<template>
  <div>
    <div class="columns is-variable is-0 is-desktop">
      <div
        class="column is-narrow"
        v-show="hideFilters"
      >
        <p class="mb-3">
          <b-tooltip
            position="is-right"
            label="Pokaż filtry"
          >
            <b-button
              size="is-small"
              icon-left="eye"
              @click="hideFilters = false"
            ></b-button>
          </b-tooltip>
        </p>
        <p class="mb-3">
          <b-tooltip
            position="is-right"
            label="Zakończ edycję"
            v-if="editorTrigger"
          >
            <b-button
              size="is-small"
              icon-left="calendar-edit"
              type="is-secondary"
              @click="editorTrigger = false"
            ></b-button>
          </b-tooltip>
          <b-tooltip
            position="is-right"
            label="Konfiguruj strefy"
            v-else
          >
            <b-button
              :disabled="!schedule2editor || readonly"
              size="is-small"
              icon-left="calendar-edit"
              @click="editorTrigger = true"
            ></b-button>
          </b-tooltip>
        </p>
        <!-- <p class="mb-3">
          <b-tooltip position="is-right" label="Konfiguruj strefy (stara wersja)">
            <b-button
              :disabled="!schedule2editor"
              size="is-small"
              icon-left="calendar-plus"
              @click="openWorktimeScheduler"
            ></b-button>
          </b-tooltip>
        </p>-->
        <p>
          <b-tooltip
            position="is-right"
            label="Odśwież dane terminarza"
          >
            <b-button
              size="is-small"
              icon-left="refresh"
              :disabled="editorTrigger"
              @click="getSchedule"
            ></b-button>
          </b-tooltip>
        </p>
      </div>

      <div
        v-if="focusBlock"
        class="card p-1 has-background-danger"
        style="position: fixed; bottom: 30px;right:30px;z-index: 29;"
      >
        <div class="card p-4">
          <b-field expanded>
            <p class="control is-expanded">
              <b-button
                icon-left="clock-outline"
                expanded
              >Przekładanie wizyty</b-button>
            </p>
            <p class="control">
              <b-button
                type="is-danger"
                icon-left="close"
                @click="cancelReschedule"
              >Anuluj</b-button>
            </p>
          </b-field>
          <table class="table is-bordered w-100">
            <tr>
              <th>Pacjent</th>
              <th>{{focusBlock.name}}</th>
            </tr>
            <tr>
              <td>Typ</td>
              <td>{{focusBlock.typeName}}</td>
            </tr>
            <tr>
              <td>Godzina</td>
              <td>{{mTime(focusBlock.start)}}</td>
            </tr>
          </table>
        </div>
      </div>
      <div
        v-if="focusArea"
        class="card p-1 has-background-danger"
        style="position: fixed; bottom: 30px;right:30px;z-index: 29;"
      >
        <div class="card p-4">
          <b-field expanded>
            <p class="control is-expanded">
              <b-button
                icon-left="clock-outline"
                expanded
              >Przekładanie strefy</b-button>
            </p>
            <p class="control">
              <b-button
                type="is-danger"
                icon-left="close"
                @click="cancelReschedule"
              >Anuluj</b-button>
            </p>
          </b-field>
          <table class="table is-bordered w-100">
            <tr>
              <th>Typ</th>
              <th>{{focusArea.name}}</th>
            </tr>
          </table>
        </div>
      </div>

      <div
        v-show="!hideFilters"
        class="column pt-3 is-narrow"
        style="
          min-width: 19.2rem !important;
          max-width: 19.2rem !important;
          width: 19.2rem !important;
          z-index: 2;
        "
      >
        <b-field>
          <b-button
            size="is-small"
            icon-left="eye-off"
            class="mr-1"
            @click="hideFilters = true"
          >Ukryj filtry</b-button>
          <!-- <b-button
            v-if="multiOffices.length > 1"
            class="mr-1"
            icon-left="calendar-edit"
            size="is-small"
            disabled
            expanded
            style="z-index: 29;"
          >
            <b-tooltip
              label="Niedostępne przy wyborze wielu gabinetów"
              position="is-bottom"
            >Konfiguracja stref</b-tooltip>
          </b-button>-->
          <b-button
            @click="editorTrigger = false"
            icon-left="calendar-edit"
            size="is-small"
            expanded
            class="mr-1 has-text-weight-semibold"
            type="is-warning"
            v-if="editorTrigger"
          >Zakończ konfigurację</b-button>
          <b-button
            v-else
            @click="editorTrigger = true"
            icon-left="calendar-edit"
            size="is-small"
            expanded
            :disabled="!schedule2editor || readonly || editorTriggerSlots"
          >Konfiguracja stref</b-button>
        
          <!-- <b-tooltip label="Zobacz instrukcję tworzenia stref" class="ml-1">
            <b-button
              size="is-small"
              icon-left="information-outline"
              @click="mDownloadInstruction('6492c0ae985c5a0bf2564d53')"
            ></b-button>
          </b-tooltip>-->
          <!-- <b-tooltip position="is-right" label="Konfiguruj strefy (stara wersja)">
            <b-button
              size="is-small"
              icon-left="calendar-plus"
              :disabled="!schedule2editor"
              @click="openWorktimeScheduler"
            ></b-button>
          </b-tooltip>-->
        </b-field>
        <b-field
          class="mb-1"
          v-if="vehiclesVisible"
          label="Pojazd"
          label-position="on-border"
        >
          <b-autocomplete
            expanded
            :data="vehicles"
            field="name"
            open-on-focus
            @select="selectedVehicle"
          ></b-autocomplete>
        </b-field>
        <b-field v-if="vehiclesVisible">
          <b-datepicker
            style="background-color: transparent"
            v-model="datesSelected"
            :first-day-of-week="1"
            placeholder="Wybierz"
            custom-class="hand"
            icon="calendar-month"
            ref="datesPickerVehicle"
            :mobile-native="false"
            :key="scheduleViewType"
            expanded
          >
            <b-field>
              <b-button
                size="is-small"
                icon-left="calendar-month"
                class="mr-1"
                @click="cleanDates(true)"
              >Dzisiaj</b-button>
            </b-field>
          </b-datepicker>
          <p class="control">
            <b-button @click="getSchedule20">Szukaj</b-button>
          </p>
        </b-field>
        <b-tabs
          v-show="!vehiclesVisible"
          type="is-boxed"
          size="is-small"
          class="boxed-tabs xyz mb-3"
          v-model="scheduleMode"
          :animated="false"
        >
          <b-tab-item
            icon="folder-search-outline"
            label="Ogólny"
          >
            <!-- <b-tabs
              expanded
              class="mb-2 xyz"
              v-model="scheduleMode2"
              :animated="false"
              size="is-small"
            >
            <b-tab-item label="Placówka" class="pt-3 pl-3 pr-3 pb-0">-->
            <b-field
              class="wide mt-3 ml-3 mr-3 mb-2"
              label-position="on-border"
            >
              <b-autocomplete
                v-model="facilityName"
                :open-on-focus="true"
                :data="filteredFacilities"
                field="name"
                size="is-small"
                @select="selectFacility"
                clear-on-select
                expanded
                placeholder="Wyszukaj placówki"
              >
                <template slot-scope="props">
                  <div style="line-height: 1rem;">
                    <p class="has-text-weight-semibold pb-1">{{ props.option.name }}</p>
                    <p
                      v-if="props.option.address"
                      class="has-text-grey"
                    >
                      {{ props.option.address.street }}
                      {{ props.option.address.buildingNumber }}
                      {{ props.option.address.apartmentNumber ? "/ " : "" }}
                      {{ props.option.address.apartmentNumber }}
                      <br />
                      {{ props.option.address.zipCode }}
                      {{ props.option.address.city }}
                    </p>
                  </div>
                </template>
              </b-autocomplete>
            </b-field>

            <div
              class="ml-2 mr-2 mb-2"
              v-show="selectedFacility.length > 0"
            >
              <table class="table is-narrow w-100">
                <tr
                  v-for="(facility, index) in selectedFacility"
                  :key="facility.id"
                >
                  <td class="pt-1 pb-1 pr-2 is-6 title">{{facility.name}}</td>
                  <td style="width: 1px;">
                    <b-tooltip
                      :delay="200"
                      label="Wyczyść placówkę"
                    >
                      <b-button
                        icon-left="close"
                        type="is-text"
                        size="is-small"
                        style="height:1.3rem;"
                        @click="selectedFacility.splice(index, 1)"
                      ></b-button>
                    </b-tooltip>
                  </td>
                </tr>
              </table>
            </div>
            <!--  </b-tab-item>
              <b-tab-item label="Klaster" disabled>
                <b-field label="Klaster" label-position="on-border">
                  <b-select placeholder="Wybierz" expanded></b-select>
                </b-field>
              </b-tab-item>
              <b-tab-item label="Rejon" disabled>
                <b-field label="Rejon" label-position="on-border">
                  <b-select placeholder="Wybierz" expanded></b-select>
                </b-field>
              </b-tab-item>
            </b-tabs>-->
            <div class="pl-3 pr-3 pt-0 pb-3">
              <div
                class="mt-4 mb-4"
                v-if="roomSearchVisible"
              >
                <b-field
                  label-position="on-border"
                  label="Wybierz pomieszczenie"
                  class="wide mb-1"
                  expanded
                >
                  <b-autocomplete
                    expanded
                    :data="roomsFiltered"
                    v-model="roomFilter"
                    clearable
                    class="wide"
                    size="is-small"
                    field="info"
                    open-on-focus
                    @select="selectedRoom"
                    :loading="requestInProgress"
                  ></b-autocomplete>
                </b-field>

                <b-button
                  expanded
                  v-if="scheduleViewType == 1"
                  size="is-small"
                  type="is-primary"
                  icon-left="magnify"
                  :disabled="chosenRoom == null"
                  @click="getSchedule4"
                >Zobacz terminarz pokoju</b-button>
                <b-button
                  expanded
                  v-if="scheduleViewType == 2"
                  size="is-small"
                  type="is-secondary"
                  icon-left="magnify"
                  :disabled="chosenRoom == null"
                  @click="getSchedule5"
                >Zobacz terminarz pokoju</b-button>
              </div>
              <b-field
                grouped
                class="mb-2"
              >
                <b-datepicker
                  style="background-color: transparent;"
                  v-model="datesSelected"
                  :first-day-of-week="1"
                  placeholder="Wybierz daty"
                  custom-class="hand"
                  icon="calendar-month"
                  :disabled="scheduleViewType === 2"
                  :multiple="scheduleViewType === 0"
                  ref="datesPicker"
                  :mobile-native="false"
                  :key="scheduleViewType"
                  expanded
                  size="is-small"
                  class="mr-1"
                  @input="dateChosen"
                >
                  <b-button
                    size="is-small"
                    icon-left="calendar-month"
                    @click="cleanDates(true)"
                  >Dzisiaj</b-button>
                  <b-button
                    size="is-small"
                    type="is-danger"
                    icon-left="close"
                    outlined
                    class="ml-1"
                    v-if="scheduleViewType === 0"
                    @click="cleanDates(false)"
                  >Wyczyść</b-button>
                  <b-button
                    size="is-small"
                    icon-left="refresh"
                    class="ml-1"
                    :type="multipleMode ? 'is-primary' : ''"
                    :loading="requestInProgress"
                    @click="getScheduleAndHideDropdown"
                    ref="getScheduleButton"
                  >
                    <span v-if="multipleMode">Pobierz</span>
                    <span v-else>Odśwież</span>
                  </b-button>
                </b-datepicker>
                <b-tooltip
                  v-if="multipleMode"
                  :delay="500"
                  label="Multiwybór jest włączony. Kliknij aby WYŁĄCZYĆ."
                  class="mr-1"
                >
                  <b-button
                    @click="multipleMode = false"
                    size="is-small"
                    icon-left="playlist-check"
                  ></b-button>
                </b-tooltip>
                <b-tooltip
                  v-else
                  :delay="500"
                  label="Multiwybór jest wyłączony. Kliknij aby WŁĄCZYĆ."
                  type="is-light"
                  class="mr-1"
                >
                  <b-button
                    @click="multipleMode = true"
                    type="is-text"
                    size="is-small"
                    icon-left="playlist-remove"
                  ></b-button>
                </b-tooltip>

                <!-- <p v-if="scheduleViewType == 2" class="mr-1">
                  <b-tooltip
                    v-if="onlyAvailable"
                    :delay="500"
                    label="Zapełnione dni są pomijane. Kliknij aby je UWZGLĘDNIAĆ."
                    type="is-secondary"
                  >
                    <b-button
                      @click="onlyAvailable = false"
                      size="is-small"
                      icon-left="calendar-alert"
                    ></b-button>
                  </b-tooltip>
                  <b-tooltip
                    v-else
                    :delay="500"
                    label="Zapełnione dni są uwzględniane. Kliknij aby je OMIJAĆ."
                    type="is-secondary"
                  >
                    <b-button
                      @click="onlyAvailable = true"
                      size="is-small"
                      type="is-secondary"
                      icon-left="calendar-alert"
                    ></b-button>
                  </b-tooltip>
                </p>-->

                <b-tooltip label="Terminarz pomieszczeń">
                  <b-button
                    size="is-small"
                    :disabled="readonly"
                    icon-left="view-dashboard"
                    :type="roomSearchVisible ? 'is-secondary' : ''"
                    :loading="requestInProgress"
                    @click="roomSearchVisible = !roomSearchVisible"
                  ></b-button>
                </b-tooltip>
              </b-field>
              <b-field
                grouped
                expanded
                class="mb-0"
              >
                <b-dropdown
                  aria-role="list"
                  :triggers="['click','hover']"
                  v-if="scheduleViewType == 2"
                >
                  <template #trigger>
                    <!-- Options trigger button -->
                    <b-button
                      size="is-small"
                      icon-left="cog"
                    ></b-button>
                  </template>
                  <div
                    class="pl-3 pr-3 pt-1 pb-1"
                    style="width: 14rem;"
                  >
                    <b-field class="mb-1">
                      <b-checkbox
                        size="is-small"
                        v-model="onlyAvailable"
                      >Pomiń zapełnione dni</b-checkbox>
                    </b-field>

                    <b-field class="mb-1">
                      <b-checkbox
                        size="is-small"
                        v-model="skipLeaves"
                      >Pomiń dni tylko z urlopami</b-checkbox>
                    </b-field>

                    <b-field class="mb-1">
                      <b-checkbox
                        size="is-small"
                        v-model="showDisabledScheduleOffices"
                      >Pokaż gabinety archiwalne</b-checkbox>
                    </b-field>

                  </div>
                </b-dropdown>

                <b-field
                  expanded
                  class="ml-1"
                >
                  <b-select
                    size="is-small"
                    expanded
                    v-model="scheduleViewType"
                  >
                    <option :value="0">Wybrane dni</option>
                    <option :value="1">Tydzień od</option>
                    <option :value="2">Najbliższe terminy</option>
                  </b-select>
                  <p class="control">
                    <b-button
                      size="is-small"
                      icon-left="refresh"
                      :type="multipleMode ? 'is-primary' : ''"
                      :loading="requestInProgress"
                      :disabled="editorTrigger"
                      @click="onMenuOfficeClick(null)"
                    >
                      <span v-if="editorTrigger">Tryb edycji</span>
                      <span v-else-if="multipleMode">Pobierz</span>
                      <span v-else>Odśwież</span>
                    </b-button>
                  </p>
                </b-field>
              </b-field>

              <b-tag
                class="w-100 mt-1"
                type="is-warning"
                v-if="onlyAvailable"
              >
                <b>
                  <span v-if="filters2 && filters2.areaFilter > -1">Strefy</span>
                  <span v-else>Dni</span>
                </b> zapełnione są pomijane</b-tag>

              <b-field
                grouped
                v-if="multipleMode && scheduleViewType == 2"
                class="mt-2 mb-2"
              >
                <b-field
                  :type="filters2.areaFilter > -1 ? 'is-orange':''"
                  expanded
                  class="mr-0"
                >
                  <b-select
                    v-model="filters2.areaFilter"
                    :disabled="multiOffices.length == 0"
                    expanded
                    size="is-small"
                  >
                    <option :value="-1">
                      <span v-if="multiOffices.length == 0">Wybierz gabinet aby zobaczyć strefy</span>
                      <span v-else>Wszystkie typy stref</span>
                    </option>
                    <option
                      v-for="x in areaFilterItems"
                      :key="x.index"
                      :value="x.id"
                    >{{x.name}}</option>
                  </b-select>
                </b-field>
                <b-tooltip
                  v-show="filters2.areaFilter > -1"
                  :delay="500"
                  multilined
                  class="ml-1"
                  label="Wybrany jest filtr strefy. Terminarz wyszukuje najbliższe dni w których taka strefa ma wolne terminy. Kliknij aby usunąć filtr."
                >
                  <b-button
                    @click="filters2.areaFilter = -1"
                    size="is-small"
                    icon-left="arrow-left-bold"
                  ></b-button>
                </b-tooltip>

                <b-field v-if="hasOnlyWorktimeScheduleOffices">
                  <b-tooltip
                    v-if="officeWorktimeLock"
                    multilined
                    label="Filtr gabinetów tylko do umówienia strefy jest włączony. Kliknij aby WYŁĄCZYĆ."
                    type="is-secondary"
                    class="ml-1"
                  >
                    <b-button
                      @click="officeWorktimeLock = false"
                      size="is-small"
                      type="is-secondary"
                      icon-left="calendar-lock"
                    ></b-button>
                  </b-tooltip>
                  <b-tooltip
                    v-else
                    multilined
                    label="Filtr gabinetów tylko do umówienia strefy jest wyłączony. Kliknij aby WŁĄCZYĆ."
                    type="is-secondary"
                    class="ml-1"
                  >
                    <b-button
                      @click="officeWorktimeLock = true"
                      size="is-small"
                      icon-left="calendar-lock"
                    ></b-button>
                  </b-tooltip>
                </b-field>
              </b-field>

              <b-field
                grouped
                class="mt-2 mb-1"
              >
                <b-input
                  v-model="officeNameFilter"
                  placeholder="Filtruj gabinet w wybranych placówkach"
                  size="is-small"
                  expanded
                  class="mr-0"
                ></b-input>
                <b-button
                  v-show="officeNameFilter.length"
                  :type="officeNameFilter.length ? 'is-danger':''"
                  icon-left="close"
                  @click="officeNameFilter = ''"
                  size="is-small"
                  class="ml-1"
                ></b-button>

                <p
                  class="control ml-1"
                  v-if="multiOffices.length > 0"
                >
                  <b-tooltip
                    type="is-warning"
                    label="Wyczyść zaznaczenie gabinetów"
                    :delay="500"
                  >
                    <b-button
                      @click="multiOffices = []"
                      size="is-small"
                      type="is-warning"
                      icon-left="checkbox-blank-off-outline"
                    ></b-button>
                  </b-tooltip>
                </p>
              </b-field>

              <div
                v-if="multipleMode"
                class="schedule-menu mt-0"
                :accordion="false"
              >
                <b-collapse
                  :open="false"
                  v-for="clinic in filteredGeneralMenu"
                  :key="clinic.index"
                  v-show="clinic.offices && clinic.offices.length > 0"
                >
                  <template #trigger="propsMain">
                    <div class="pt-1">
                      <table>
                        <tr>
                          <td class="pr-2 pl-0">
                            <b-icon
                              :type="propsMain.open ? 'is-secondary' : ''"
                              :icon="propsMain.open ? 'folder-open-outline' : 'folder-outline'"
                            ></b-icon>
                          </td>
                          <td>
                            <div
                              :class="{'has-text-secondary':propsMain.open}"
                              style="line-height: 0.92rem;"
                              class="is-uppercase lighthover has-text-weight-semibold is-size-7 pt-1"
                            >{{ getClinicName(clinic.id) }}</div>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </template>

                  <div
                    class="pb-1 mb-1 pt-1"
                    style="border-left: 3px solid #26a69a;"
                  >
                    <p
                      class="is-size-7 pb-1"
                      v-if="clinic.offices && clinic.offices.length > 1"
                    >
                      <a
                        class="has-text-grey mr-3 ml-3"
                        @click="multiselect(clinic.offices, true)"
                      >
                        <u>Zaznacz wszystko</u>
                      </a>
                      <a
                        class="has-text-grey is-pulled-right"
                        @click="multiselect(clinic.offices, false)"
                      >
                        <u>Wyczyść</u>
                      </a>
                    </p>
                    <div
                      v-for="office in clinic.offices"
                      :key="office.index"
                      class="pt-1 pb-0"
                      :class="{'has-text-secondary':multiOffices.includes(office)}"
                    >
                      <div class="pl-3">
                        <b-checkbox
                          class="w-100 has-text-weight-semibold"
                          style="font-size: 0.78rem;"
                          type="is-secondary"
                          v-model="multiOffices"
                          :native-value="office"
                        >
                          <div>
                            {{ office.name }}
                            <span
                              class="has-text-danger"
                              v-if="office.isScheduleBlocked"
                            >ZABLOKOWANY</span>
                          </div>
                        </b-checkbox>
                      </div>
                    </div>
                  </div>
                </b-collapse>
              </div>
              <b-menu
                v-else
                class="schedule-menu"
                :accordion="false"
              >
                <b-menu-list>
                  <b-menu-item
                    v-for="clinic in filteredGeneralMenu"
                    :key="clinic.index"
                    class="clinic-type-menu"
                    v-show="clinic.offices && clinic.offices.length > 0"
                  >
                    <template slot="label">
                      <div class="level">
                        <div class="level-left is-variable is-0">
                          <div class="level-left">
                            <div class="level-item is-uppercase is-size-7 has-text-weight-semibold">
                              <div style="max-width: 18rem;">{{ getClinicName(clinic.id) }} ({{ clinic.offices.length }})</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                    <b-menu-item
                      v-for="office in clinic.offices"
                      :key="office.index"
                      @click="onMenuOfficeClick(office)"
                      class="office-menu"
                    >
                      <template slot="label">
                        <div class="level">
                          <div class="level-left">
                            <div class="level-item mr-1">
                              <b-tooltip
                                size="is-small"
                                multilined
                                :label="`${getOfficeName(office.type)} (Imienny)`"
                                type="is-secondary"
                                v-if="office.isNamed"
                              >
                                <b-icon
                                  type="is-secondary"
                                  icon="calendar-account"
                                ></b-icon>
                              </b-tooltip>
                              <b-tooltip
                                size="is-small"
                                v-else
                                type="is-secondary"
                                multilined
                                :label="getOfficeName(office.type)"
                              >
                                <b-icon icon="calendar-arrow-right"></b-icon>
                              </b-tooltip>
                            </div>
                            <div class="level-item is-size-7">
                              <div
                                class="has-text-weight-semibold mb-1"
                                style="max-width: 14rem;"
                              >
                                {{ office.name }}
                                <span
                                  class="has-text-danger"
                                  v-if="office.isScheduleBlocked"
                                >ZABLOKOWANY</span>
                              </div>
                              <!-- <div class="is-size-7 has-text-weight-bold" v-if="office.isNamed">
                                <b-icon size="is-small" icon="account-circle" class="m-0"></b-icon>
                                {{ getEmployeeName(office.employee) }}
                              </div>-->
                            </div>
                          </div>
                        </div>
                      </template>
                    </b-menu-item>
                    <!-- </b-menu-item> -->
                  </b-menu-item>
                </b-menu-list>
              </b-menu>

              <section
                class="section has-text-centered"
                v-show="filteredOfficesCount < 1"
              >Brak gabinetów</section>
            </div>
          </b-tab-item>
          <b-tab-item
            :disabled="!patient"
            icon="card-account-details-star-outline"
            label="Szczegółowy"
          >
            <div class="p-3">
              <b-field expanded>
                <p class="control">
                  <b-button
                    size="is-small"
                    type="is-static"
                  >Tryb</b-button>
                </p>
                <b-select
                  size="is-small"
                  expanded
                  v-model="scheduleViewType"
                >
                  <option :value="0">Wybrane dni</option>
                  <option :value="1">Tydzień od</option>
                  <option :value="2">Najbliższe terminy</option>
                </b-select>
              </b-field>
            </div>
            <b-field class="mb-0">
              <b-datepicker
                class="borderless-calendar"
                style="
                  margin-left: 0rem;
                  margin-right: 0rem;
                  margin-top: -0.49rem;
                  background-color: transparent;
                "
                v-model="datesSelected"
                :month-names="monthNames"
                :day-names="dayNamesShort"
                :first-day-of-week="1"
                placeholder="Wybierz"
                custom-class="hand"
                icon="calendar-month"
                :multiple="scheduleViewType === 0"
                :disabled="scheduleViewType === 2"
                ref="datesPickerDetailed"
                :mobile-native="false"
                :key="scheduleViewType"
                expanded
                inline
                size="is-small"
              >
                <b-field>
                  <b-button
                    size="is-small"
                    icon-left="calendar-month"
                    class="mr-1"
                    @click="cleanDates(true)"
                  >Dzisiaj</b-button>
                  <b-button
                    size="is-small"
                    type="is-danger"
                    icon-left="close"
                    class="mr-1"
                    outlined
                    v-if="scheduleViewType === 0"
                    @click="cleanDates(false)"
                  >Wyczyść</b-button>
                </b-field>
              </b-datepicker>
            </b-field>

            <div class="pt-0 p-3">
              <b-tabs
                v-model="searchMode"
                expanded
                :animated="false"
                size="is-small"
                type="is-toggle"
              >
                <b-tab-item
                  value="0"
                  label="Poradnia"
                >
                  <b-field
                    expanded
                    label="Poradnia"
                    label-position="inside"
                    class="wide mb-3 mt-3"
                  >
                    <b-autocomplete
                      ref="auto0"
                      :data="clinicFiltered"
                      v-model="clinicFilter"
                      placeholder="Wybierz"
                      open-on-focus
                      field="name"
                      keep-first
                      expanded
                      clearable
                      @select="selectedClinic"
                      :loading="requestInProgress"
                    >
                      <template slot-scope="props">
                        <div v-if="props.option.name">
                          <table>
                            <tr>
                              <td>
                                <b-tag type="is-secondary">{{ props.option.code }}</b-tag>
                              </td>
                              <td class="pl-2">
                                <p class="title is-7">{{ props.option.shortName }}</p>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </template>
                    </b-autocomplete>
                  </b-field>
                  <b-button
                    v-if="editorTrigger"
                    type="is-primary"
                    icon-right="calendar-search"
                    expanded
                    disabled
                  >Tryb edycji</b-button>
                  <b-button
                    v-else-if="scheduleViewType === 2"
                    :loading="requestInProgress"
                    @click="getSchedule3"
                    type="is-primary"
                    icon-right="calendar-search"
                    expanded
                    :disabled="filters2.clinic === -1 || editorTrigger"
                  >Szukaj terminu</b-button>
                  <b-button
                    v-else
                    :loading="requestInProgress"
                    @click="getSchedule2"
                    type="is-primary"
                    icon-right="calendar"
                    expanded
                    :disabled="filters2.clinic === -1 || editorTrigger"
                  >Pobierz terminarz</b-button>
                </b-tab-item>
                <b-tab-item
                  value="1"
                  label="Gabinet"
                >
                  <b-field
                    expanded
                    label="Gabinet"
                    label-position="inside"
                    class="mb-3 mt-3"
                  >
                    <b-autocomplete
                      ref="auto1"
                      :data="officesFiltered"
                      v-model="officeFilter"
                      placeholder="Wybierz"
                      open-on-focus
                      field="name"
                      keep-first
                      expanded
                      clearable
                      @select="selectedOffice"
                      :loading="requestInProgress"
                    >
                      <template slot-scope="props">
                        <div>
                          <div
                            style="line-height: .85rem;"
                            class="is-size-7"
                          >
                            <p
                              class="has-text-weight-bold mb-1"
                              v-if="props.option.originalName"
                            >{{ props.option.originalName }}</p>
                            <p
                              class="has-text-weight-semibold mb-1"
                              v-else
                            >{{ props.option.name }}</p>
                            <!-- <p class="has-text-primary mb-1">
                            <i>{{ props.option.subUnitName }}</i>
                            </p>-->
                            <p class="mb-1">{{ props.option.subUnitName }}</p>
                            <p>{{ props.option.facilityName }}</p>
                          </div>
                        </div>
                      </template>
                    </b-autocomplete>
                  </b-field>
                  <b-button
                    v-if="editorTrigger"
                    type="is-primary"
                    icon-right="calendar-search"
                    expanded
                    disabled
                  >Tryb edycji</b-button>
                  <b-button
                    v-else-if="scheduleViewType === 2"
                    :loading="requestInProgress"
                    @click="getSchedule9"
                    icon-right="calendar-search"
                    expanded
                    class="mt-3"
                    type="is-primary"
                    :disabled="  filters2.office === null || (includeRoom && filters2.room === null) || editorTrigger"
                  >Szukaj terminu</b-button>
                  <b-button
                    v-else
                    :loading="requestInProgress"
                    @click="getSchedule8"
                    icon-right="calendar"
                    expanded
                    class="mt-3"
                    type="is-primary"
                    :disabled="filters2.office === null ||  (includeRoom && filters2.room === null) || editorTrigger"
                  >Pobierz terminarz</b-button>
                </b-tab-item>
                <b-tab-item
                  v-if="false"
                  value="2"
                  label="Pracownik"
                >
                  <b-field
                    expanded
                    label="Pracownik"
                    label-position="inside"
                    class="wide mt-3"
                  >
                    <b-autocomplete
                      ref="auto2"
                      :data="elmpoyeeFiltered"
                      v-model="docFilter"
                      placeholder="Wybierz"
                      open-on-focus
                      field="name"
                      keep-first
                      expanded
                      @select="selectedEmployee"
                      :loading="requestInProgress"
                      clearable
                    >
                      <template slot-scope="props">
                        <p
                          class="title is-7 mb-0"
                          :class="{'has-text-grey':props.option.isOtherDomain}"
                          style="line-height: .75rem;"
                        >
                          {{ props.option.lastName }}
                          {{ props.option.firstName }}
                        </p>
                        <p
                          v-if="props.option.isOtherDomain"
                          class="has-text-danger is-size-7"
                        >Uwaga! Inna domena/obszar</p>
                      </template>
                    </b-autocomplete>
                  </b-field>
                  <b-field
                    expanded
                    v-if="
                  employee != null &&
                  employee.specializations &&
                  employee.specializations.length > 0
                "
                    label-position="inside"
                    label="Specjalizacja"
                  >
                    <b-select
                      expanded
                      v-model="filters2.spec"
                    >
                      <!-- <option :value="-1">dowolna specjalizacja</option> -->
                      <option
                        v-for="item in employee.specializations"
                        :key="item.id"
                        :value="item"
                      >{{ mGetSpecialization(item) }}</option>
                    </b-select>
                  </b-field>
                  <b-button
                    :loading="requestInProgress"
                    @click="getSchedule1"
                    type="is-primary"
                    icon-right="calendar-search"
                    expanded
                    :disabled="filters2.employee === -1 || editorTrigger"
                    v-if="scheduleViewType === 2"
                  >Szukaj terminu</b-button>
                  <b-button
                    v-else
                    :loading="requestInProgress"
                    @click="getSchedule0"
                    type="is-primary"
                    icon-right="calendar"
                    expanded
                    :disabled="filters2.employee === -1 || editorTrigger"
                  >Pobierz terminarz</b-button>
                </b-tab-item>
              </b-tabs>

              <b-field
                label-position="on-border"
                label="Klaster"
                class="mt-5"
              >
                <b-select
                  v-model="selectedCluster"
                  expanded
                >
                  <option :value="null">Wszystkie</option>
                  <option
                    v-for="item in clusters"
                    :value="item"
                    :key="item.id"
                  >{{ item.name }}</option>
                </b-select>
                <p class="control">
                  <b-tooltip
                    :delay="500"
                    label="Z powodu braku konfiguracji placówek, wybór klastra nie działa jeszcze poprawnie."
                    multilined
                  >
                    <b-button
                      disabled
                      icon-left="information-outline"
                    ></b-button>
                  </b-tooltip>
                </p>
              </b-field>
              <p class="has-text-white">{{ columnWidth }}</p>
            </div>
          </b-tab-item>
        </b-tabs>

        <b-field grouped>
        <b-button
          v-if="perms.lioczCreate"
          class="mb-3"
          size="is-small"
          icon-left="calendar"
          type="is-violet"
          @click="lioczModal = true"
        >Raporty kolejki</b-button>
        <b-field v-if="erSlotsEditor" expanded class="ml-1">
          <b-button
            @click="editorTriggerSlots = false"
            icon-left="square-edit-outline"
            size="is-small"
            expanded
            class="has-text-weight-semibold"
            type="is-warning"
            v-if="editorTriggerSlots"
          >Zakończ konfigurację</b-button>
          <b-button
            v-else
            @click="editorTriggerSlots = true"
            icon-left="square-edit-outline"
            size="is-small"
            expanded type="is-primary"
            :disabled="!schedule2editor || readonly || editorTrigger"
          >Konfiguracja slotów</b-button>
        </b-field>
      </b-field>

        <table class="is-size-7">
          <tr>
            <td style="width: 10px;background: red;">
              <b-icon
                size="is-small"
                icon="exclamation-thick"
                type="is-light"
                style="margin:-3px 0 0 0;"
              ></b-icon>
            </td>
            <td class="pl-2">Wizyta pilna</td>
            <td style="width: 10px;background: orange;">
              <b-icon
                size="is-small"
                icon="account"
                style="margin:-3px 0 0 0;"
              ></b-icon>
            </td>
            <td class="pl-2">Pacjent w poczekalni</td>
          </tr>
          <tr>
            <td style="width: 10px;background: mediumpurple;">
              <b-icon
                size="is-small"
                icon="calendar"
                style="margin:-3px 0 0 0;"
              ></b-icon>
            </td>
            <td class="pl-2">Rezerwacja</td>
            <td style="width: 10px;background: greenyellow;">
              <b-icon
                size="is-small"
                icon="refresh"
                type="is-dark"
                style="margin:-3px 0 0 0;"
              ></b-icon>
            </td>
            <td class="pl-2">Możliwe przełożenie</td>
          </tr>
          <tr>
            <td style="width: 10px;background: DarkTurquoise;">
              <b-icon
                size="is-small"
                icon="clock-alert"
                style="margin:-3px 0 0 0;"
              ></b-icon>
            </td>
            <td class="pl-2 pr-5">Wizyta w trakcie</td>
            <td style="width: 10px;background: rgb(255, 213, 0);">
              <b-icon
                size="is-small"
                icon="clipboard-account"
                type="is-danger"
                style="margin:-3px 0 0 0;"
              ></b-icon>
            </td>
            <td class="pl-2">Brak deklaracji</td>
          </tr>
          <tr>
            <td style="width: 10px;background: #17A589;">
              <b-icon
                size="is-small"
                icon="phone"
                type="is-light"
                style="margin:-3px 0 0 0;"
              ></b-icon>
            </td>
            <td class="pl-2 pr-5">Teleporada</td>
            <td style="width: 10px;background: #884EA0;">
              <b-icon
                size="is-small"
                icon="car"
                type="is-light"
                style="margin:-3px 0 0 0;"
              ></b-icon>
            </td>
            <td class="pl-2">Wizyta domowa</td>
          </tr>
          <tr>
            <td class="p-1"></td>
            <td></td>
          </tr>
          <tr>
            <td
              style="width: 10px;text-align: center;"
              class="has-background-success"
            >
              <b style="color:white;">E</b>
            </td>
            <td class="pl-2">E-Rejestracja</td>
            <td style="width: 10px;background: maroon;text-align: center;">
              <b style="color:white;">E</b>
            </td>
            <td class="pl-2">Wymagane skreślenie</td>
          </tr>
          <tr>
            <td
              style="width: 10px;text-align: center;"
              class="has-background-danger"
            >
              <b style="color:white;">E</b>
            </td>
            <td class="pl-2">E-Rejestr. Błąd</td>
            <td
              style="width: 10px;text-align: center;"
              class="has-background-info"
            >
              <b style="color:white;">E</b>
            </td>
            <td class="pl-2">E-Rejestr. Oczekujący</td>
          </tr>
          <tr>
            <td class="p-1"></td>
            <td></td>
          </tr>
          <tr>
            <td
              style="width: 10px;text-align: center;"
              class="has-background-info"
            >
              <b style="color:white;">A</b>
            </td>
            <td class="pl-2">AP-KOLCE</td>
            <td
              style="width: 10px;text-align: center;"
            >
            </td>
            <td class="pl-2"></td>
          </tr>

        </table>

        <!-- <code>{{ filters2.facilities }}</code> -->
        <!--<hr />
        <hr />-->
        <!-- <pre>{{datesSelected}}</pre> -->
        <!-- <pre>{{scheduleViewType}}</pre> -->
        <!-- <pre>{{focusBlock}}</pre> -->
        <!-- <pre>app:{{focusAppointment}}</pre> -->
      </div>

      <div
        class="column pl-3"
        ref="scheduleColumn"
        :style="hideFilters ? 'max-width:calc(100% - 2.1rem);':'max-width:calc(100% - 19.2rem);'"
      >
        <Schedule
          :trigger="trigger"
          :editorActive="editorTrigger"
          :editorSlotsActive="editorTriggerSlots"
          :resetTrigger="resetTrigger"
          :filters="filters2"
          :patient="patient"
          :rescheduleMode="focusBlock != null"
          :readonly="readonly"
          :scheduleViewType="scheduleViewType"
          @created="onAppointmentCreated"
          @remove="onAppointmentRemove"
          @update="onAppointmentUpdate"
          @response="requestInProgress = false"
          @error="handleError"
          @search-patient="searchPatientEvent"
          @scroll-days="changeDays"
          @area-moved="areaMoved"
          @show-patient-data="showPatientDataEvent"
        ></Schedule>
      </div>
    </div>
    <b-modal
      :active.sync="isWorktimeSchedulerModalActive"
      has-modal-card
      full-screen
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
      scroll="keep"
    >
      <div class="modal-card">
        <header
          class="modal-card-head has-background-kin"
          style="border-radius: 0px !important"
        >
          <p class="modal-card-title has-text-light is-size-5">
            <b-icon
              icon="calendar-weekend"
              class="mr-3"
              type="is-light"
            ></b-icon>Konfiguracja stref terminarza
          </p>
        </header>

        <section class="modal-card-body">
          <WorktimeScheduler />
        </section>
        <footer class="modal-card-foot has-background-kin buttons is-right">
          <b-button
            type="is-primary"
            @click="isWorktimeSchedulerModalActive = false"
            icon-left="close"
            size="is-small"
          >Zamknij</b-button>
        </footer>
      </div>
    </b-modal>

    <b-modal
      :active.sync="lioczModal"
      has-modal-card
      scroll="keep"
      :destroy-on-hide="false"
      class="chat-pad chatsize"
    >
      <LioczList
        v-if="perms.lioczCreate"
        :dailyMode="true"
      ></LioczList>
    </b-modal>

    <b-modal
      :active.sync="isActiveModalPatientChoice"
      :destroy-on-hide="true"
      scroll="keep"
      has-modal-card
      :can-cancel="true"
      custom-class="max-width-modal"
    >
      <ContentPanel
        title="Wyszukaj pacjenta"
        icon="magnify"
        :paddingless="true"
        :marginless="true"
      >
        <template slot="addon">
          <b-button
            class="mr-3"
            icon-left="calendar"
            size="is-small"
            type="is-secondary"
            :disabled="!selectedPatient"
            @click="openVisitModalAdd"
          >
            Potwierdź i umów wizytę
          </b-button>
          <b-button
            @click="cancelSearch()"
            icon-left="close"
            size="is-small"
            type="is-danger"
          >
            Zamknij
          </b-button>
        </template>
        <div
          class="modal-card"
          style="overflow: visible; width: 100% !important;"
        >
          <section class="modal-card-body">
            <PatientPanelSearchRegistration
              :isModal="true"
              :selectedPatient="selectedPatient"
              :clearSearchCounter="clearPatientSearch"
              @selected="selectPatient"
              @new-patient="newPatientEvent"
            />
          </section>
        </div>
      </ContentPanel>
    </b-modal>

    <RegistrationNewPatientModal
      :payloadEvent="newPatientPayload"
      :trigger="newPatientTrigger"
      @search-completed="emitSearchCompleted"
    />

  </div>
</template>

<script>
import CommonMixins from "@/mixins/commons";
import InvMixins from "@/mixins/inventory";
import PersonnelMixins from "@/mixins/personnel";
import AppoMixins from "@/mixins/appointments";
import ClinicsMixins from "@/mixins/clinics";
import SpecMixins from "@/mixins/specializations";
import MedEnums from "@/mixins/med_enums";
import DateHelpers from "@/mixins/date_helpers";
import moment from "moment";
import { Action } from "@/store/config_actions";
import Schedule from "@/components/schedule2/Schedule2";
import { Mutation } from "@/store/config_mutations";
import WorktimeScheduler from "@/components/schedule/wrappers/WorktimeScheduler";
import { Cookies } from "@/store/config_cookies";
import LioczList from "@/components/nfz/LioczStandalone";

import PatientPanelSearchRegistration from "@/components/patient/PatientPanelSearchRegistration";
import RegistrationNewPatientModal from "@/components/registration/RegistrationNewPatientModal";

export default {
  name: "RegistrationSchedule2",
  mixins: [
    CommonMixins,
    DateHelpers,
    MedEnums,
    SpecMixins,
    ClinicsMixins,
    PersonnelMixins,
    AppoMixins,
    InvMixins,
  ],

  components: {
    Schedule,
    WorktimeScheduler,
    LioczList,
    PatientPanelSearchRegistration,
    RegistrationNewPatientModal,
  },

  props: {
    resetTrigger: { type: Number, required: false },
    readonly: { type: Boolean, required: false },
  },

  watch: {
    "multiOffices.length"(length) {
      if (length > 0) {
        this.areaFilterItems = [];
        let items = [];

        this.multiOffices.forEach((office) => {
          let conf = this.officeTypes.find((x) => x.id == office.type);

          if (conf && conf.areas) {
            conf.areas.forEach((area) => {
              if (items.includes(area)) {
                // cool, already there
              } else {
                items.push(area);
              }
            });
          }
        });

        items.forEach((item) => {
          let conf = this.areaTypes.find((x) => x.id == item);

          if (conf) {
            this.areaFilterItems.push(conf);
          }
        });
      } else {
        this.areaFilterItems = [];
        this.filters2.areaFilter = -1;
      }
    },

    autoNavigateTrigger() {
      if (this.autoNavigateTarget) {
        if (this.autoNavigateTarget.officeId) {
          let off = this.allOffices.find(
            (x) => x.id == this.autoNavigateTarget.officeId
          );

          if (off) {
            this.scheduleViewType = 0;
            this.scheduleMode = 0;
            this.datesSelected = [];
            this.multiOffices = [];

            this.$nextTick(() => {
              let targetDate = moment(this.autoNavigateTarget.start).toDate();
              this.datesSelected.push(targetDate);
              this.selectedOffice(off);
              this.getSchedule8();
              window.scrollTo(0, 0);
            });
          }
        }
      }
    },

    focusArea(val) {
      if (val) {
        this.editorTrigger = true;
      }
    },

    officeWorktimeLock() {
      this.multiOffices = [];
      this.wrapGeneralMenu();
    },

    showDisabledScheduleOffices() {
      this.multiOffices = [];
      this.wrapGeneralMenu();
    },

    multipleMode(val) {
      this.$cookies.set(Cookies.SCHEDULE_MULTICHOICE_REG, val);
      this.multiOffices = [];
      this.filters2.areaFilter = -1;
    },

    selectedCluster(value) {
      if (value) {
        var facs = [];

        // Filter facility IDs
        this.facilities.forEach((f) => {
          if (f.clusterId === value.internalId) {
            facs.push(f.internalId);
          }
        });

        this.filters2.facilities = facs;
      } else {
        // All clusters included
        this.filters2.facilities = null;
      }
    },

    resetTrigger() {
      this.scheduleMode = 0;
      this.resetFilters();
    },

    searchMode(val) {
      this.resetFilters();
      this.$nextTick(() => {
        switch (val) {
          case "0":
            if (this.$refs.auto0) this.$refs.auto0.focus();
            break;
          case "1":
            if (this.$refs.auto1) this.$refs.auto1.focus();
            break;
          case "2":
            if (this.$refs.auto2) this.$refs.auto2.focus();
            break;
        }
      });
    },

    activeFacility() {
      this.getSchedule();
    },

    scheduleViewType() {
      this.filters2.areaFilter = -1;
      if (this.scheduleViewType === 0) {
        this.datesSelected = [];
      } else if (this.scheduleViewType === 1) {
        //this.datesSelected = null
        this.cleanDates(true);
      } else if (this.scheduleViewType === 2) {
        this.datesSelected = null;
      }
    },

    allRooms(val) {
      if (!val) {
        this.filters2.room = null;
      }
    },

    datesSelected() {
      this.refactorDates();

      if (this.scheduleMode == 0) {
        //this.getSchedule()
      }
    },

    scheduleMode() {
      this.resetFilters();
    },

    selectedFacilityLength() {
      this.officeWorktimeLock = false;
      this.multiOffice = [];
      this.wrapGeneralMenu();
    },

    employeeSlots() {
      if (
        this.employeeSlots &&
        this.employeeSlots.length > 0 &&
        !this.currentFacility
      ) {
        this.employeeSlots.forEach((element) => {
          let el = this.facilities.find((f) => f.id == element.facilityId);
          if (el) {
            this.selectedFacility.push(el);
          }
        });
      }
    },

    currentFacility() {
      if (this.currentFacility) {
        this.selectedFacility.push(this.currentFacility);
      }
    },

    editVisitTrigger() {
      if (this.searchMode == "0") {
        this.getSchedule3();
      } else if (this.searchMode == "1") {
        this.getSchedule9();
      } else if (this.searchMode == "2") {
        this.getSchedule1();
      }
    },

    allOffices: {
      deep: true,
      handler(valNew, valOld) {
        if (
          (valOld == null || (valOld != null && valOld.length == 0)) &&
          valNew != null &&
          valNew.length > 0
        ) {
          this.wrapGeneralMenu();
        }
      },
    },
  },

  data: function () {
    return {
      searchMode: "1",
      scheduleMode2: 0,
      generalMenu: [],
      scheduleMode: 0,
      vehicles: [],
      bigCalendar: false,
      lioczModal: false,
      vehiclesVisible: false,
      multipleMode: true,
      skipLeaves: true,
      onlyAvailable: false,
      roomSearchVisible: false,
      multiOffices: [],
      areaFilterItems: [],

      // filters: {
      //   dates: [],
      //   subjectId: -2,
      //   patientId: null,
      //   subject: null,
      //   spec: null,
      //   type: null,
      //   subtype: null,
      //   service: null,
      //   timeless: null,
      //   private: null
      // },

      // filters2: {
      //   "searchType": 0,
      //   "dates": [],
      //   "subjectId": null,
      //   "clinicId": null,
      //   "spec": null,
      //   "area": null
      // },

      filters2: {
        searchType: 0,
        dates: [],
        facilities: [],
        employee: -1,
        device: null,
        clinic: -1,
        spec: -1,
        area: -1,
        office: null,
        multiOffice: [],
        afterDay: null,
        useAreaFilter: true,
        areaFilter: -1,
      },

      selectedCluster: null,

      employee: null,
      requestInProgress: false,
      scheduleViewType: 2,
      datesSelected: new Date(),
      officeNameFilter: "",
      docFilter: "",
      clinicFilter: "",
      officeFilter: "",
      roomFilter: "",
      trigger: 0,
      editorTrigger: false,
      editorTriggerSlots: false,
      clearTrigger: 0,
      medicalDevices: [],
      offices: [],
      rooms: [],
      deviceFilter: "",
      device: null,
      selectedArea: -1,
      columnWidth: 500,
      includeRoom: false,
      hideFilters: false,
      allRooms: false,
      currentOffice: null,

      isWorktimeSchedulerModalActive: false,

      selectedFacility: [],
      selectedRooms: [],
      chosenRoom: null,
      facilityName: "",

      officeWorktimeLock: false,
      hasOnlyWorktimeScheduleOffices: false,

      employeeSlots: null,
      vehicleSelected: null,

      isActiveModalPatientChoice: false,
      loadingPatientSearch: false,
      patients: [],
      selectedPatient: null,
      clearPatientSearch: 0,
      newPatientPayload: null,
      newPatientTrigger: 0,

      showDisabledScheduleOffices: false,
    };
  },

  mounted() {
    let multiCookie = this.$cookies.get(Cookies.SCHEDULE_MULTICHOICE_REG);

    if (multiCookie != undefined && multiCookie != null) {
      this.multipleMode = multiCookie == "true";
    } else {
      // do nothing
    }

    this.getEmployeeSlots();
    this.downloadMedicalDevices();
    this.downloadVehicles();
    this.downloadRooms();

    this.selectedCluster = null; // this.clusters.length > 0 ? this.clusters[0] : null

    this.datesSelected = new Date();

    let moments = [];
    let thisMoment = moment(this.datesSelected);
    moments.push(thisMoment);

    for (let index = 1; index < 7; index++) {
      const momentAdded = moment(thisMoment).add(index, "days");
      moments.push(momentAdded);
    }

    for (let index = 0; index < moments.length; index++) {
      const element = moments[index];

      if (element.format("ddd") !== "Sun") {
        let local = element.format("YYYY-MM-DD");
        this.filters2.dates.push(local);
      }
    }

    if (this.currentFacility) {
      this.selectedFacility.push(this.currentFacility);
      this.facilityName = "";
    }

    this.wrapGeneralMenu();
  },

  computed: {
    officeTypes() {
      return this.$store.state.config.offices;
    },

    areaTypes() {
      return this.$store.state.config.areas;
    },

    selectedFacilityLength() {
      return this.selectedFacility.length;
    },

    filteredCounty() {
      return this.countyTotal.filter((option) => {
        return (
          option.nazwa
            .toString()
            .toLowerCase()
            .indexOf(this.countyFilter.toLowerCase()) >= 0
        );
      });
    },

    filteredGeneralMenu() {
      if (this.officeNameFilter && this.officeNameFilter.length > 0) {
        let pharse = this.officeNameFilter.toLowerCase();
        let menu = JSON.parse(JSON.stringify(this.generalMenu));

        menu.forEach((m) => {
          m.offices = m.offices.filter(
            (x) => x.pharse && x.pharse.toLowerCase().indexOf(pharse) >= 0
          );
        });

        return menu;
      } else {
        return this.generalMenu;
      }
    },

    filteredOfficesCount() {
      let count = 0;

      this.filteredGeneralMenu.forEach((m) => {
        if (m.offices) {
          count += m.offices.length;
        }
      });

      return count;
    },

    schedule2editor() {
      return (
        this.$store.state.employee.permits.schedule2editor ||
        this.$store.state.employee.permits.worktimeScheduler
      );
    },

    erSlotsEditor() { return this.$store.state.employee.permits.erSlotEditor },

    autoNavigateTarget() {
      return this.$store.state.registration.autoNavigateTarget;
    },
    autoNavigateTrigger() {
      return this.$store.state.registration.autoNavigateTrigger;
    },

    configOffices() {
      return this.$store.state.config.offices;
    },
    clusters() {
      return this.$store.state.config.clusters;
    },
    clinics() {
      return this.$store.state.config.clinics;
    },
    //facilities() { return this.$store.state.config.facilities },
    facilities() {
      let allfacs = this.$store.state.config.facilities;

      //if (this.allOffices) {
      if (this.allOffices) {
        let ids = [];
        this.allOffices.forEach((x) => {
          if (x.facilityId && !ids.includes(x.facilityId)) {
            ids.push(x.facilityId);
          }
        });

        let selected = [];

        ids.forEach((x) => {
          let alfc = allfacs.find((e) => e.id == x);

          if (alfc) {
            selected.push(alfc);
          }
        });

        return selected;
      } else return allfacs;
    },

    allOffices() {
      return this.$store.state.offices.offices;
    },

    activeFacility() {
      return this.$store.state.clinic.activeClinic;
    },
    patient() {
      return this.$store.state.registration.patient;
    },
    perms() {
      return this.$store.state.employee.permits;
    },
    me() {
      return this.$store.state.employee.me;
    },
    workers() {
      return this.$store.state.employee.all;
    },

    docs() {
      return this.$store.state.employee.all ?? [];
    },

    elmpoyeeFiltered() {
      return this.docs.filter((option) => {
        let id = (option.lastName + option.firstName).toLowerCase();
        if (this.docFilter != null) {
          return id.indexOf(this.docFilter.toLowerCase()) >= 0;
        } else return false;
      });
    },

    clinicAvailables() {
      if (this.availableOffices) {
        let ids = [];
        this.availableOffices.forEach((x) => {
          if (x.clinicId && !ids.includes(x.clinicId)) {
            ids.push(x.clinicId);
          }
        });

        let selected = [];

        ids.forEach((x) => {
          let alClin = this.clinics.find((e) => e.id == x);

          if (alClin) {
            selected.push(alClin);
          }
        });

        return selected.sort(this.sortById);
      } else return this.clinics;
    },

    clinicFiltered() {
      return this.clinicAvailables.filter((option) => {
        let id = option.name.toLowerCase();
        return id.indexOf(this.clinicFilter.toLowerCase()) >= 0;
      });
    },

    availableOffices() {
      if (this.allOffices) {
        return this.allOffices.filter((option) => {
          return option.hasScheduleDisabled == false;
        });
      } else return [];
    },

    officesFiltered() {
      return this.availableOffices.filter((option) => {
        let id = "";

        if (option.pharse) {
          id = option.pharse.toLowerCase();
        } else {
          id = option.name.toLowerCase();
        }

        return id.indexOf(this.officeFilter.toLowerCase()) >= 0;
      });
    },

    roomsFiltered() {
      return this.selectedRooms.filter((option) => {
        let id = option.info.toLowerCase();
        return id.indexOf(this.roomFilter.toLowerCase()) >= 0;
      });
    },

    roomsOptions() {
      if (this.filters2.office) {
        let array = this.rooms;

        if (this.currentOffice && this.currentOffice.facilityId) {
          if (!this.allRooms) {
            array = this.rooms.filter(
              (r) => r.facilityId === this.currentOffice.facilityId
            );
          }

          return array;
        } else return array;
      } else return [];
    },

    devicesFiltered() {
      return this.medicalDevices.filter((option) => {
        let id = option.name.toLowerCase();
        return id.indexOf(this.deviceFilter.toLowerCase()) >= 0;
      });
    },

    focusAppointment() {
      return this.$store.state.poz.focusAppointment;
    },
    focusBlock() {
      return this.$store.state.poz.focusBlock;
    },
    focusArea() {
      return this.$store.state.poz.focusArea;
    },

    currentFacility() {
      return this.$store.state.config.currentFacility;
    },

    editVisitTrigger() {
      return this.$store.state.registration.editVisitTrigger;
    },

    filteredFacilities() {
      if (this.facilities) {
        return this.facilities.filter((option) => {
          let result = false;
          if (option.name) {
            result =
              option.name
                .toString()
                .toLowerCase()
                .indexOf(this.facilityName.toLowerCase()) >= 0;
          }
          if (!result && option.address) {
            if (option.address.street) {
              result =
                option.address.street
                  .toString()
                  .toLowerCase()
                  .indexOf(this.facilityName.toLowerCase()) >= 0;
            }

            if (!result && option.address.city) {
              result =
                option.address.city
                  .toString()
                  .toLowerCase()
                  .indexOf(this.facilityName.toLowerCase()) >= 0;
            }
          }
          if (!result && option.name) {
            result =
              option.name
                .toString()
                .toLowerCase()
                .indexOf(this.facilityName.toLowerCase()) >= 0;
          }

          return result;
        });
      }

      return [];
    },

    registrationIkz() {
      return this.$store.state.registration.ikz;
    },

    facilitiesAll() {
      return this.$store.state.clinic.clinics;
    },

    meParentFacilityObject() {
      if (this.facilitiesAll) {
        var found = this.facilitiesAll.find(
          (x) => x.internalId === this.me.parentFacility
        );
        return found;
      } else return null;
    },
  },

  methods: {
    dateChosen() {
      if (this.scheduleViewType == 1) {
        this.$nextTick(() => {
          this.getScheduleAndHideDropdown();
        });
      }
    },

    selectFacility(option) {
      if (option) {
        let findFacility = this.selectedFacility.find(
          (facility) => facility.id == option.id
        );
        if (!findFacility) {
          this.selectedFacility.push(option);
          this.multiOffices = [];
        }
      }

      this.facilityName = "";
    },

    multiselect(offices, selectMode) {
      if (offices && this.multiOffices) {
        if (selectMode) {
          offices.forEach((o) => {
            if (this.multiOffices.includes(o)) {
              // ok
            } else {
              this.multiOffices.push(o);
            }
          });
        } else {
          offices.forEach((o) => {
            if (this.multiOffices.includes(o)) {
              let ind = this.multiOffices.indexOf(o);

              if (ind > -1) {
                this.multiOffices.splice(ind, 1);
              }
            }
          });
        }
      }
    },

    getScheduleAndHideDropdown() {
      if (this.$refs.datesPicker) {
        this.$refs.datesPicker.toggle();
      }

      this.onMenuOfficeClick(null);
    },

    areaMoved() {
      this.editorTrigger = false;
    },

    handleError(val) {
      this.requestInProgress = false;
      if (val) {
        this.snackbar("Błąd: " + val);
      }
    },

    getClinicName(id) {
      if (id < 0) return "Brak poradni";
      let category = this.clinics.find((c) => c.id == id);
      if (category) return category.name;
      else return `${id} - Poradnia nieokreślona`;
    },

    getOfficeName(id) {
      if (id < 0) return "Brak typu";
      let category = this.configOffices.find((c) => c.id == id);
      if (category) return category.name;
      else return `Typ nieokreślony (${id})`;
    },

    getEmployeeName(id) {
      if (id < 0) return "Brak pracownika";
      let category = this.workers.find((c) => c.id == id);
      if (category) return `${category.firstName} ${category.lastName}`;
      else return `Pracownik nieokreślony (${id})`;
    },

    changeDays(val) {
      if (this.scheduleViewType != 0) {
        var result = this.datesSelected.setDate(
          this.datesSelected.getDate() + val
        );
        this.datesSelected = new Date(result);

        if (moment(this.datesSelected).format("ddd") === "Sun") {
          let skipSunday = val > 0 ? 1 : -1;
          result = this.datesSelected.setDate(
            this.datesSelected.getDate() + skipSunday
          );
        }
        this.datesSelected = new Date(result);

        this.trigger++;
      } else {
        //do nothing, change on calendar :P
      }
    },

    toggleVehicles() {
      this.vehiclesVisible = !this.vehiclesVisible;

      if (this.vehiclesVisible) {
        this.scheduleViewType = 1;
      }
    },

    searchPatientEvent() {
      this.selectedPatient = null;
      this.clearPatientSearch += 1;
      this.isActiveModalPatientChoice = true;
    },

    selectPatient(regPatient) {
      this.selectedPatient = regPatient;
    },

    newPatientEvent(newPatientPayload) {
      this.newPatientPayload = newPatientPayload;

      this.$nextTick(() => {
        this.newPatientTrigger += 1;
      });
    },

    momentDate(item) {
      return moment(item);
    },

    cancelReschedule() {
      this.$store.commit(Mutation.APPOINTMENT_SIMPLE_FOCUS, null);
      this.editorTrigger = false;
    },

    // wrapGeneralMenuOLD() {
    //   let clins = []
    //   let facilityId = null

    //   if (this.selectedFacility) {
    //     let facility = this.facilities.find(f => f.name == this.selectedFacility)
    //     if (facility) {
    //       facilityId = facility.id
    //     }
    //   }

    //   this.allOffices.forEach(office => {
    //     if (facilityId) {
    //       //
    //       if (office.clinicId > -1 && office.facilityId == facilityId && !office.hasScheduleDisabled) {
    //         let existing = clins.find(c => c.id === office.clinicId)

    //         if (existing) {
    //           // cool
    //         }
    //         else {
    //           existing = { id: office.clinicId, types: [] }
    //           clins.push(existing)
    //         }

    //         let extype = existing.types.find(e => e.id === office.type)

    //         if (extype) {
    //           extype.offices.push(office)
    //         }
    //         else {
    //           extype = { id: office.type, offices: [] }
    //           extype.offices.push(office)
    //           existing.types.push(extype)
    //         }
    //       }

    //     } else {
    //       if (office.clinicId > -1 && !office.hasScheduleDisabled) {
    //         let existing = clins.find(c => c.id === office.clinicId)

    //         if (existing) {
    //           // cool
    //         }
    //         else {
    //           existing = { id: office.clinicId, types: [] }
    //           clins.push(existing)
    //         }

    //         let extype = existing.types.find(e => e.id === office.type)

    //         if (extype) {
    //           extype.offices.push(office)
    //         }
    //         else {
    //           extype = { id: office.type, offices: [] }
    //           extype.offices.push(office)
    //           existing.types.push(extype)
    //         }
    //       }
    //     }
    //   })

    //   this.generalMenu = clins.sort(this.sortById)
    // },

    wrapRooms() {
      let rooms = [];
      if (this.selectedFacility && this.selectedFacility.length > 0) {
        if (this.rooms) {
          rooms = this.rooms.filter((x) => {
            let findFacility = this.selectedFacility.find(
              (facility) => x.facilityId == facility.internalId
            );
            if (findFacility) {
              return true;
            }

            return false;
          });
        }
      }
      this.selectedRooms = rooms;
    },

    wrapGeneralMenu() {
      let clins = [];
      let facilitiesIds = [];
      let hasOnlyWorktimeScheduleOffices = false;

      if (this.selectedFacility && this.selectedFacility.length > 0) {
        this.selectedFacility.forEach((facility) =>
          facilitiesIds.push(facility.id)
        );
      }

      this.allOffices.forEach((office) => {
        if (facilitiesIds && facilitiesIds.length > 0) {
          let findFacility = facilitiesIds.find(
            (facilityId) => office.facilityId == facilityId
          );

          if (
            office.clinicId > -1 &&
            findFacility &&
            !office.hasScheduleDisabled
          ) {
            let existing = clins.find((c) => c.id === office.clinicId);

            if (existing) {
              // cool
            } else {
              existing = { id: office.clinicId, offices: [] };
              clins.push(existing);
            }

            if (this.officeWorktimeLock && office.onlyWorktimeSchedule) {
              existing.offices.push(office);
            }

            if (!this.officeWorktimeLock && !office.onlyWorktimeSchedule) {
              existing.offices.push(office);
            }
          }
        } else {
          if (office.clinicId > -1 && !office.hasScheduleDisabled) {
            let existing = clins.find((c) => c.id === office.clinicId);

            if (existing) {
              // cool
            } else {
              existing = { id: office.clinicId, offices: [] };
              clins.push(existing);
            }

            if (this.officeWorktimeLock && office.onlyWorktimeSchedule) {
              existing.offices.push(office);
            }

            if (!this.officeWorktimeLock && !office.onlyWorktimeSchedule) {
              existing.offices.push(office);
            }
          }
        }
      });

      if (clins) {
        clins.forEach((x) => {
          if (x.offices && x.offices.length > 0) {
            x.offices = x.offices.sort(this.sortByName);
          }
        });
      }

      let findOnlyWorktimeOffice = this.allOffices.filter(
        (office) =>
          office.clinicId > -1 &&
          !office.hasScheduleDisabled &&
          office.onlyWorktimeSchedule
      );
      if (facilitiesIds && facilitiesIds.length > 0) {
        findOnlyWorktimeOffice = findOnlyWorktimeOffice.filter((office) => {
          let findFacility = facilitiesIds.find(
            (facilityId) => office.facilityId == facilityId
          );
          if (findFacility) {
            return true;
          }

          return false;
        });
      }
      if (findOnlyWorktimeOffice.length > 0) {
        hasOnlyWorktimeScheduleOffices = true;
      }

      this.hasOnlyWorktimeScheduleOffices = hasOnlyWorktimeScheduleOffices;

      if (this.showDisabledScheduleOffices) {
        clins.forEach((type) => {
          let scheduleBlockedOffices = type.offices.filter(
            (office) => office.isScheduleBlocked === true
          );
          type.offices = scheduleBlockedOffices;
        });
      } else {
        clins.forEach((type) => {
          let visibleOffices = type.offices.filter(
            (office) => office.isScheduleBlocked === false
          );
          type.offices = visibleOffices;
        });
      }

      clins.sort(this.sortById);

      this.generalMenu = clins;
    },

    sortById(a, b) {
      return a.id - b.id;
    },

    sortByName(a, b) {
      const nameA = a.name.toUpperCase(); // ignore upper and lowercase
      const nameB = b.name.toUpperCase(); // ignore upper and lowercase

      if (nameA < nameB) {
        return -1;
      } else if (nameA > nameB) {
        return 1;
      } else {
        return 0;
      }
    },

    resetFilters() {
      this.filters2 = {
        searchType: 0,
        dates: this.filters2.dates,
        facilities: this.filters2.facilities,
        employee: -1,
        device: null,
        clinic: -1,
        spec: -1,
        area: -1,
        office: null,
        vehicle: -1,
        multiOffice: [],
        afterDay: null,
      };

      this.clinicFilter = "";
      this.officeFilter = "";
      this.docFilter = "";
    },

    downloadOffices() {
      this.loading = true;
      this.$store
        .dispatch(Action.OFFICE_GET_ALL)
        .then((data) => {
          this.offices = data;
          this.loading = false;
        })
        .catch((error) => {
          this.apiProblem(error);
          this.loading = false;
        });
    },

    downloadRooms() {
      this.$store
        .dispatch(Action.INVENTORY_GET_ALL_ROOMS)
        .then((response) => {
          response.forEach((x) => {
            x.info = `${
              x.number ? "nr " + x.number + ", " : ""
            } ${this.mGetRoomType(x.type)}, ${
              x.description ? ", " + x.description : ""
            }`;
          });

          this.rooms = response;
          this.wrapRooms();
        })
        .catch((error) => {
          this.apiProblem(error);
        });
    },

    downloadMedicalDevices() {
      this.requestInProgress = true;
      this.$store
        .dispatch(Action.MEDICAL_DEVICE_GET_ALL)
        .then((data) => {
          this.medicalDevices = data;
          this.requestInProgress = false;
        })
        .catch((error) => {
          this.apiProblem(error);
          this.requestInProgress = false;
        });
    },

    downloadVehicles() {
      this.requestInProgress = true;
      this.$store
        .dispatch(Action.MOBI2_GET_VEHICLES)
        .then((data) => {
          data.forEach((x) => {
            x.name = `${x.registrationNumber} ${x.brand} ${x.model}`;
          });
          this.vehicles = data;
          this.requestInProgress = false;
        })
        .catch((error) => {
          this.apiProblem(error);
          this.requestInProgress = false;
        });
    },

    getItem(id) {
      let category = this.configOffices.find((c) => c.id == id);
      if (category) return category.name;
      else return `???`;
    },

    cleanDates(resetToToday) {
      while (this.filters2.dates.length > 0) this.filters2.dates.pop();

      if (resetToToday) {
        this.scheduleViewType = 1;
        this.$nextTick(() => {
          this.datesSelected = new Date();
        });
      } else {
        this.scheduleViewType++;
        this.$nextTick(() => {
          this.scheduleViewType--;
        });
      }
    },

    refactorDates() {
      this.filters2.dates = [];

      if (this.datesSelected) {
        switch (this.scheduleViewType) {
          case 0: {
            this.datesSelected.forEach((d) => {
              this.filters2.dates.push(moment(d).format("YYYY-MM-DD"));
            });
            break;
          }
          case 1: {
            let moments = [];
            let thisMoment = moment(this.datesSelected);
            moments.push(thisMoment);

            for (let index = 1; index < 7; index++) {
              const momentAdded = moment(thisMoment).add(index, "days");
              moments.push(momentAdded);
            }

            for (let index = 0; index < moments.length; index++) {
              const element = moments[index];

              if (element.format("ddd") !== "Sun") {
                let local = element.format("YYYY-MM-DD");
                this.filters2.dates.push(local);
              }
            }
            break;
          }
        }
      }
    },

    onAppointmentCreated(payload) {
      this.$emit("created", payload);
    },

    onAppointmentUpdate(payload) {
      this.$emit("update", payload);
    },

    onAppointmentRemove(payload) {
      this.$emit("remove", payload);
    },

    getSchedule() {
      if (this.$refs.scheduleColumn) {
        this.columnWidth = this.$refs.scheduleColumn.clientWidth;
      }

      this.filters2.onlyAvailable = this.onlyAvailable;
      this.filters2.skipLeaves = this.skipLeaves;

      this.requestInProgress = true;
      this.trigger++;
    },

    getSchedule0() {
      this.filters2.searchType = 0;
      this.getSchedule();
    },

    getSchedule1() {
      this.filters2.searchType = 5;
      this.filters2.area = this.selectedArea;
      this.getSchedule();
    },

    getSchedule2() {
      this.filters2.searchType = 5;
      this.getSchedule();
    },

    getSchedule4() {
      this.filters2.employee = -1;
      this.filters2.spec = -1;
      this.filters2.area = -1;
      this.filters2.clinic = -1;
      this.filters2.searchType = 4;
      this.filters2.area = -1;
      this.getSchedule();
    },

    getSchedule5() {
      this.filters2.employee = -1;
      this.filters2.spec = -1;
      this.filters2.area = -1;
      this.filters2.clinic = -1;
      this.filters2.searchType = 5;
      this.filters2.area = -1;
      this.getSchedule();
    },

    getSchedule20() {
      this.filters2.employee = -1;
      this.filters2.spec = -1;
      this.filters2.area = -1;
      this.filters2.clinic = -1;
      this.filters2.searchType = 20;

      if (this.vehicleSelected) {
        this.filters2.vehicle = this.vehicleSelected.id;
        this.getSchedule();
      }
    },

    getSchedule3() {
      this.filters2.searchType = 3;
      this.getSchedule();
    },

    getSchedule8() {
      if (this.includeRoom) {
        this.filters2.searchType = 4;
      } else {
        this.filters2.searchType = 8;
      }

      if (this.currentOffice && this.currentOffice.employee > -1) {
        this.filters2.employee = -1; // this.currentOffice.employee
      }

      this.getSchedule();
    },

    onMenuOfficeClick(office) {
      if (this.multipleMode) {
        this.filters2.multiOffice = [];
        this.multiOffices.forEach((x) => this.filters2.multiOffice.push(x.id));

        if (this.scheduleViewType < 2) {
          // If 0 or 1 -> go for specific days
          this.getSchedule11();
        } else {
          // If 2 -> go for first available
          this.getSchedule12();
        }
      } else {
        if (office) {
          this.currentOffice = office;
        }

        if (this.currentOffice) {
          this.filters2.office = this.currentOffice.id;
          this.filters2.employee = -1; // this.currentOffice.employee

          if (this.scheduleViewType < 2) {
            // If 0 or 1 -> go for specific days
            this.getSchedule8();
          } else {
            // If 2 -> go for first available
            this.getSchedule9();
          }
        }
      }
    },

    getSchedule9() {
      if (this.includeRoom) {
        this.filters2.searchType = 5;
      } else {
        this.filters2.searchType = 9;
      }

      if (this.currentOffice && this.currentOffice.employee > -1) {
        this.filters2.employee = -1; // this.currentOffice.employee
      }

      this.getSchedule();
    },

    getSchedule6() {
      this.filters2.searchType = 6;
      this.filters2.device = this.device.id;
      this.getSchedule();
    },

    getSchedule11() {
      this.filters2.searchType = 11;
      this.filters2.employee = -1;
      this.getSchedule();
    },
    getSchedule12() {
      this.filters2.searchType = 12;
      this.filters2.employee = -1;
      this.getSchedule();
    },

    selectedClinic(item) {
      this.resetFilters();
      this.docFilter = "";
      this.officeFilter = "";
      this.scheduleForClinic(item);
    },

    selectedVehicle(item) {
      this.resetFilters();
      this.docFilter = "";
      this.officeFilter = "";
      this.vehicleSelected = item;
      this.scheduleForVehicle(item);
    },

    selectedOffice(item) {
      this.resetFilters();
      this.docFilter = "";
      this.clinicFilter = "";
      this.roomFilter = "";
      this.currentOffice = item;
      this.scheduleForOffice(item);
    },

    selectedRoom(item) {
      this.resetFilters();
      this.docFilter = "";
      this.clinicFilter = "";
      this.chosenRoom = item;
      this.scheduleForRoom(item);
    },

    scheduleForVehicle(item) {
      if (item) {
        this.filters2.employee = -1;
        this.filters2.spec = -1;
        this.filters2.area = -1;
        this.filters2.clinic = -1;
        this.filters2.vehicle = item.id;
        this.device = null;
      }

      //this.getSchedule()
    },

    scheduleForClinic(item) {
      if (item) {
        this.filters2.employee = -1;
        this.filters2.spec = -1;
        this.filters2.area = -1;
        this.filters2.clinic = item.id;
        this.device = null;
      }

      //this.getSchedule()
    },

    scheduleForOffice(item) {
      if (item) {
        if (item) this.filters2.office = item.id;
        else this.filters2.office = null;
        this.filters2.employee = -1; // item.employee
        this.filters2.room = null;
      }
    },

    scheduleForRoom(item) {
      if (item) this.filters2.room = item.id;
      else this.filters2.room = null;
    },

    selectedEmployee(item) {
      this.resetFilters();
      if (item) {
        this.employee = item;
        this.filters2.employee = item.id;
        if (
          this.employee.specializations &&
          this.employee.specializations.length > 0
        ) {
          this.filters2.spec = this.employee.specializations[0];
        } else {
          this.filters2.spec = -1;
        }
      } else this.employee = -1;
    },

    selectedDevice(item) {
      if (item) this.device = item;
      else this.device = null;
    },

    openWorktimeScheduler() {
      this.isWorktimeSchedulerModalActive = true;
    },

    getEmployeeSlots() {
      this.requestInProgress = true;
      if (this.me && this.me.id) {
        this.$store
          .dispatch(Action.SLOT_GET_BY_EMPLOYEE, this.me.id)
          .then((response) => {
            this.employeeSlots = response;
            this.requestInProgress = false;
          })
          .catch((error) => {
            this.snackbar("Błąd podczas pobierania slotów pracowników.");
            this.apiProblem(error);
            this.requestInProgress = false;
          });
      }
    },

    openVisitModalAdd() {
      this.isActiveModalPatientChoice = false;
      this.$store.commit(
        Mutation.REGISTRATION_SET_PATIENT,
        this.selectedPatient
      );
      this.$store.commit(Mutation.REGISTRATION_SET_SCHEDULE, []);
      this.$store.commit(Mutation.REGISTRATION_CLEAR_EREFERRALS);
      this.initBundle(this.selectedPatient);
      this.$store.commit(Mutation.SCHEDULE_SET_ONECLICK_ADD_MODAL, true);
    },

    cancelSearch() {
      this.isActiveModalPatientChoice = false;
      this.$store.commit(Mutation.SCHEDULE_SET_ONECLICK_ADD_MODAL, false);
      this.$store.commit(Mutation.SCHEDULE_SET_CURRENT_BLOCK, null);
    },

    initBundle(item) {
      let facilityId = this.meParentFacilityObject
        ? this.meParentFacilityObject.id
        : null;
      let bundle = {
        patientId: item.id,
        employeeId: this.me.id,
        type: 1,
        status: 1,
        facilityId: facilityId,
      };

      this.$store
        .dispatch(Action.PD_ADD_BUNDLE, bundle)
        .then((response) => {
          if (response.data) {
            this.$store.commit(
              Mutation.REGISTRATION_ADD_ACTIVE_BUNDLE,
              response.data
            );
            this.addIkzAddActiveBundle();
          }
        })
        .catch((error) => {
          this.apiProblem(error);
        });
    },

    addIkzAddActiveBundle() {
      let facilityId = this.meParentFacilityObject
        ? this.meParentFacilityObject.id
        : null;
      let selected = this.registrationIkz.find((x) => x.internalId == 588);

      if (selected) {
        var payload = {
          ikzId: selected.id,
          employeeId: this.me.id,
          patientId: this.patient.id,
          group: selected.group,
          type: selected.type,
          source: selected.source,
          points: selected.points,
          ikzInternalId: selected.internalId,
          name: selected.name,
          entry: 3, // 3 - ENTRY - AUTOMATYCZNE
          amount: 1,
          facility: facilityId,
        };

        this.$store
          .dispatch(Action.FINANCE_USER_IKZ_ADD, payload)
          .then(() => {
            //this.successSnackbar("Dodano IKZ");
          })
          .catch((error) => {
            this.apiProblem(error);
          });
      }
    },

    async showPatientDataEvent(patientEvent) {
      if (patientEvent) {
        if (this.patient && this.patient.id === patientEvent.id) {
          this.$emit("show-patient-data");
        } else {
          let fullPatient = await this.getPatientById(patientEvent);
          if (fullPatient) {
            this.$store.commit(Mutation.REGISTRATION_SET_PATIENT, fullPatient);
            this.initBundle(fullPatient);
            this.$emit("show-patient-data");
          }
        }
      }
    },

    async getPatientById(patient) {
      let findPatient = null;
      await this.$store
        .dispatch(Action.PATIENT_GET_BY_ID, patient.id)
        .then((response) => {
          if (response && response.data) {
            findPatient = response.data;
          }
        })
        .catch((error) => {
          this.apiProblem(error);
        });
      return findPatient;
    },

    emitSearchCompleted(searchCompleted) {
      this.isActiveModalPatientChoice = false;
      this.$emit("search-completed", searchCompleted);
    },
  },
};
</script>

<style scoped lang="scss">
.box-grow {
  flex: 1 1 auto; /* formerly flex: 1 0 auto; */
  background: green;
  padding: 5px;
  margin: 5px;
  overflow: auto; /* new */
}
</style>
