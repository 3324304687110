<template>
  <div ref="scheduleContainer">
    <div :class="{'editor-background':editorMode, 'editor-background-slots': editorSlotsMode}">
      <!--          -->
      <!-- TOP MENU -->
      <!--          -->
      <!-- Top menu contains schedule options and day/view scroll buttons -->
      <div class="mb-3">
        <b-dropdown aria-role="list">
          <template #trigger>
            <!-- Options trigger button -->
            <b-button
              size="is-small"
              icon-left="cog"
              class="mr-1"
            >Opcje</b-button>
          </template>
          <div
            class="pl-3 pr-3 pt-1 pb-1"
            style="width: 20rem;"
          >
            <!-- Hours visibility -->
            <b-field
              label="Widoczne godziny"
              label-position="on-border"
            >
              <b-field
                class="control"
                expanded
              >
                <b-select
                  expanded
                  size="is-small"
                  v-model="hourFrom"
                >
                  <option
                    v-for="item in hoursFrom"
                    :key="item.value"
                    :value="item.value"
                  >{{item.text}}</option>
                </b-select>
              </b-field>
              <b-field
                expanded
                class="control"
              >
                <b-select
                  expanded
                  size="is-small"
                  v-model="hourTo"
                >
                  <option
                    v-for="item in hoursTo"
                    :key="item.value"
                    :value="item.value"
                  >{{item.text}}</option>
                </b-select>
              </b-field>
            </b-field>
            <!-- Schedule scale -->
            <b-field
              label="Skala terminarza"
              label-position="on-border"
              class="mb-1"
            >
              <b-select
                expanded
                v-model="pixelsPerMinute"
                size="is-small"
              >
                <option :value="2">Mała</option>
                <option :value="2.4">Normalna</option>
                <option :value="3">Duża</option>
              </b-select>
            </b-field>
            <!-- Appointment types on tiles option -->
            <b-field class="mb-1">
              <b-checkbox
                size="is-small"
                v-model="show5minutes"
              >Pokaż linie 5-minutowe</b-checkbox>
            </b-field>
            <b-field class="mb-1">
              <b-checkbox
                size="is-small"
                v-model="showAppointmentTypeLabelOnBlocks"
              >Pokaż typy wizyt na terminarzu</b-checkbox>
            </b-field>
            <!-- My tasks tiles option -->
            <!-- <b-field v-if="!predefinedDayMode" class="mb-1">
              <b-checkbox size="is-small" v-model="showMyTasks">Pokaż moje zadania</b-checkbox>
            </b-field>-->
            <!-- Darker tile background option -->
            <b-field
              v-if="!predefinedDayMode"
              class="mb-1"
            >
              <b-checkbox
                size="is-small"
                v-model="showReversedBlockColors"
              >Ciemniejsze kolory wizyt</b-checkbox>
            </b-field>
            <!-- Appointment types on print option -->
            <b-field class="mb-1">
              <b-checkbox
                size="is-small"
                v-model="showAppointmentTypeOnPrint"
              >Pokaż typy wizyt na wydruku</b-checkbox>
            </b-field>
            <b-field class="mb-1">
              <b-checkbox
                size="is-small"
                v-model="fixedEnabled"
              >Pokaż nagłowki kolumn po przewinięciu</b-checkbox>
            </b-field>
          </div>
        </b-dropdown>
        <!-- <b-button
          icon-left="help-circle-outline"
          class="mr-1"
          size="is-small"
          @click="infoModalActive = true"
        ></b-button>-->

        <ScheduleDynoLegend
          :schedule="payload"
          style="display:inline;"
        ></ScheduleDynoLegend>

        <!--                     -->
        <!-- Editor mode actions -->
        <!--                     -->
        <div
          style="display:inline;"
          v-if="editorMode && payload && payload.days && !predefinedDayMode"
        >
          <b-button
            size="is-small"
            type="is-danger"
            class="mr-1"
            icon-left="alert"
            disabled
          >TRYB KONFIGURACJI</b-button>
          <!-- Days selection for editor -->
          <b-button
            class="mr-1"
            size="is-small"
            @click="allDays(true)"
            icon-left="check-all"
            :disabled="editorCurrentOffice == null"
          >Wszystkie dni dla wybranego gabinetu</b-button>
          <b-button
            class="mr-1"
            size="is-small"
            @click="allDays(false)"
            icon-left="close"
          >Odznacz dni</b-button>
          <!-- Scroller buttons -->
          <b-button
            size="is-small"
            class="mr-1 is-pulled-right"
            @click="scrollRightZero"
            icon-right="chevron-right-box-outline"
          ></b-button>
          <b-button
            size="is-small"
            class="mr-1 is-pulled-right"
            @click="scrollRight"
            icon-right="menu-right"
          >Widok w prawo</b-button>
          <b-button
            class="mr-1 is-pulled-right"
            size="is-small"
            @click="scrollLeft"
            icon-left="menu-left"
          >Widok w lewo</b-button>
          <b-button
            size="is-small"
            class="mr-1 is-pulled-right"
            @click="scrollLeftZero"
            icon-right="chevron-left-box-outline"
          ></b-button>

          <b-dropdown
            aria-role="list"
            v-if="schedule2editor"
          >
            <template #trigger>
              <!-- Options trigger button -->
              <b-button
                size="is-small"
                icon-left="checkbox-multiple-marked-outline"
                class="mr-1"
              >Zaznaczenie grupowe</b-button>
            </template>
            <div
              class="pl-3 pr-3 pt-1 pb-1"
              style="width: 20rem;"
            >
              <b-field grouped>
                <b-button
                  expanded
                  size="is-small"
                  @click="switchAreasSelection(true)"
                  icon-left="checkbox-marked-outline"
                >Zaznacz wszystko</b-button>
                <b-button
                  class="ml-3"
                  @click="switchAreasSelection(false)"
                  size="is-small"
                  icon-left="checkbox-blank-off-outline"
                >Usuń zaznaczenie</b-button>
              </b-field>
              <b-button
                expanded
                size="is-small"
                class="mb-1"
                type="is-danger"
                @click="multiDelete"
                icon-left="delete-alert-outline"
              >Usuń zaznaczone strefy</b-button>
            </div>
          </b-dropdown>
        </div>
        <!--                           -->
        <!-- Non editor action buttons -->
        <!--                           -->
        <div
          class="is-inline"
          v-else-if="payload && payload.days && !predefinedDayMode"
        >
          <!-- Day scrollers -->
          <b-button
            :disabled="payload.days.length < 1"
            size="is-small"
            class="mr-1"
            icon-left="chevron-double-left"
            @click="changeDayRange(-7)"
          >Tydzień wcześniej</b-button>
          <b-button
            :disabled="payload.days.length < 1"
            size="is-small"
            class="mr-1"
            icon-left="chevron-left"
            @click="changeDayRange(-1)"
          >Dzień wcześniej</b-button>
          <b-button
            size="is-small"
            class="mr-1"
          >Znaleziono {{ payload.days.length }} dni</b-button>
          <b-button
            size="is-small"
            :disabled="payload.days.length < 1"
            class="mr-1"
            icon-right="chevron-right"
            @click="changeDayRange(1)"
          >Dzień później</b-button>
          <b-button
            class="mr-1"
            :disabled="payload.days.length < 1"
            size="is-small"
            icon-right="chevron-double-right"
            @click="changeDayRange(7)"
          >Tydzień później</b-button>
          <b-button
            size="is-small"
            class="mr-1 is-pulled-right"
            @click="scrollRightZero"
            icon-right="chevron-right-box-outline"
          ></b-button>
          <!-- View scrollers -->
          <b-button
            size="is-small"
            class="mr-1 is-pulled-right"
            @click="scrollRight"
            icon-right="menu-right"
          >Widok w prawo</b-button>
          <b-button
            class="mr-1 is-pulled-right"
            size="is-small"
            @click="scrollLeft"
            icon-left="menu-left"
          >Widok w lewo</b-button>
          <b-button
            size="is-small"
            class="mr-1 is-pulled-right"
            @click="scrollLeftZero"
            icon-right="chevron-left-box-outline"
          ></b-button>
        </div>
      </div>
      <!--                 -->
      <!-- SCHEDULE ALERTS -->
      <!--                 -->
      <!-- Loading box -->
      <transition
        name="fade"
        mode="out-in"
      >
        <div
          v-show="loading"
          class="card pl-5 pt-3 pr-5 pb-5 loading-schedule-box"
        >
          <p class="has-text-centered mb-0 p-3">
            <b-icon
              icon="calendar-clock"
              size="is-large"
            ></b-icon>
          </p>
          <p class="has-text-centered mb-2 subtitle">Aktualizacja danych</p>
          <b-progress
            :rounded="false"
            class="w-100"
            size="is-small"
            type="is-secondary"
            show-value
          ></b-progress>
        </div>
      </transition>
      <div
        v-show="editorActive && selectedDays && selectedDays.length < 1"
        class="card pl-5 pt-3 pr-5 pb-5 no-office-schedule-box"
      >
        <p class="has-text-centered mb-0 p-3">
          <b-icon
            icon="arrow-top-right-bold-box-outline"
            size="is-large"
          ></b-icon>
        </p>
        <p class="has-text-centered title is-6">Aktywuj wybrany dzień, aby rozpocząć dodawanie stref</p>
      </div>
      <!-- No data message -->
      <!-- <div
        v-if="payload == null || payload.days==null || payload.days.length == 0"
        class="empty-schedule-box has-text-grey card p-5"
        style="z-index:20;"
      >
        <p>
          <b-icon size="is-large" type="is-grey" icon="calendar-remove"></b-icon>
        </p>
        <p>Brak danych</p>
      </div>-->

      <table
        style="max-width:100%;"
        :class="{
          'table-clear': timeOnlyMode === true
        }"
      >
        <tr>
          <td>
            <!--           -->
            <!-- TIMETABLE -->
            <!--           -->
            <div class="timeline-container">
              <div class="header-height-time"></div>
              <b-button
                size="is-small"
                type="is-text"
                icon-left="clock-outline"
                class="mt-2"
              ></b-button>
              <div style="position: relative;font-size:0.7rem;">
                <!-- Half-hour text markers -->
                <div
                  v-for="item in markers"
                  :key="item.text"
                  :style="timetableBlockStyle"
                  class="pr-1"
                  :class="item.class"
                >{{item.text}}</div>

                <!-- Time line markers -->
                <!-- <div
              class="timeline"
              v-for="item in timelinesCount"
              :key="item"
              :style="getLineStyle(item - 1)"
                ></div>-->

                <!-- Current time marker -->
                <!-- <div class="time-marker" :style="timeMarkerStyle"></div> -->
              </div>
            </div>
          </td>
          <td style="max-width:calc(100vw - 27rem);">
            <!--                 -->
            <!-- SCHEDULE VIEWER -->
            <!--                 -->
            <div
              class="schedule2-wrapper"
              ref="scroller"
              @scroll="handleXScroll"
              style="overflow-x: scroll;"
            >
              <!--                      -->
              <!-- ACTUAL SCHEDULE GRID -->
              <!--                      -->
              <div>
                <!-- <div
            v-if="payload == null || payload.days==null || payload.days.length == 0"
            class="subtitle pt-5 has-text-grey"
                >Brak danych</div>-->
                <div
                  v-if="payload.days"
                  class="schedule2-container"
                >
                  <!-- Editor mode slot hour tooltip -->
                  <div
                    v-if="tooltipVisible && editorMode"
                    :style="`position:fixed;left:${tooltipX}px;top:${tooltipY}px;z-index:19;`"
                    class="card p-1"
                  >{{tooltipA}} do {{tooltipB}}</div>

                  <!--                    -->
                  <!-- Single day columns -->
                  <!--                    -->
                  <div
                    v-for="day in payload.days"
                    :ref="day.id"
                    :key="day.id"
                    @mouseleave="generalMouseLeave"
                    style="position: relative;"
                  >
                    <!--            -->
                    <!-- Day header -->
                    <!--            -->
                    <!-- <div class="header-height has-background-white" style="z-index: 16 !important;">
                <div style="padding-top: 0rem;" class="card pb-1 pl-2 pr-2 mb-1"></div>
                    </div>-->

                    <transition
                      name="fade"
                      mode="out-in"
                    >
                      <div
                        class="card fixed-header"
                        :style="`${day.boxStyle};left:0;top: calc(${scrollTop}px - 10.6rem);`"
                        v-show="fixedVisible"
                      >
                        <table :style="day.boxStyle">
                          <tr>
                            <td
                              colspan="2"
                              class="pr-2"
                            >
                              <div
                                style="position: relative;"
                                id="colsth"
                              >
                                <!--              -->
                                <!-- Column label -->
                                <!--              -->
                                <div>
                                  <!-- Office header -->
                                  <p
                                    class="is-size-7 has-text-weight-semibold has-text-centered"
                                    style="margin-bottom: 0.2rem;height: 2.7rem; line-height: 0.9rem;"
                                    v-if="day.office"
                                  >
                                    <!-- Facility info -->
                                    <span
                                      v-if="day.office && day.office.facilityInfo"
                                      class="has-text-weight-bold has-text-secondary is-size-7 has-text-right"
                                    >
                                      <b-tooltip
                                        position="is-bottom"
                                        multilined
                                        :label="day.office.facilityInfo.name"
                                      >[{{day.office.facilityInfo.shortName}}]</b-tooltip>
                                    </span>
                                    {{day.office.nameBase}}
                                  </p>

                                  <!-- Employee header -->
                                  <p
                                    class="is-size-7 has-text-weight-semibold"
                                    style="margin-bottom: 0.3rem;height: 2.7rem;"
                                    v-else-if="day.subjectId > 0"
                                  >{{getWorkerNameOnly(day.subjectId)}}</p>
                                </div>

                                <!-- Office header FIXED -->
                                <!-- <div class="card pl-1 pt-2 pr-1 fixed-header" :style="calcTopPoz(day.id)">
                        {{scrollTop}}
                        <p
                          class="is-size-7 has-text-weight-semibold"
                          style="margin-bottom: 0.2rem;height: 2.7rem; line-height: 0.9rem;"
                          v-if="day.office"
                        >
                          <span
                            v-if="day.office && day.office.facilityInfo"
                            class="has-text-weight-bold has-text-secondary is-size-7 has-text-right"
                          >
                            <b-tooltip
                              position="is-bottom"
                              multilined
                              :label="day.office.facilityInfo.name"
                            >[{{day.office.facilityInfo.shortName}}]</b-tooltip>
                          </span>
                          {{day.office.nameBase}}
                        </p>
                        <p
                          class="is-size-7 has-text-weight-semibold"
                          style="margin-bottom: 0.3rem;height: 2.7rem;"
                          v-else-if="day.subjectId > 0"
                        >{{getWorkerNameOnly(day.subjectId)}}</p>

                        <p>
                          <span class="has-text-weight-bold">{{ mDateNoYear(day.date) }}</span>
                          <span
                            class="ml-1 has-text-weight-semibold"
                            :class="{'has-text-secondary' : !selectedDays.includes(day.id)}"
                          >{{ getDayVerb(day.date) }}</span>
                        </p>
                                </div>-->
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td
                              colspan="2"
                              class="pr-2"
                              style="padding-bottom:0.18rem;"
                            >
                              <div :class="{'pr-1': showMyTasks}">
                                <!-- Buttons for editor view only -->
                                <b-field
                                  v-if="editorMode"
                                  class="mb-0"
                                >
                                  <p class="control is-expanded">
                                    <b-checkbox-button
                                      size="is-small"
                                      :rounded="false"
                                      type="is-secondary"
                                      v-model="selectedDays"
                                      style="z-index: 12;"
                                      expanded
                                      :disabled="isOfficeDayDisabled(day)"
                                      :native-value="day.id"
                                    >
                                      <b-tooltip
                                        type="is-secondary"
                                        label="Uwzględnij ten dzień przy określaniu stref"
                                      >
                                        <span
                                          class="has-text-weight-semibold"
                                          v-if="selectedDays.includes(day.id)"
                                        >Wyłącz</span>
                                        <span
                                          class="has-text-weight-semibold"
                                          v-else
                                        >Aktywuj</span>
                                        <span class="pl-3 has-text-weight-bold">{{ mDateNoYear(day.date) }}</span>
                                        <span
                                          class="ml-1 has-text-weight-semibold"
                                          :class="{'has-text-secondary' : !selectedDays.includes(day.id)}"
                                        >{{ getDayVerb(day.date) }}</span>
                                      </b-tooltip>
                                    </b-checkbox-button>
                                  </p>
                                </b-field>
                                <!-- Buttons for non normal view only -->
                                <b-field v-else>
                                  <p class="control is-expanded">
                                    <b-button
                                      expanded
                                      size="is-small"
                                    >
                                      <span class="has-text-weight-bold">{{ mDateNoYear(day.date) }}</span>
                                      <span class="ml-1 has-text-secondary has-text-weight-semibold">{{ getDayVerb(day.date) }}</span>
                                    </b-button>
                                  </p>
                                  <p class="control">
                                    <b-tooltip
                                      type="is-secondary"
                                      label="Wizyty bez godziny"
                                    >
                                      <b-button
                                        size="is-small"
                                        expanded
                                        style="z-index: 12;"
                                        icon-left="clock-alert-outline"
                                        :type="{ 'is-secondary': day.timeless.length > 0 }"
                                        @click="showTimeless(day)"
                                      >
                                        <span
                                          class="pl-1"
                                          :class="{'has-text-weight-semibold':day.timeless.length > 0}"
                                        >{{day.timeless.length}}</span>
                                      </b-button>
                                    </b-tooltip>
                                  </p>
                                  <p class="control">
                                    <b-button
                                      v-if="editorMode"
                                      :disabled="day.worktimes.length < 1"
                                      size="is-small"
                                      style="z-index: 12;"
                                      icon-left="sort-calendar-ascending"
                                      @click="triggerMultiAreaChange(day)"
                                    ></b-button>
                                    <b-button
                                      v-else
                                      size="is-small"
                                      style="z-index: 12;"
                                      icon-left="printer"
                                      @click="printPatients(day)"
                                    ></b-button>
                                  </p>
                                </b-field>
                              </div>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </transition>

                    <table :style="day.boxStyle">
                      <tr>
                        <td
                          colspan="2"
                          class="pr-2"
                        >
                          <div
                            style="position: relative;"
                            id="colsth"
                          >
                            <!--              -->
                            <!-- Column label -->
                            <!--              -->
                            <div>
                              <!-- Office header -->
                              <p
                                class="is-size-7 has-text-weight-semibold has-text-centered"
                                style="margin-bottom: 0.2rem;height: 2.7rem; line-height: 0.9rem;"
                                v-if="day.office"
                              >
                                <!-- Facility info -->
                                <span
                                  v-if="day.office && day.office.facilityInfo"
                                  class="has-text-weight-bold has-text-secondary is-size-7 has-text-right"
                                >
                                  <b-tooltip
                                    position="is-bottom"
                                    multilined
                                    :label="day.office.facilityInfo.name"
                                  >[{{day.office.facilityInfo.shortName}}]</b-tooltip>
                                </span>
                                {{day.office.nameBase}}
                                <span
                                  class="has-text-danger"
                                  v-if="day.office.isScheduleBlocked"
                                >ZABLOKOWANY</span>
                              </p>

                              <!-- Employee header -->
                              <p
                                class="is-size-7 has-text-weight-semibold"
                                style="margin-bottom: 0.3rem;height: 2.7rem;"
                                v-else-if="day.subjectId > 0"
                              >{{getWorkerNameOnly(day.subjectId)}}</p>
                            </div>

                            <!-- Office header FIXED -->
                            <!-- <div class="card pl-1 pt-2 pr-1 fixed-header" :style="calcTopPoz(day.id)">
                        {{scrollTop}}
                        <p
                          class="is-size-7 has-text-weight-semibold"
                          style="margin-bottom: 0.2rem;height: 2.7rem; line-height: 0.9rem;"
                          v-if="day.office"
                        >
                          <span
                            v-if="day.office && day.office.facilityInfo"
                            class="has-text-weight-bold has-text-secondary is-size-7 has-text-right"
                          >
                            <b-tooltip
                              position="is-bottom"
                              multilined
                              :label="day.office.facilityInfo.name"
                            >[{{day.office.facilityInfo.shortName}}]</b-tooltip>
                          </span>
                          {{day.office.nameBase}}
                        </p>
                        <p
                          class="is-size-7 has-text-weight-semibold"
                          style="margin-bottom: 0.3rem;height: 2.7rem;"
                          v-else-if="day.subjectId > 0"
                        >{{getWorkerNameOnly(day.subjectId)}}</p>

                        <p>
                          <span class="has-text-weight-bold">{{ mDateNoYear(day.date) }}</span>
                          <span
                            class="ml-1 has-text-weight-semibold"
                            :class="{'has-text-secondary' : !selectedDays.includes(day.id)}"
                          >{{ getDayVerb(day.date) }}</span>
                        </p>
                            </div>-->
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td
                          colspan="2"
                          class="pr-2"
                        >
                          <div :class="{'pr-1': showMyTasks}">
                            <!-- Buttons for editor view only -->
                            <b-field
                              v-if="editorMode"
                              class="mb-0"
                            >
                              <p class="control is-expanded">
                                <b-checkbox-button
                                  size="is-small"
                                  :rounded="false"
                                  type="is-secondary"
                                  v-model="selectedDays"
                                  style="z-index: 12;"
                                  expanded
                                  @input="clearFirstDay"
                                  :disabled="isOfficeDayDisabled(day)"
                                  :native-value="day.id"
                                >
                                  <b-tooltip
                                    type="is-secondary"
                                    label="Uwzględnij ten dzień przy określaniu stref"
                                  >
                                    <span
                                      class="has-text-weight-semibold"
                                      v-if="selectedDays.includes(day.id)"
                                    >Wyłącz</span>
                                    <span
                                      class="has-text-weight-semibold"
                                      v-else
                                    >Aktywuj</span>
                                    <span class="pl-3 has-text-weight-bold">{{ mDateNoYear(day.date) }}</span>
                                    <span
                                      class="ml-1 has-text-weight-semibold"
                                      :class="{'has-text-secondary' : !selectedDays.includes(day.id)}"
                                    >{{ getDayVerb(day.date) }}</span>
                                  </b-tooltip>
                                </b-checkbox-button>
                              </p>
                            </b-field>
                            <!-- Buttons for non normal view only -->
                            <b-field v-else>
                              <p class="control is-expanded">
                                <b-button
                                  expanded
                                  size="is-small"
                                >
                                  <span class="has-text-weight-bold">{{ mDateNoYear(day.date) }}</span>
                                  <span class="ml-1 has-text-secondary has-text-weight-semibold">{{ getDayVerb(day.date) }}</span>
                                </b-button>
                              </p>
                              <p class="control">
                                <b-tooltip
                                  type="is-secondary"
                                  label="Wizyty bez godziny"
                                >
                                  <b-button
                                    size="is-small"
                                    expanded
                                    style="z-index: 12;"
                                    icon-left="clock-alert-outline"
                                    :type="{ 'is-secondary': day.timeless.length > 0 }"
                                    @click="showTimeless(day)"
                                  >
                                    <span
                                      class="pl-1"
                                      :class="{'has-text-weight-semibold':day.timeless.length > 0}"
                                    >{{day.timeless.length}}</span>
                                  </b-button>
                                </b-tooltip>
                              </p>
                              <p class="control">
                                <b-button
                                  v-if="editorMode"
                                  :disabled="day.worktimes.length < 1"
                                  size="is-small"
                                  style="z-index: 12;"
                                  icon-left="sort-calendar-ascending"
                                  @click="triggerMultiAreaChange(day)"
                                ></b-button>
                                <b-button
                                  v-else
                                  size="is-small"
                                  style="z-index: 12;"
                                  icon-left="printer"
                                  @click="printPatients(day)"
                                ></b-button>
                              </p>
                            </b-field>
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td
                          colspan="2"
                          class="is-size-7 pr-2"
                        >
                          <table
                            class="w-100"
                            style="line-height:0.95rem;margin: 0.15rem 0 0.25rem 0"
                            v-if="day.stats"
                          >
                            <tr>
                              <td style="width: 6.3rem;">
                                <b-field>
                                  <b-tooltip
                                    multilined
                                    position="is-right"
                                    :delay="400"
                                    label="Ilość wizyt (w nawiasie ilość wizyt bez godziny)"
                                  >
                                    <b-button
                                      size="is-small"
                                      type="is-text"
                                      icon-left="calendar"
                                      class="p-2"
                                      style="text-decoration: none !important;"
                                    >
                                      <b>{{ day.stats.appointments }}</b>
                                      (+{{ day.stats.timeless }})
                                    </b-button>
                                  </b-tooltip>

                                  <b-tooltip
                                    label="Ilość pacjentów"
                                    position="is-bottom"
                                    :delay="400"
                                  >
                                    <b-button
                                      type="is-text"
                                      size="is-small"
                                      icon-left="account"
                                      class="p-2"
                                      style="text-decoration: none !important;"
                                    >{{ day.stats.patients }}</b-button>
                                  </b-tooltip>

                                  <p class="control is-expanded"></p>

                                  <b-tooltip
                                    label="Pozostałe wolne minuty w ciągu dnia"
                                    position="is-bottom"
                                    :delay="400"
                                  >
                                    <b-button
                                      type="is-text"
                                      size="is-small"
                                      icon-left="clock-fast"
                                      class="p-2"
                                      style="text-decoration: none !important;"
                                    >{{ day.stats.timeLeft }}</b-button>
                                  </b-tooltip>

                                  <b-tooltip
                                    label="Ilość zajętych minut w ciuągu dnia"
                                    position="is-bottom"
                                    :delay="400"
                                  >
                                    <b-button
                                      type="is-text"
                                      size="is-small"
                                      icon-left="timer-off"
                                      class="p-2"
                                      style="text-decoration: none !important;"
                                    >{{ day.stats.timeTaken }}</b-button>
                                  </b-tooltip>
                                </b-field>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>

                      <tr>
                        <td
                          style="border-top: 2px solid rgba(125, 125, 125, 0.35);"
                          :colspan="showMyTasks ? 1 : 2"
                          class="pr-2"
                        >
                          <div
                            :style="day.scheduleColumnStyle"
                            class="schedule2-day-box"
                          >
                            <!--                 -->
                            <!-- TILES CONTAINER -->
                            <!--                 -->
                            <div style="z-index: 10;">
                              <div style="position: relative;align-self: auto;">
                                <div
                                  class="timeline"
                                  v-for="item in timelinesCount"
                                  :key="item"
                                  :style="getLineStyle2(item - 1)"
                                ></div>

                                <!--               -->
                                <!-- Mockup blocks -->
                                <!--               -->
                                <div
                                  v-for="block in day.mockupBlocks"
                                  :style="block.style"
                                  :key="block.index"
                                  @contextmenu="showContextMenu($event, day, block)"
                                >
                                  <!--                          -->
                                  <!-- Mockups without editor mode -->
                                  <!--                          -->
                                  <div
                                    v-if="block.worktimeArea && !editorMode"
                                    class="hand has-text-right"
                                  >
                                    <!-- Button for adding timeless appointment -->
                                    <div
                                      v-if="!predefinedDayMode"
                                      @click="addTimeless(day, block)"
                                    >
                                      <b-tooltip
                                        label="Wizyta bez godziny (CITO)"
                                        position="is-left"
                                        type="is-danger"
                                        v-if="block.worktimeArea.isCito"
                                      >
                                        <div style="padding-top:2px;">
                                          <b-icon
                                            icon="calendar-plus"
                                            type="is-danger"
                                          ></b-icon>
                                        </div>
                                      </b-tooltip>
                                      <b-tooltip
                                        v-else
                                        label="Dodaj wizytę bez godziny"
                                        position="is-left"
                                        type="is-dark"
                                      >
                                        <div style="padding-top:2px;">
                                          <b-icon
                                            icon="calendar-plus"
                                            :style="`color:${block.textColor} !important;`"
                                          ></b-icon>
                                        </div>
                                      </b-tooltip>
                                    </div>

                                    <!-- Mockup tile label -->
                                    <div
                                      v-if="!editorMode"
                                      @click="showAreaDetailsMockup(day, block)"
                                      class="rightrotate"
                                      :class="{'has-text-weight-semibold': block.worktimeArea.info}"
                                    >{{block.worktimeArea.info ? block.worktimeArea.info : 'Info'}}</div>
                                  </div>

                                  <!--                         -->
                                  <!-- Mocups with normal mode -->
                                  <!--                         -->
                                  <div v-else-if="block.worktimeArea && editorMode">
                                    <table class="w-100">
                                      <tr>
                                        <!-- Mockup labels -->
                                        <td>
                                          <p>{{block.name}}</p>
                                          <p>{{mTime(block.start)}} - {{mTime(block.end)}}</p>
                                          <p v-if="block.worktimeArea">{{getWorkerNameOnly(block.worktimeArea.employee)}}</p>
                                          <p v-if="block.worktimeArea.device">{{block.worktimeArea.device}}}}</p>
                                          <p>{{block.worktimeArea.info}}</p>
                                        </td>

                                        <!-- Area edit button -->
                                        <td
                                          style="width:1rem;"
                                          class="hand"
                                        >
                                          <b-tooltip
                                            label="Edycja strefy"
                                            position="is-left"
                                            type="is-dark"
                                          >
                                            <div
                                              @click="editWorktimeArea(block, day)"
                                              class="hand"
                                              style="margin-top: 2px;"
                                            >
                                              <b-icon
                                                v-if="block.worktimeArea.isCito"
                                                icon="calendar-edit"
                                                type="is-danger"
                                              ></b-icon>
                                              <b-icon
                                                v-else
                                                icon="calendar-edit"
                                                :style="`color:${block.textColor} !important;`"
                                              ></b-icon>
                                            </div>
                                          </b-tooltip>
                                        </td>
                                      </tr>
                                    </table>
                                  </div>
                                </div>

                                <!--                                      -->
                                <!-- Regular tile blocks with actual data -->
                                <!--                                      -->
                                <div
                                  v-for="block in day.blocks"
                                  :style="block.style"
                                  :key="block.index"
                                  @click="showDetailsOrNewAppointmentModal(day, block)"
                                  @contextmenu="showContextMenu($event, day, block)"
                                  @dragstart="dragStarted"
                                  @dragend="dragDropped"
                                  @dragover="onDragOver"
                                  :draggable="block.appointment != null"
                                  :class="{'hoverable-block':!block.isEmpty, 'currentlyAdded': block.isNew, 'handpointer': autoNavigateTarget && block.appointment && block.appointment.id == autoNavigateTarget.id}"
                                >
                                  <table
                                    class="w-100"
                                    style="height:100%;"
                                  >
                                    <tr>
                                      <!-- Area checkbox -->
                                      <td
                                        v-if="editorMode && block.worktimeArea && block.isWorktime"
                                        style="width:8px;"
                                      >
                                        <b-checkbox
                                          v-model="areasSelected"
                                          :native-value="block.worktimeArea"
                                          :disabled="day.office && day.office.isScheduleBlocked"
                                          type="is-danger"
                                          class="m-0 is-size-6"
                                          style="width:1.45rem;padding:0.1rem;pointer-events: none;"
                                        ></b-checkbox>
                                      </td>

                                      <!-- Urgent appointments flag -->
                                      <td
                                        v-if="block.appointment && block.appointment.isUrgent"
                                        style="width:8px;"
                                      >
                                        <b-tooltip
                                          type="is-light"
                                          position="is-right"
                                          class="ind-urgent"
                                          style="width: 100%; height: 97%"
                                          label="Pilne!"
                                        >
                                          <b-icon
                                            size="is-small"
                                            icon="exclamation-thick"
                                            type="is-light"
                                            style="margin:-3px -1px 0 -3px;"
                                          ></b-icon>
                                        </b-tooltip>
                                      </td>

                                      <!-- E-Registration -->
                                      <td
                                        v-if="block.appointment && block.appointment.isEr"
                                        style="width:8px;"
                                        :class="{'erErrorAnimation': block.appointment.erState == 4}"
                                      >
                                        <b-tooltip
                                          v-if="block.appointment.erState == 4"
                                          type="is-danger"
                                          position="is-right"
                                          class="has-background-danger"
                                          style="width: 100%; height: 97%"
                                          label="E-Rejestracja: BŁĄD!"
                                        >
                                          <b style="color:white;">E</b>
                                        </b-tooltip>
                                        <b-tooltip
                                          v-else-if="block.appointment.erState == 3"
                                          type="is-success"
                                          position="is-right"
                                          class="has-background-success"
                                          style="width: 100%; height: 97%"
                                          label="E-Rejestracja: Potwierdzona"
                                        >
                                          <b style="color:white;">E</b>
                                        </b-tooltip>
                                        <b-tooltip
                                          v-else-if="block.appointment.erState == 1"
                                          type="is-info"
                                          position="is-right"
                                          class="has-background-info"
                                          style="width: 100%; height: 97%"
                                          label="E-Rejestracja: Przetwarzanie"
                                        >
                                          <b style="color:white;">E</b>
                                        </b-tooltip>
                                        <b-tooltip
                                          v-else-if="block.appointment.erState == 6"
                                          type="is-secondary"
                                          position="is-right"
                                          class="has-background-secondary"
                                          style="width: 100%; height: 97%"
                                          label="E-Rejestracja: Zakończono"
                                        >
                                          <b style="color:white;">E</b>
                                        </b-tooltip>
                                        <b-tooltip
                                          v-else
                                          type="is-light"
                                          position="is-right"
                                          class="has-background-info"
                                          style="width: 100%; height: 97%"
                                          label="E-Rejestracja"
                                        >
                                          <b style="color:white;">E</b>
                                        </b-tooltip>
                                      </td>

                                      <!-- AP-KOLCE -->
                                      <td
                                        v-if="block.appointment && block.appointment.isApkolce"
                                        style="width:8px;"
                                      >
                                        <b-tooltip
                                          v-if="block.appointment.erState == 4"
                                          type="is-info"
                                          position="is-right"
                                          class="has-background-info"
                                          style="width: 100%; height: 97%"
                                          label="AP-KOLCE: Informacja"
                                        >
                                          <b style="color:white;">A</b>
                                        </b-tooltip>
                                        <b-tooltip
                                          v-else-if="block.appointment.erState == 3"
                                          type="is-success"
                                          position="is-right"
                                          class="has-background-success"
                                          style="width: 100%; height: 97%"
                                          label="AP-KOLCE: Potwierdzona"
                                        >
                                          <b style="color:white;">A</b>
                                        </b-tooltip>
                                        <b-tooltip
                                          v-else-if="block.appointment.erState == 1"
                                          type="is-info"
                                          position="is-right"
                                          class="has-background-info"
                                          style="width: 100%; height: 97%"
                                          label="AP-KOLCE: Przetwarzanie"
                                        >
                                          <b style="color:white;">A</b>
                                        </b-tooltip>
                                        <b-tooltip
                                          v-else-if="block.appointment.erState == 6"
                                          type="is-secondary"
                                          position="is-right"
                                          class="has-background-secondary"
                                          style="width: 100%; height: 97%"
                                          label="AP-KOLCE: Zakończono"
                                        >
                                          <b style="color:white;">A</b>
                                        </b-tooltip>
                                        <b-tooltip
                                          v-else
                                          type="is-light"
                                          position="is-right"
                                          class="has-background-info"
                                          style="width: 100%; height: 97%"
                                          label="AP-KOLCE"
                                        >
                                          <b style="color:white;">A</b>
                                        </b-tooltip>
                                      </td>

                                      <td
                                        v-if="block.appointment && block.appointment.isEr && (block.appointment.erState == 0 || block.appointment.erState == 1 || block.appointment.erState == 3) && block.appointment.state == 8"
                                        style="width:8px;"
                                      >
                                        <b-tooltip
                                          type="is-light"
                                          position="is-right"
                                          class="ind-alert"
                                          style="width: 100%; height: 97%"
                                          label="Wymagane skreślenie"
                                        >
                                          <b style="color:white;">E</b>
                                        </b-tooltip>
                                      </td>

                                      <!-- Patient waiting flag -->
                                      <td
                                        v-if="block.appointment && block.appointment.patientWaiting"
                                        style="width:8px;"
                                      >
                                        <b-tooltip
                                          type="is-light"
                                          position="is-right"
                                          class="ind-present"
                                          style="width: 100%; height: 97%"
                                          label="Pacjent w poczekalni"
                                        >
                                          <b-icon
                                            size="is-small"
                                            icon="account"
                                            type="is-dark"
                                            style="margin:-3px -1px 0 -3px;"
                                          ></b-icon>
                                        </b-tooltip>
                                      </td>

                                      <td
                                        v-if="block.appointment && block.appointment.service == 1"
                                        style="width:8px;"
                                      >
                                        <b-tooltip
                                          type="is-light"
                                          position="is-right"
                                          class="ind-tele"
                                          style="width: 100%; height: 97%"
                                          label="Teleporada"
                                        >
                                          <b-icon
                                            size="is-small"
                                            icon="phone"
                                            type="is-light"
                                            style="margin:-3px -1px 0 -3px;"
                                          ></b-icon>
                                        </b-tooltip>
                                      </td>

                                      <td
                                        v-if="block.appointment && block.appointment.service == 2"
                                        style="width:8px;"
                                      >
                                        <b-tooltip
                                          type="is-light"
                                          position="is-right"
                                          class="ind-home"
                                          style="width: 100%; height: 97%"
                                          label="Wizyta domowa"
                                        >
                                          <b-icon
                                            size="is-small"
                                            icon="car"
                                            type="is-light"
                                            style="margin:-3px -1px 0 -3px;"
                                          ></b-icon>
                                        </b-tooltip>
                                      </td>

                                      <!-- Reschedule for timeout/reservation available flag -->
                                      <td
                                        v-if="block.appointment && !block.appointment.isEr && block.isHistory && (block.appointment.state == 8 || block.appointment.state == 100)"
                                        style="width:8px;"
                                      >
                                        <b-tooltip
                                          type="is-light"
                                          position="is-right"
                                          class="ind-reschedule"
                                          style="width: 100%; height: 97%"
                                          label="Możliwe przełożenie"
                                        >
                                          <b-icon
                                            size="is-small"
                                            icon="refresh"
                                            type="is-dark"
                                            style="margin:-3px -2px 0 -2px;"
                                          ></b-icon>
                                        </b-tooltip>
                                      </td>

                                      <!-- Reschedule for timeout/reservation available flag -->
                                      <td
                                        v-if="block.appointment && block.appointment.state == 1"
                                        style="width:8px;"
                                      >
                                        <b-tooltip
                                          type="is-light"
                                          position="is-right"
                                          class="ind-ongoing"
                                          style="width: 100%; height: 97%"
                                          label="Wizyta w trakcie"
                                        >
                                          <b-icon
                                            size="is-small"
                                            icon="clock"
                                            type="is-dark"
                                            style="margin:-3px -1px 0 -3px;"
                                          ></b-icon>
                                        </b-tooltip>
                                      </td>

                                      <!-- Reservation flag -->
                                      <td
                                        v-if="block.appointment && block.appointment.state == 100"
                                        style="width:8px;"
                                      >
                                        <b-tooltip
                                          type="is-light"
                                          position="is-right"
                                          class="ind-reservation"
                                          style="width: 100%; height: 97%"
                                          label="Rezerwacja wstępna"
                                        >
                                          <b-icon
                                            size="is-small"
                                            icon="calendar"
                                            type="is-dark"
                                            style="margin:-3px -2px 0 -2px;"
                                          ></b-icon>
                                        </b-tooltip>
                                      </td>

                                      <!-- Reservation flag -->
                                      <td
                                        v-if="block.appointment && block.appointment.clinic == 10 && block.appointment.patient.deklL <= 0"
                                        style="width:8px;"
                                      >
                                        <b-tooltip
                                          type="is-light"
                                          position="is-right"
                                          class="ind-nodekl"
                                          style="width: 100%; height: 97%"
                                          label="Brak deklaracji"
                                        >
                                          <b-icon
                                            size="is-small"
                                            icon="clipboard-account"
                                            type="is-danger"
                                            style="margin:-3px -2px 0 -2px;"
                                          ></b-icon>
                                        </b-tooltip>
                                      </td>

                                      <!-- Appointment icon -->
                                      <td
                                        v-if="block.appointment"
                                        :style="`width: 8px;overflow:hidden;${block.maxInsideHeightStyle};`"
                                      >
                                        <b-icon
                                          size="is-small"
                                          :icon="block.icon ? block.icon : 'calendar'"
                                          style="margin: -2px -1px 0px -1px;"
                                        ></b-icon>
                                      </td>

                                      <!-- Appointment labels -->
                                      <td>
                                        <div :style="`max-height: ${block.maxInsideHeightStyle};overflow:hidden;padding-left:1px;`">
                                          <span class="has-text-weight-semibold">
                                            <!-- Block dispaly name -->
                                            <p>
                                              <b-tooltip
                                                type="is-primary"
                                                label="E-Rejestracja"
                                                position="is-right"
                                                v-show="block.isWorktime && block.worktimeArea && block.worktimeArea.isEr"
                                              >
                                                <b
                                                  style="border-radius: 5px;margin:1px0 1px 0;"
                                                  class="pl-1 pr-1 has-background-primary has-text-white"
                                                >E-REJ</b>
                                              </b-tooltip>
                                              <span v-html="block.name"></span>
                                            </p>

                                            <!-- Employee if tile is worktime area -->
                                            <p
                                              style="opacity: 0.8;"
                                              v-if="block.isWorktime"
                                            >{{getWorkerNameOnly(block.worktimeArea.employee)}}</p>
                                          </span>

                                          <!-- Appointment type if available and option selected -->
                                          <span
                                            style="opacity:0.8;"
                                            class="has-text-weight-normal"
                                            v-if="block.appointment && showAppointmentTypeLabelOnBlocks && block.typeName"
                                          >{{`${ block.typeName}`}}</span>
                                        </div>
                                      </td>

                                      <!-- Stat icons -->
                                      <td
                                        style="width:1px;"
                                        v-if="block.appointment && block.appointment.sms"
                                      >
                                        <b-icon
                                          icon="message-reply-text"
                                          style="margin: -2px -1px 0px -1px;"
                                          size="is-small"
                                        ></b-icon>
                                      </td>
                                      <td
                                        style="width:1px;"
                                        v-if="block.appointment && block.appointment.sEwus"
                                      >
                                        <b-icon
                                          v-if="block.appointment.sEwus.ewusStatus == 1 || block.appointment.sEwus.ewusStatus == 8"
                                          icon="shield-check"
                                          style="margin: -2px -1px 0px -1px;"
                                          size="is-small"
                                        ></b-icon>
                                        <b-icon
                                          v-else-if="block.appointment.sEwus.ewusStatus == 2 || block.appointment.sEwus.ewusStatus == 9 || block.appointment.sEwus.ewusStatus == 10 || block.appointment.sEwus.ewusStatus == 11"
                                          icon="shield-alert"
                                          style="margin: -2px -1px 0px -1px;"
                                          size="is-small"
                                        ></b-icon>
                                        <b-icon
                                          v-else
                                          icon="shield-off-outline"
                                          style="margin: -2px -1px 0px -1px;"
                                          size="is-small"
                                        ></b-icon>
                                      </td>
                                      <!-- Payer type icons -->
                                      <td style="width:1px;padding-right:3px;">
                                        <b-icon
                                          v-if="block.appointment && block.appointment.payerType == 0"
                                          icon="cash-multiple"
                                          style="margin: -2px -1px 0px -1px;"
                                          size="is-small"
                                        ></b-icon>
                                        <b-icon
                                          v-else-if="block.appointment && block.appointment.payerType == 1"
                                          icon="heart-half-full"
                                          style="margin: -2px -1px 0px -1px;"
                                          size="is-small"
                                        ></b-icon>
                                        <b-icon
                                          v-else-if="block.appointment && block.appointment.payerType == 2"
                                          icon="home-city"
                                          style="margin: -2px -1px 0px -1px;"
                                          size="is-small"
                                        ></b-icon>
                                      </td>
                                      <td
                                        v-if="!block.isEmpty"
                                        style="width:1px;padding-right:1px;"
                                      >{{mTime(block.start)}}</td>
                                    </tr>
                                  </table>
                                </div>

                                <!--               -->
                                <!-- ERSLOT blocks -->
                                <!--               -->
                                <div
                                  v-for="block in day.erSlots"
                                  :style="block.style"
                                  :key="block.index"
                                  class="hoverable-slot"
                                  @click="switchSlotState(block)"
                                >
                                  <p
                                    v-if="block.error == 6"
                                    style="position:absolute;top:0;right:1px;opacity:0.7;"
                                  >
                                    {{mTime(block.start)}}<br /><span
                                      style="font-size: 0.65rem;"
                                      class="has-text-weight-bold"
                                    >BŁĄD DODAWANIA SLOTU</span>
                                  </p>
                                  <p
                                    v-else-if="block.error == 5"
                                    style="position:absolute;top:0;right:1px;opacity:0.7;"
                                  >
                                    {{mTime(block.start)}}<br /><span style="font-size: 0.65rem;">SLOT NIEPOTWIERDZONY</span>
                                  </p>
                                  <p
                                    v-else-if="block.error < 2"
                                    style="position:absolute;top:0;right:1px;opacity:0.7;"
                                  >
                                    {{mTime(block.start)}}<br /><span style="font-size: 0.65rem;">wolny slot eRej</span>
                                  </p>
                                  <p
                                    v-else-if="block.error == 2 && editorSlotsMode"
                                    style="position:absolute;top:0;right:1px;opacity:0.7;"
                                  >
                                    {{mTime(block.start)}}<br /><span
                                      style="font-size: 0.65rem;"
                                      class="has-text-weight-bold"
                                    >ZABLOKOWANY SLOT</span>
                                  </p>
                                  <p
                                    v-else-if="block.error == 3 && editorSlotsMode"
                                    style="position:absolute;top:0;right:1px;opacity:0.7;"
                                  >
                                    {{mTime(block.start)}}<br /><span
                                      style="font-size: 0.65rem;"
                                      class="has-text-weight-bold"
                                    >REZERWACJA SLOTU</span>
                                  </p>
                                </div>

                                <!--                            -->
                                <!-- Slot tiles for editor mode -->
                                <!--                            -->
                                <div
                                  v-show="editorMode && selectedDays.includes(day.id)"
                                  v-for="block in day.slots"
                                  :style="block.style"
                                  class="slot-box"
                                  :key="block.id"
                                  @mousedown="onMouseDown($event, block, day.id, day.slots)"
                                  @mousemove="onMouseEnter($event, block, day.slots)"
                                  @mouseleave="onMouseLeave"
                                  @mouseup="onMouseUp(block)"
                                >
                                  <p>{{mTime(block.start)}} - {{mTime(block.end)}}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>

                        <!--              -->
                        <!-- Tasks column -->
                        <!--              -->
                        <td
                          v-show="showMyTasks && !predefinedDay"
                          class="pr-2"
                          :style="day.taskColumnStyle"
                        >
                          <div class="has-background-white pb-1">
                            <b-button
                              class="mt-1"
                              size="is-small"
                              expanded
                              icon-left="calendar"
                              :type="{ 'is-secondary': day.tasks && day.tasks.length > 0 }"
                              @click="showTasksList(day)"
                            >
                              <b>{{day.tasks ? day.tasks.length : 0}} zadań</b>
                            </b-button>
                          </div>
                          <div style="z-index: 10;">
                            <div style="position: relative;align-self: auto;">
                              <!--            -->
                              <!-- Task tiles -->
                              <!--            -->
                              <div
                                v-for="block in day.tasks"
                                :style="block.style"
                                :key="block.index"
                                @click="showTaskModal(day, block)"
                              >
                                <table
                                  class="w-100"
                                  style="height:100%;min-height:100%;"
                                >
                                  <tr>
                                    <td v-if="block.task">{{block.task.content}}</td>
                                  </tr>
                                </table>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </table>

      <!--              -->
      <!-- ICONS LEGEND -->
      <!--              -->
      <table class="mt-3">
        <tr>
          <td style="width: 10px;background: red;">
            <b-icon
              size="is-small"
              icon="exclamation-thick"
              type="is-light"
              style="margin:-3px 0 0 0;"
            ></b-icon>
          </td>
          <td class="pl-2">Wizyta pilna</td>
          <td style="width: 10px;background: orange;">
            <b-icon
              size="is-small"
              icon="account"
              style="margin:-3px 0 0 0;"
            ></b-icon>
          </td>
          <td class="pl-2">Pacjent w poczekalni</td>
        </tr>
        <tr>
          <td style="width: 10px;background: mediumpurple;">
            <b-icon
              size="is-small"
              icon="calendar"
              style="margin:-3px 0 0 0;"
            ></b-icon>
          </td>
          <td class="pl-2">Rezerwacja</td>
          <td style="width: 10px;background: greenyellow;">
            <b-icon
              size="is-small"
              icon="refresh"
              type="is-dark"
              style="margin:-3px 0 0 0;"
            ></b-icon>
          </td>
          <td class="pl-2">Możliwe przełożenie</td>
        </tr>
        <tr>
          <td style="width: 10px;background: DarkTurquoise;">
            <b-icon
              size="is-small"
              icon="clock-alert"
              style="margin:-3px 0 0 0;"
            ></b-icon>
          </td>
          <td class="pl-2 pr-5">Wizyta w trakcie</td>
          <td style="width: 10px;background: rgb(255, 213, 0);">
            <b-icon
              size="is-small"
              icon="clipboard-account"
              type="is-danger"
              style="margin:-3px 0 0 0;"
            ></b-icon>
          </td>
          <td class="pl-2">Brak deklaracji</td>
        </tr>
        <tr>
          <td style="width: 10px;background: #17A589;">
            <b-icon
              size="is-small"
              icon="phone"
              type="is-light"
              style="margin:-3px 0 0 0;"
            ></b-icon>
          </td>
          <td class="pl-2 pr-5">Teleporada</td>
          <td style="width: 10px;background: #884EA0;">
            <b-icon
              size="is-small"
              icon="car"
              type="is-light"
              style="margin:-3px 0 0 0;"
            ></b-icon>
          </td>
          <td class="pl-2">Wizyta domowa</td>
        </tr>
        <tr>
          <td class="p-1"></td>
          <td></td>
        </tr>
        <tr>
          <td
            style="width: 10px;text-align: center;"
            class="has-background-success"
          >
            <b style="color:white;">E</b>
          </td>
          <td class="pl-2 pr-3">E-Rejestracja: Potwierdzona</td>
          <td style="width: 10px;background:maroon;text-align: center;">
            <b style="color:white;">E</b>
          </td>
          <td class="pl-2">E-Rejestracja: Wymagane skreślenie</td>
        </tr>
        <tr>
          <td
            style="width: 10px;text-align: center;"
            class="has-background-danger"
          >
            <b style="color:white;">E</b>
          </td>
          <td class="pl-2">E-Rejestracja: Błąd</td>
          <td
            style="width: 10px;text-align: center;"
            class="has-background-info"
          >
            <b style="color:white;">E</b>
          </td>
          <td class="pl-2">E-Rejestracja: Oczekujący</td>
        </tr>
        <tr>
          <td class="p-1"></td>
          <td></td>
        </tr>
        <tr>
          <td
            style="width: 10px;text-align: center;"
            class="has-background-info"
          >
            <b style="color:white;">A</b>
          </td>
          <td class="pl-2 pr-3">AP-KOLCE</td>
          <td style="width: 10px;text-align: center;">
          </td>
          <td class="pl-2"></td>
        </tr>
      </table>
    </div>

    <!--                   -->
    <!-- INFORMATION MODAL -->
    <!--                   -->
    <b-modal
      :active.sync="infoModalActive"
      has-modal-card
      scroll="keep"
      :destroy-on-hide="false"
    >
      <ContentPanel
        title="Informacje"
        icon="clock"
        :paddingless="true"
        :marginless="true"
      >
        <div
          class="modal-card"
          style="width:100% !important;"
        >
          <section class="modal-card-body pt-3">
            <table class="table">
              <tr>
                <th colspan="2">Ikony płatnika</th>
              </tr>
              <tr>
                <td style="width:1rem;">
                  <b-icon icon="cash-multiple"></b-icon>
                </td>
                <td>Komercja</td>
              </tr>
              <tr>
                <td>
                  <b-icon icon="heart-half-full"></b-icon>
                </td>
                <td>NFZ</td>
              </tr>
              <tr>
                <td>
                  <b-icon icon="home-city"></b-icon>
                </td>
                <td>MZ</td>
              </tr>
              <tr>
                <th
                  colspan="2"
                  class="pt-5"
                >Ikony EWUŚ</th>
              </tr>
              <tr>
                <td>
                  <b-icon icon="shield-check"></b-icon>
                </td>
                <td>Ubezpieczony</td>
              </tr>
              <tr>
                <td>
                  <b-icon icon="shield-alert"></b-icon>
                </td>
                <td>Brak ubezpieczenia</td>
              </tr>
              <tr>
                <td>
                  <b-icon icon="shield-off"></b-icon>
                </td>
                <td>Inny status</td>
              </tr>
              <tr>
                <td>brak</td>
                <td>Brak informacji EWUŚ</td>
              </tr>
              <tr>
                <th
                  colspan="2"
                  class="pt-5"
                >Inne</th>
              </tr>
              <tr>
                <td>
                  <b-icon icon="message-reply-text"></b-icon>
                </td>
                <td>Wizyta posiada skojarzone wiadomości SMS</td>
              </tr>
            </table>
          </section>
          <footer class="modal-card-foot">
            <b-button
              icon-left="close"
              size="is-small"
              @click="infoModalActive = false"
            >Zamknij</b-button>
          </footer>
        </div>
      </ContentPanel>
    </b-modal>

    <!--                  -->
    <!-- TASKS LIST MODAL -->
    <!--                  -->
    <b-modal
      :active.sync="tasksModalVisible"
      has-modal-card
      class="chat-pad chatsize"
      scroll="keep"
      :destroy-on-hide="false"
    >
      <ContentPanel
        v-if="tasksModalVisible && focusDay && focusDay.tasks"
        :title="`Zadania na ${mDate(focusDay.date)}`"
        icon="clock"
        :paddingless="true"
        :marginless="true"
      >
        <template slot="addon">
          <WorkerTaskInfo
            :startDate="focusDay.dateObject"
            :finishDate="focusDay.dateObject"
            :workerId="me.id"
            class="mr-1 is-inline"
          ></WorkerTaskInfo>
          <b-button
            @click="tasksModalVisible = false"
            icon-left="close"
            size="is-small"
          >Zamknij</b-button>
        </template>

        <div
          class="modal-card"
          style="width:100% !important;"
        >
          <section class="modal-card-body">
            <b-table
              :data="focusDay.tasks"
              narrowed
              hoverable
              striped
              bordered
            >
              <b-table-column
                label="ID"
                v-slot="props"
              >{{props.row.task.internalId}}</b-table-column>
              <b-table-column
                label="Nazwa"
                v-slot="props"
              >{{props.row.task.content}}</b-table-column>
              <b-table-column
                label="Status"
                v-slot="props"
              >{{props.row.task.status}}</b-table-column>
              <b-table-column
                label="Start"
                v-slot="props"
              >{{mDateTime(props.row.task.startDate)}}</b-table-column>
              <b-table-column
                label="Przewidywany koniec"
                v-slot="props"
              >{{mDateTime(props.row.task.finishDate)}}</b-table-column>
              <b-table-column
                label="Ukończono"
                v-slot="props"
              >{{mDateTime(props.row.task.endDate)}}</b-table-column>
              <b-table-column
                label="Deadline"
                v-slot="props"
              >{{mDateTime(props.row.task.deadlineDate)}}</b-table-column>
              <b-table-column
                label="Role"
                v-slot="props"
              >
                <p
                  v-for="role in props.row.task.roles"
                  :key="role.index"
                >{{getWorkerNameOnly(role.workerId)}}, {{role.role}}, {{role.status}}</p>
              </b-table-column>
              <b-table-column
                label="Szczegóły"
                v-slot="props"
              >
                <b-button
                  @click="showTaskModal(focusDay, props.row)"
                  size="is-small"
                  icon-right="menu-right"
                  type="is-primary"
                  expanded
                >Więcej</b-button>
              </b-table-column>
              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <p>
                      <b-icon
                        icon="timer-off-outline"
                        size="is-large"
                      ></b-icon>
                    </p>
                    <p>Brak</p>
                  </div>
                </section>
              </template>
            </b-table>
          </section>
          <footer class="modal-card-foot">
            <b-button
              icon-left="close"
              size="is-small"
              @click="tasksModalVisible = false"
            >Zamknij</b-button>
          </footer>
        </div>
      </ContentPanel>
    </b-modal>

    <!--                     -->
    <!-- TIMELESS APPS MODAL -->
    <!--                     -->
    <b-modal
      :active.sync="timelessModalVisible"
      has-modal-card
      class="chat-pad chatsize"
      scroll="keep"
      :destroy-on-hide="false"
    >
      <ContentPanel
        v-if="timelessModalVisible && focusDay && focusDay.timeless"
        title="Wizyty bez godziny"
        icon="clock"
        :paddingless="true"
        :marginless="true"
      >
        <template slot="addon">
          <b-button
            @click="timelessModalVisible = false"
            icon-left="close"
            size="is-small"
          >Zamknij</b-button>
        </template>

        <div
          class="modal-card"
          style="width:100% !important;"
        >
          <section class="modal-card-body">
            <b-table
              :data="focusDay.timeless"
              narrowed
              hoverable
              striped
              bordered
            >
              <b-table-column
                label="Godzina orientacyjna"
                centered
                cell-class="hand"
                width="1rem"
              >
                <template slot="header">
                  <div class="pl-3 pr-3">
                    <b-tooltip
                      label="Godzina orientacyjna"
                      position="is-right"
                    >
                      <b-icon
                        class="m-0"
                        icon="clock"
                      ></b-icon>
                    </b-tooltip>
                  </div>
                </template>
                <template
                  v-slot="props"
                  width="1rem"
                >{{ mTime(props.row.start) }}</template>
              </b-table-column>
              <b-table-column
                v-slot="props"
                label="Pacjent"
                cell-class="hand"
              >{{mGetAppointmentStateText(props.row.state)}}</b-table-column>
              <b-table-column
                v-slot="props"
                label="Pacjent"
                cell-class="hand"
              >
                <span v-if="props.row.patient">{{ props.row.patient.lastName }} {{ props.row.patient.firstName }}</span>
                <span v-else>Brak danych</span>
              </b-table-column>
              <b-table-column
                v-slot="props"
                label="Identyfikacja"
                cell-class="hand"
              >
                <span v-if="props.row.patient">
                  <span v-if="props.row.patient.pesel">{{props.row.patient.pesel}}</span>
                  <span v-else>
                    {{ props.row.patient.identityDocument === 0 && props.row.patient.identityDocumentNumber ? `Dowód: ${props.row.patient.identityDocumentNumber}` : ''}}
                    {{ props.row.patient.identityDocument === 1 && props.row.patient.identityDocumentNumber ? `Paszport: ${props.row.patient.identityDocumentNumber}` : ''}}
                    {{ props.row.patient.identityDocument > 1 && props.row.patient.identityDocumentNumber ? `Inny: ${props.row.patient.identityDocumentNumber}` : ''}}
                  </span>
                </span>
                <span v-else>Brak danych</span>
              </b-table-column>
              <b-table-column
                v-slot="props"
                label="Typ"
                field="typeName"
                cell-class="hand"
              >{{ props.row.typeName }}</b-table-column>
              <b-table-column
                label="Szczegóły"
                cell-class="hand"
                v-slot="props"
              >
                <b-button
                  @click="showTimelessApp(props.row)"
                  size="is-small"
                  icon-right="menu-right"
                  type="is-primary"
                  expanded
                >Więcej</b-button>
              </b-table-column>

              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <p>
                      <b-icon
                        icon="timer-off-outline"
                        size="is-large"
                      ></b-icon>
                    </p>
                    <p>Brak</p>
                  </div>
                </section>
              </template>
            </b-table>
          </section>
          <footer class="modal-card-foot">
            <b-button
              icon-left="close"
              size="is-small"
              @click="timelessModalVisible = false"
            >Zamknij</b-button>
          </footer>
        </div>
      </ContentPanel>
    </b-modal>

    <!--                   -->
    <!-- APP DETAILS MODAL -->
    <!--                   -->
    <b-modal
      :active.sync="appointmentDetailsModalVisible"
      has-modal-card
      class="chat-pad chatsize"
      scroll="keep"
      :destroy-on-hide="false"
    >
      <ContentPanel
        v-if="appointmentDetailsModalVisible && focusBlock && focusBlock.appointment && focusDay"
        :title="`Szczegóły ${focusBlock.appointment.state == 100 ? 'rezerwacji' : 'wizyty'} #${focusBlock.appointment.id}`"
        icon="calendar"
        :paddingless="true"
        :marginless="true"
        @close="appointmentDetailsModalVisible = false"
        class="chatsize"
      >
        <template slot="addon">
          <b-button
            v-if="focusBlock.appointment && focusBlock.appointment.isEr && (focusBlock.appointment.erState == 0 || focusBlock.appointment.erState == 1 || focusBlock.appointment.erState == 3) && focusBlock.appointment.state == 8"
            @click="endErAppModalVisible = true"
            icon-left="calendar-remove"
            size="is-small"
            :disabled="focusBlock.appointment.state != 8"
            type="is-primary"
            class="mr-1"
          >Skreśl wizytę w E-Rejestracji</b-button>
          <b-button
            v-else-if="focusBlock.appointment.isEr && focusBlock.appointment.erState == 8"
            icon-left="calendar-remove"
            size="is-small"
            disabled
            type="is-primary"
            class="mr-1"
          >Wizyta już skreślona w E-Rejestracji</b-button>
          <b-button
            v-if="focusBlock.appointment.isEr && (focusBlock.appointment.erState  != 6 ) && focusBlock.appointment.state == 5"
            @click="closeErAppointment"
            icon-left="calendar-check-outline"
            size="is-small"
            :disabled="focusBlock.appointment.state != 5"
            type="is-primary"
            class="mr-1"
          >Zakończ wizytę w E-Rejestracji</b-button>
          <b-button
            v-else-if="focusBlock.appointment.isEr && focusBlock.appointment.erState == 6"
            icon-left="calendar-check-outline"
            size="is-small"
            disabled
            type="is-primary"
            class="mr-1"
          >Wizyta zakończona w E-Rejestracji</b-button>
          <b-button
            v-if="focusBlock.appointment && notPersonal && focusBlock.appointment.state ==0 && 
            (focusBlock.appointment.clinic == 1450 || focusBlock.appointment.clinic == 7242) &&
            focusBlock.appointment.erState == 0"
            @click="sendToEregistration(false)"
            icon-left="account-clock"
            size="is-small"
            type="is-primary" :loading="slotsLoading"
            :disabled="readonly"
            class="mr-1"
          >Zgłoś tę wizytę do E-Rejestracji</b-button>
          <b-button
            v-if="focusBlock.appointment && notPersonal && focusBlock.appointment.state ==0 && 
            (focusBlock.appointment.clinic == 1450 || focusBlock.appointment.clinic == 7242) &&
            focusBlock.appointment.erState == 4"
            @click="sendToEregistration(true)"
            icon-left="account-clock"
            size="is-small"
            type="is-primary"
            :disabled="readonly" :loading="slotsLoading"
            class="mr-1"
          >Zgłoś ponownie do E-Rejestracji</b-button>
          <b-button
            v-if="focusBlock.appointment.patientWaiting && notPersonal && focusBlock.appointment.state != 100"
            @click="markPatientWaiting(false)"
            icon-left="account-clock"
            size="is-small"
            type="is-warning"
            :disabled="readonly"
            class="mr-1"
          >Anuluj oznaczenie pacjenta w poczekalni</b-button>
          <b-button
            v-else-if="notPersonal && focusBlock.appointment.state != 100"
            @click="markPatientWaiting(true)"
            icon-left="account-clock"
            size="is-small"
            type="is-secondary"
            :disabled="focusBlock.appointment.state > 0 || !isToday(focusBlock) || readonly"
            class="mr-1"
          >Oznacz pacjenta w poczekalni</b-button>
          <b-button
            v-if="perms.subadmin"
            @click="cancelAsSubadmin"
            icon-left="calendar-remove"
            size="is-small"
            type="is-orange"
            :disabled="focusBlock.appointment.state > 0 || readonly"
            class="mr-1"
          >Odwołaj w trybie subadmina</b-button>
          <b-button
            @click="appointmentDetailsModalVisible = false"
            icon-left="close"
            size="is-small"
          >Zamknij</b-button>
        </template>
        <Schedule2AppDetails
          :block="focusBlock"
          :readonly="readonly"
          :blocks="focusDay.blocks"
          @removed="hideModalsAndReload"
          @send-usg="hideModalsAndReload"
          @close="hideModals"
          @complete-reservation="showReservationCompletionModal"
          @edited="hideModalsAndReload"
          @show-patient-data="emitShowPatientData"
        ></Schedule2AppDetails>
      </ContentPanel>
    </b-modal>

    <!--                   -->
    <!-- APP CREATOR MODAL -->
    <!--                   -->
    <b-modal
      :active.sync="newAppointmentModalVisible"
      has-modal-card
      class="chat-pad chatsize"
      scroll="keep"
      :destroy-on-hide="false"
      :can-cancel="false"
    >
      <div v-if="newAppointmentModalVisible && focusBlock && focusDay && ((focusBlock.isWorktime && focusBlock.worktimeArea) || focusReservation)">
        <Schedule2NewApp
          :day="focusDay"
          :block="focusBlock"
          :date="focusDay.moment"
          :patient="focusPatient"
          :timeOnlyMode="timeOnlyMode"
          :timelessOnlyMode="timelessOnlyMode"
          :rescheduleMode="rescheduleMode"
          :focusReservation="focusReservation"
          @close="hideModals"
          @created="hideModalsAndReload"
          @created-many="hideModalsAndReloadMany"
          @time-selected="emitTime"
          @removed="hideModalsAndReload"
          @edited="hideModalsAndReload"
          @split="hideModalsAndReload"
        ></Schedule2NewApp>
      </div>
    </b-modal>

    <!--                    -->
    <!-- AREA DETAILS MODAL -->
    <!--                    -->
    <b-modal
      :active.sync="areaDetailsModalVisible"
      has-modal-card
      class="chat-pad chatsize"
      scroll="keep"
      :destroy-on-hide="false"
    >
      <ContentPanel
        v-if="areaDetailsModalVisible && focusBlock && focusBlock.isWorktime && focusBlock.worktimeArea && focusDay"
        :title="`Szczegóły strefy #${focusBlock.worktimeArea.id}`"
        icon="calendar"
        :paddingless="true"
        :marginless="true"
      >
        <template slot="addon">
          <b-button
            v-if="focusBlock.worktimeArea && notPersonal && !predefinedDayMode"
            @click="moveWorktimeWithAppointments(focusDay, focusBlock)"
            icon-left="arrow-left-right-bold"
            size="is-small"
            type="is-primary"
            class="mr-1"
            :disabled="!schedule2editor || readonly || (focusDay.office && focusDay.office.isScheduleBlocked) || focusBlock.worktimeArea.isEr"
          >
            Przełóż całą strefę i wizyty
            <span v-if="!schedule2editor">(brak uprawnień)</span>
          </b-button>

          <b-button
            v-if="focusBlock.isBackground && notPersonal && !predefinedDayMode"
            @click="addTimeless(focusDay, focusBlock)"
            icon-left="calendar"
            size="is-small"
            type="is-secondary"
            :disabled="readonly || (focusDay.office && focusDay.office.isScheduleBlocked)"
            class="mr-1"
          >Dodaj wizytę bez godziny</b-button>

          <b-button
            v-else-if="notPersonal"
            @click="showDetailsOrNewAppointmentModal(focusDay, focusBlock)"
            icon-left="calendar"
            size="is-small"
            type="is-primary"
            :disabled="readonly || (focusDay.office && focusDay.office.isScheduleBlocked)"
            class="mr-1"
          >Dodaj wizytę</b-button>

          <b-button
            @click="areaDetailsModalVisible = false"
            icon-left="close"
            size="is-small"
            type="is-danger"
          >Zamknij</b-button>
        </template>
        <Schedule2AreaDetails
          @updated="reload"
          :day="focusDay"
          :block="focusBlock"
          :date="focusDay.moment"
        ></Schedule2AreaDetails>
      </ContentPanel>
    </b-modal>

    <!--                    -->
    <!-- AREA DETAILS MODAL -->
    <!--                    -->
    <b-modal
      :active.sync="slotAddModalVisible"
      has-modal-card
      scroll="keep"
      :destroy-on-hide="false"
    >
      <ContentPanel
        v-if="slotAddModalVisible && focusBlock"
        :title="`Dodawanie slotów w godzinach od ${mTime(focusBlock.start)} do ${mTime(focusBlock.end)}`"
        icon="calendar"
      >
        <template slot="addon">

          <b-button
            @click="slotAddModalVisible = false"
            icon-left="close"
            size="is-small"
          >Zamknij</b-button>
        </template>

        <p class="mb-3">
          <b-button
            expanded
            icon-left="fast-forward-10"
            @click="addSlot(10)"
            :loading="slotsLoading"
          >Sloty co 10 minut</b-button>
        </p>
        <p>
          <b-button
            expanded
            icon-left="fast-forward-15"
            @click="addSlot(15)"
            :loading="slotsLoading"
          >Sloty co 15 minut</b-button>
        </p>

      </ContentPanel>
    </b-modal>

    <!--                        -->
    <!-- CONTEXT MENU FOR TILES -->
    <!--                        -->
    <div
      v-if="focusBlock && contextMenuVisible"
      class="card context-menu pb-1"
      :style="contextMenuStyle"
    >
      <div
        class="pl-1"
        style="border-bottom: 1px solid #dbdbdb;"
      >
        <table class="w-100 is-size-7 has-text-weight-semibold">
          <tr>
            <td
              class="p-1"
              v-html="focusBlock.tooltip"
            ></td>
            <td class="has-text-right">
              <b-button
                size="is-small"
                type="is-text"
                icon-left="close"
                @click="contextMenuVisible = false"
              ></b-button>
            </td>
          </tr>
        </table>
      </div>
      <ul>
        <li @click="showAreaDetails">
          <span v-if="focusBlock.appointment">Szczegóły wizyty</span>
          <span v-if="focusBlock.isWorktime">Szczegóły strefy</span>
        </li>
        <li
          v-if="focusBlock.appointment && (focusBlock.appointment.state == 0 || focusBlock.appointment.state == 100) && !focusBlock.appointment.patientWaiting && notPersonal"
          @click="reschedule"
        >Przełóż</li>
        <li
          v-if="focusBlock.isEmpty"
          @click="contextMenuVisible = false"
        >Brak strefy</li>
        <li
          @click="markPatientWaiting(true)"
          v-if="isToday(focusBlock) && focusBlock.appointment && focusBlock.appointment.state < 1 && !focusBlock.appointment.patientWaiting && notPersonal"
        >Pacjent w poczekalni</li>
      </ul>
    </div>

    <!--                    -->
    <!-- TASK DETAILS MODAL -->
    <!--                    -->
    <b-modal
      :active.sync="taskDetailsModalVisible"
      has-modal-card
      class="chat-pad chatsize"
      scroll="keep"
      :destroy-on-hide="false"
    >
      <ContentPanel
        v-if="taskDetailsModalVisible && focusBlock && focusBlock.task && focusDay"
        :title="`Szczegóły zadania #${focusBlock.task.internalId}`"
        icon="calendar"
        :paddingless="true"
        :marginless="true"
        @close="taskDetailsModalVisible = false"
      >
        <template slot="addon">
          <WorkerTaskInfo
            :task="focusBlock.task"
            class="mr-1 is-inline"
          ></WorkerTaskInfo>
          <b-button
            @click="taskDetailsModalVisible = false"
            icon-left="close"
            size="is-small"
          >Zamknij</b-button>
        </template>

        <div
          class="modal-card"
          style="overflow: visible;width:100% !important"
        >
          <section
            class="modal-card-body"
            v-if="focusBlock"
            style="min-height: 18rem;"
          >
            <div class="columns">
              <div class="column is-6">
                <table class="table is-bordered w-100">
                  <tr>
                    <th style="width: 14rem;">Zadanie</th>
                    <th>{{focusBlock.task.content}}</th>
                  </tr>
                  <tr>
                    <td>Utworzono</td>
                    <td>{{mDateTime(focusBlock.task.created)}}</td>
                  </tr>
                  <tr>
                    <td>Rozpoczęcie</td>
                    <td>{{mDateTime(focusBlock.task.startDate)}}</td>
                  </tr>
                  <tr>
                    <td>Przewidywane zakończenie</td>
                    <td>{{mDateTime(focusBlock.task.finishDate)}}</td>
                  </tr>
                  <tr>
                    <td>Zakończono</td>
                    <td>{{mDateTime(focusBlock.task.endDate)}}</td>
                  </tr>
                  <tr>
                    <td>Deadline</td>
                    <td>{{mDateTime(focusBlock.task.deadlineDate)}}</td>
                  </tr>
                </table>
              </div>
              <div class="column is-6">
                <b-notification
                  type="is-info"
                  has-icon
                >Wkrótce pojawi się tu więcej informacji :)</b-notification>
                <!-- <pre>{{focusBlock.task}}</pre> -->
              </div>
            </div>
          </section>
        </div>
        <!-- <Schedule2AppDetails
          :block="focusBlock"
          :blocks="focusDay.blocks"
          @close="taskDetailsModalVisible = false"
        ></Schedule2AppDetails>-->
      </ContentPanel>
    </b-modal>

    <!--                    -->
    <!-- TASK DETAILS MODAL -->
    <!--                    -->
    <b-modal
      :active.sync="areaMoveModal"
      has-modal-card
      class="chat-pad chatsize"
      scroll="keep"
      :destroy-on-hide="false"
    >
      <ContentPanel
        v-if="areaMoveModal && focusSlot && focusDay && focusArea"
        :title="`Przekładanie strefy #${focusArea.worktimeArea.id}`"
        icon="calendar"
        :paddingless="true"
        :marginless="true"
      >
        <template slot="addon">
          <b-button
            @click="proceedWithAreaMove"
            icon-left="check"
            :loading="loading"
            size="is-small"
            type="is-secondary"
            class="mr-1"
          >Potwierdź przeniesienie strefy</b-button>
          <b-button
            @click="areaMoveModal = false"
            icon-left="close"
            size="is-small"
          >Zamknij</b-button>
        </template>
        <div
          class="modal-card"
          style="overflow: visible;width:100% !important"
        >
          <section
            class="modal-card-body"
            style="min-height: 18rem;"
          >
            <div class="columns">
              <div class="column is-6">
                <table class="table w-100 mb-3 is-bordered">
                  <tr>
                    <th>Aktualna data</th>
                    <th>Nowa data</th>
                  </tr>
                  <tr>
                    <td>{{mDate(focusArea.start)}}</td>
                    <td>{{mDate(focusSlot.start)}}</td>
                  </tr>
                  <tr>
                    <th>Aktualna godzina</th>
                    <th>Nowa godzina</th>
                  </tr>
                  <tr>
                    <td>{{mTime(focusArea.start)}}</td>
                    <td>{{mTime(focusSlot.start)}}</td>
                  </tr>
                </table>
                <table class="table is-bordered w-100">
                  <tr>
                    <th colspan="3">Wizyty</th>
                  </tr>
                  <tr>
                    <th>Godzina</th>
                    <th>Pacjent</th>
                    <th>Typ</th>
                  </tr>
                  <tr
                    v-for="item in selectedWorktimeAreaApps"
                    :key="item.index"
                  >
                    <td>{{mTime(item.start)}}</td>
                    <td>{{item.name}}</td>
                    <td>{{item.typeName}}</td>
                  </tr>
                </table>
              </div>
              <div class="column is-6">
                <b-field>
                  <b-checkbox v-model="areaMoveWithApps">Przenieś razem z wizytami</b-checkbox>
                </b-field>
                <b-field>
                  <b-checkbox
                    v-model="areaMoveSendSms"
                    disabled
                  >Wyślij powiadomienia SMS</b-checkbox>
                </b-field>
                <b-field grouped>
                  <p class="control">Opóźnij wysłanie SMS o</p>
                  <b-numberinput
                    controls-alignment="right"
                    controls-position="compact"
                    size="is-small"
                    :disabled="true || !areaMoveSendSms"
                    v-model="areaMoveDelaySms"
                  ></b-numberinput>
                  <p class="control">minut</p>
                </b-field>
              </div>
            </div>
          </section>
        </div>
      </ContentPanel>
    </b-modal>

    <!--              -->
    <!-- ER END MODAL -->
    <!--              -->
    <b-modal
      :active.sync="endErAppModalVisible"
      has-modal-card
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
      scroll="keep"
    >
      <ContentPanel
        :rounded="true"
        title="Skreślanie wizyty w E-Rejestracji"
        icon="upload"
        v-if="focusBlock && focusBlock.appointment"
      >
        <template slot="addon">
          <b-button
            size="is-small"
            type="is-primary"
            icon-left="calendar-check"
            :disabled="erEndReason == null"
            @click="endErApp"
            class="mr-3"
          >Potwierdź skreślenie</b-button>
          <b-button
            size="is-small"
            type="is-danger"
            icon-left="close"
            @click="endErAppModalVisible = false"
          >Anuluj</b-button>
        </template>
        <!-- <b-radio class="mb-3" v-model="erEndReason" :native-value="1">Termin wpisany przez pomyłkę</b-radio>
        <br />-->
        <!-- <b-radio
          class="mb-3"
          v-model="erEndReason"
          :native-value="2"
        >Termin przypada na dzień wolny od pracy</b-radio>
        <br />
        <b-radio
          class="mb-3"
          v-model="erEndReason"
          :native-value="3"
        >Względy medyczne - przyśpieszenie terminu</b-radio>
        <br />
        <b-radio
          class="mb-3"
          v-model="erEndReason"
          :native-value="4"
        >Względy medyczne - czasowe odroczenie terminu</b-radio>
        <br />
        <b-radio
          class="mb-3"
          v-model="erEndReason"
          :native-value="5"
        >Na wniosek osoby wpisanej w harmonogram przyjęć/ listę oczekujących</b-radio>
        <br />
        <b-radio class="mb-3" v-model="erEndReason" :native-value="6">Przesunięcie grupowe</b-radio>
        <br />-->

        <b-radio
          class="m-4"
          v-model="erEndReason"
          :native-value="77"
        >Z przyczyn leżących po stronie świadczeniodawcy</b-radio>
        <br />
        <b-radio
          class="ml-4 mb-5 mr-4"
          v-model="erEndReason"
          :native-value="88"
        >Z przyczyn leżących po stronie pacjenta</b-radio>
        <br />
        <!--         
        <b-radio class="mb-3" v-model="erEndReason" :native-value="8">Brak kompletu badań</b-radio>
        <br />
        <b-radio
          class="mb-3"
          v-model="erEndReason"
          :native-value="9"
        >Aktualizacja terminu wyznaczonego pierwotnie z dokładnością do tygodnia</b-radio>
        <br />
        <b-radio class="mb-3" v-model="erEndReason" :native-value="10">Inna przyczyna</b-radio>
        <br />
        <b-radio
          class="mb-3"
          v-model="erEndReason"
          :native-value="11"
        >Aktualizacja terminu w wyniku rezygnacji innych pacjentów</b-radio>
        <br />
        <b-radio
          class="mb-3"
          v-model="erEndReason"
          :native-value="12"
        >Nieprawidłowa kategoria medyczna podana podczas rejestracji</b-radio>
        <br />
        <b-radio class="mb-3" v-model="erEndReason" :native-value="13">Nieobecność lekarza</b-radio>
        <br />
        <b-radio
          class="mb-3"
          v-model="erEndReason"
          :native-value="14"
        >Osoba wpisana na listę oczekujących/ harmonogram przyjęć nie kwalifikowała się do leczenia ze względów medycznych w terminie pierwotnie wyznaczonym</b-radio>
        <br />
        <b-radio
          class="mb-3"
          v-model="erEndReason"
          :native-value="15"
        >Osoba skorzystała z możliwości, o której mowa w art. 20 ust. 9A ustawy</b-radio>
        <br />
        <b-radio
          class="mb-3"
          v-model="erEndReason"
          :native-value="16"
        >Zmiana kategorii, o której mowa w art. 19A ust. 4 pkt 3 ustawy</b-radio>
        <br />
        <b-radio class="mb-3" v-model="erEndReason" :native-value="17">Osoba wpisana w harmonogram</b-radio>-->
      </ContentPanel>
    </b-modal>

    <!--                                              -->
    <!-- EDITOR V2, used for worktime area management -->
    <!--                                              -->
    <Schedule2Editor
      :existing="focusBlock"
      :day="focusDay"
      :payload="payload"
      :selectedDayIds="selectedDays"
      :selectedSlots="slotsSelected"
      :editTrigger="worktimeEditTrigger"
      :creatorTrigger="creatorTrigger"
      :multichangeTrigger="multiAreaChangeTrigger"
      @updated="reload"
      @removed="reload"
      @clear="clearEditorData"
    ></Schedule2Editor>

    <!--                       -->
    <!-- HIDDEN PRINT TEMPLATE -->
    <!--                       -->
    <div style="display: none;">
      <div
        id="appointmentDayPrint"
        ref="appointmentDayPrint"
      >
        <div style="
            margin-left: 1rem;
            margin-right: 1rem;
            margin-top: 2rem;
            margin-bottom: 2rem;
            font-size: 18px;
            font-family:Arial;
          ">
          <div style="text-align: center">
            <p
              v-if="printDate"
              style="margin-top: 1rem; margin-bottom: 1rem; font-size: 1rem;"
            >Lista rezerwacji na dzień: {{ mDate(printDate) }}</p>
          </div>
          <div
            v-for="(item, index) in printAppointments"
            :key="index"
          >
            <p style="text-align: center">{{ item.employee ? item.employee.toUpperCase() : "" }}</p>
            <p style="text-align: center">{{ mDate(item.date) }}</p>
            <table
              class="table"
              style="
                margin-top: 0.5rem;
                width: 100%;
                margin: 0;
                paddding: 0;
                font-size: 0.7rem;
              "
              v-if="item.appointments.length > 0"
            >
              <tr>
                <th style="text-align: left">Godz.</th>
                <th style="text-align: left">Pacjent</th>
                <th style="text-align: left">{{ perms.worktimePatientPrintPESEL ? "PESEL" : "Data ur." }}</th>
                <th style="text-align: left">Telefon</th>
                <!-- <th class="has-text-centered">NFZ</th> -->
                <th style="text-align: left">Notatka</th>
                <th style="text-align: left">{{ showAppointmentTypeOnPrint ? "Typ wizyty" : "Usługa" }}</th>
              </tr>
              <tr
                v-for="(item, index) in item.appointments"
                :key="index"
              >
                <td>
                  {{
                  item.isTimeless
                  ? `-`
                  : `${mTime(item.start)} - ${mTime(item.stop)}`
                  }}
                </td>
                <td>
                  {{
                  item.patient
                  ? `${item.patient.lastName} ${item.patient.firstName}`
                  : "-"
                  }}
                </td>
                <td>
                  <span v-if="perms.worktimePatientPrintPESEL">
                    {{
                    item.patient ? `${item.patient.pesel}` : "-"
                    }}
                  </span>
                  <span v-else>
                    {{
                    item.patient ? `${getPatientBirthdate(item.patient)}` : "-"
                    }}
                  </span>
                </td>
                <td>
                  {{
                  item.patient
                  ? `${item.patient.teleAddressTelephoneNumber}`
                  : "-"
                  }}
                </td>
                <!-- <td class="has-text-centered">{{ item.isNfz ? "Tak" : "Nie" }}</td> -->
                <td>{{ item.description ? item.description : " " }}</td>
                <td>
                  <div
                    v-if="showAppointmentTypeOnPrint"
                    style="font-size: 0.6rem !important;"
                  >
                    {{
                    item.appointmentType
                    }}
                    {{ item.appointmentSubtype !== null ? `, ${item.appointmentSubtype}` : ""}}
                  </div>
                  <div v-else>
                    <div
                      v-if="item.ticket"
                      style="font-size: 0.6rem !important;"
                    >
                      <p
                        v-if="item.ticket.ikz"
                        style="margin-bottom: 0.25rem"
                      >
                        <span
                          v-for="item in item.ticket.ikz"
                          :key="item.id"
                        >
                          {{
                          item.name
                          }}
                        </span>
                      </p>
                      <div
                        v-if="
                          item.ticket.procedures &&
                          item.ticket.procedures.length > 0
                        "
                        style="margin-bottom: 0.25rem"
                      >
                        <p
                          style="margin: 0; padding: 0"
                          v-for="(item, index) in item.ticket.procedures"
                          :key="index"
                        >
                          <span>{{ item.description }}</span>
                          <span v-if="item.bodySide">
                            , strona ciała:
                            <span class="has-text-weight-bold">{{ item.bodySide }}</span>
                          </span>
                          <span v-if="item.icd9 != null && item.icd9 != ''">({{ item.icd9 }})</span>
                          <span v-if="item.params && item.params.length > 0">
                            , projekcje:
                            <span
                              v-for="x in item.params"
                              :key="x.index"
                            >{{ mGetProjectionName(x) }},</span>
                          </span>
                        </p>
                      </div>
                    </div>
                    <span v-else></span>
                  </div>
                </td>
              </tr>
            </table>
            <p
              style="margin-top: 0.5rem"
              v-else
            >Brak wizyt</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CommonMixins from "@/mixins/commons";
// import ContentPanel from '@/components/tools/ContentPanel'
import WorkerTaskInfo from "@/components/projects-management/WorkerTaskInfo";
import DateHelpers from "@/mixins/date_helpers";
import AppoMixin from "@/mixins/appointments";
import { Action } from "@/store/config_actions";
import { Mutation } from "@/store/config_mutations";
import { Cookies } from "@/store/config_cookies";
import Schedule2AppDetails from "./Schedule2AppDetails";
import Schedule2NewApp from "./Schedule2NewApp";
import Schedule2AreaDetails from "./Schedule2AreaDetails";
import ScheduleDynoLegend from "./ScheduleDynoLegend";
import Schedule2Editor from "./Schedule2Editor";
import moment from "moment";
import RisMixins from "@/mixins/ris";
import ErSignMixins from "@/mixins/zm/erSignObject";

export default {
  name: "Schedule",
  mixins: [CommonMixins, DateHelpers, AppoMixin, RisMixins, ErSignMixins],
  components: {
    Schedule2AppDetails,
    Schedule2NewApp,
    Schedule2AreaDetails,
    Schedule2Editor,
    WorkerTaskInfo,
    ScheduleDynoLegend,
  },

  props: {
    /**
     * Schedule filters.
     */
    filters: { type: Object, required: false },

    /**
     * Main trigger. Used for data updating.
     */
    trigger: { type: Number, required: false },

    /***
     * Editor mode trigger.
     */
    editorActive: { type: Boolean, required: false },

    /***
     * Slot editor mode trigger.
     */
    editorSlotsActive: { type: Boolean, required: false },

    /**
     * Patient to focus on.
     */
    patient: { type: Object, required: false },

    /**
     * Day data provided without calling 'search' API.
     * Used to display local data.
     */
    predefinedDay: { type: Object, required: false },

    /**
     * Reschedule mode indicator.
     */
    rescheduleMode: { type: Boolean, required: false },

    /**
     * Trigger used to reset schedule data.
     */
    resetTrigger: { type: Number, required: false },

    /**
     * Type of schedule view.
     */
    scheduleViewType: { type: Number, required: false },

    /**
     * Personal schedule indicator.
     */
    personal: { type: Boolean, required: false },

    /**
     * Timed appointments only indicator.
     */
    timedOnly: { type: Boolean, required: false },

    readonly: { type: Boolean, required: false },
  },

  data: function () {
    return {
      /**
       * Schedule source data.
       */
      payload: { days: [] },

      /**
       * Loading indicator.
       */
      loading: false,

      /**
       * Schedule visibility from this hour.
       */
      hourFrom: 7,

      /**
       * Schedule visibility to this hour.
       */
      hourTo: 20,

      /**
       * Amount of timelines displayed. This changes with hours diplayed.
       */
      timelinesCount: 0,
      timelinesWidth: window.innerWidth * 0.78,

      /**
       * Schedule scale.
       */
      pixelsPerMinute: 2.4,

      areasSelected: [],

      /**
       * Default timetable style.
       */
      timetableBlockStyle: `min-height: ${15 * 2.4}px;width:2.2rem;`,

      /**
       * Timeless mode indicator. If true, only timeless appointments can be created.
       */
      timelessOnlyMode: false,

      /**
       * Appointment type label on blocks visibility.
       */
      showAppointmentTypeLabelOnBlocks: false,

      /**
       * Appointment type name on print.
       */
      showAppointmentTypeOnPrint: false,

      /**
       * Task column visibility.
       */
      showMyTasks: false,

      /**
       * Context menu style string. Contains menu fixed position.
       */
      contextMenuStyle: "",

      erEndReason: null,

      /**
       * Reversed block colors indicator.
       */
      showReversedBlockColors: false,

      /**
       * Time markers.
       */
      markers: [],

      /**
       * Current block to focus on.
       */
      focusBlock: null,

      /**
       * Current day to focus on.
       */
      focusDay: null,

      /**
       * Current slot to focus on.
       */
      focusSlot: null,

      /**
       * Reservation to be changed to full appointment.
       */
      focusReservation: null,

      /**
       * Appointment details modal indicator.
       */
      appointmentDetailsModalVisible: false,

      /**
       * Task details modal indicator.
       */
      taskDetailsModalVisible: false,

      /**
       * App creator modal indicator.
       */
      newAppointmentModalVisible: false,

      /**
       * Area details moda visibility.
       */
      areaDetailsModalVisible: false,
      slotAddModalVisible: false,

      /**
       * Context menu visibility.
       */
      contextMenuVisible: false,

      /**
       * Information modal indicator.
       */
      infoModalActive: false,

      /**
       * Timeless apps modal indicator.
       */
      timelessModalVisible: false,

      /**
       * Tasks modal indicator.
       */
      tasksModalVisible: false,

      /**
       * Worktime area move modal indicator.
       */
      areaMoveModal: false,

      /**
       * Appointments included in focused area block.
       */
      selectedWorktimeAreaApps: [],

      /**
       * Indicator if appointments should be moved with worktime area.
       */
      areaMoveWithApps: true,

      /**
       * Indicator if moving area should trigger sms notifications.
       */
      areaMoveSendSms: false,

      /**
       * Sms notifications delay in case of area move with appointments.
       */
      areaMoveDelaySms: 0,

      /**
       * Editor mode indicator.
       */
      editorMode: false,
      editorSlotsMode: false,

      /**
       * Indicator if all days should be selected for edit.
       */
      fullRowSelection: false,

      /**
       * If mouse is pressed down.
       */
      mouseDown: false,

      /**
       * Trigger for area editor modal.
       */
      worktimeEditTrigger: 0,

      /**
       * Trigget for area movememnt in the old way.
       */
      multiAreaChangeTrigger: 0,

      /**
       * Worktime area creator modal trigger.
       */
      creatorTrigger: 0,

      /**
       * Is tooltip visible.
       */
      tooltipVisible: false,
      slotsLoading: false,

      endErAppModalVisible: false,

      /**
       * Tooltip X position.
       */
      tooltipX: 0,

      /**
       * Tooltip Y position.
       */
      tooltipY: 0,

      /**
       * Tooltip content part A.
       */
      tooltipA: null,

      /**
       * Tooltip content part B.
       */
      tooltipB: null,

      /**
       * Slots selected for new area.
       */
      slotsSelected: [],

      /**
       * Days selected for area editor.
       */
      selectedDays: [],

      // ***************************
      // OLD (MAYBE) REQUIRED FIELDS
      // ***************************

      /**
       * Current time marker Y position.
       */
      timeMarkerPosition: 300,

      /**
       * Fixed header X position.
       */
      fixedHeader: 0,

      /**
       * Fixed header visible.
       */
      fixedEnabled: true,
      fixedVisible: false,
      scrolling: false,
      show5minutes: true,
      fixedLeft: 0,
      scrollTop: 0,

      /** Every slot on current data days. */
      everyslot: [],

      firstSelected: null,

      /**
       * Date of print?
       */
      printDate: null,
      printAppointments: [],
      changeHidden: false,
      //hiddenChevron:false,
      appTypeVisibility: false,

      editorCurrentOffice: null,

      scheduleScrollerOffset: 0,
    };
  },

  watch: {
    selectedDays(val) {
      if (val && val.length > 0) {
        var first = val[0];
        var parts = first.split("_");

        if (parts.length > 2) {
          this.editorCurrentOffice = parts[2];
        }
      } else {
        this.editorCurrentOffice = null;
      }

      this.refillActiveSlots();
    },

    slotsSelectedLength() {
      this.recalculateSlotStyles();
    },

    editorActive(val) {
      this.selectedDays = [];

      if (val) {
        this.editorSlotsMode = false;
        this.editorMode = true;
      } else {
        this.editorMode = false;
      }
    },

    editorSlotsActive(val) {
      this.selectedDays = [];

      if (val) {
        this.editorMode = false;
        this.editorSlotsMode = true;
      } else {
        this.editorSlotsMode = false;
      }

      this.recalculateElementSizeAndStyle();
    },

    /**
     * Hour from changed
     */
    hourFrom() {
      // Recalculate visible time range
      this.recalculateTimetable();
      this.recalculateElementSizeAndStyle();
    },

    /**
     * Reverse colors switched.
     */
    showReversedBlockColors() {
      // Recalculate block styles
      this.recalculateElementSizeAndStyle();
    },

    /**
     * Task column visibility changed.
     */
    showMyTasks() {
      // Recalculate block styles
      this.recalculateElementSizeAndStyle();
    },

    /**
     * Hour to changed
     */
    hourTo() {
      // Recalculate visible time range
      this.recalculateTimetable();
      this.recalculateElementSizeAndStyle();
    },

    /**
     * Schedule scale changed
     */
    pixelsPerMinute() {
      // Recalculate block styles
      this.recalculateTimetableBlockStyle();
      this.recalculateElementSizeAndStyle(true);
    },

    /**
     * App type visibility changed
     */
    showAppointmentTypeLabelOnBlocks() {
      // Recalculate block styles
      this.recalculateElementSizeAndStyle();
    },

    /**
     * Focus block changed.
     */
    focusBlock() {
      // Clear lingering reservation
      this.focusReservation = null;
      this.$store.commit(Mutation.EWUS_RESET_RESERVATION);
    },

    oneclickModalTriggerActive() {
      if (this.oneclickModalTriggerActive && this.patient && this.focusBlock) {
        this.showDetailsOrNewAppointmentModal(this.focusDay, this.focusBlock);
      }
    },

    trigger() {
      if (this.filters) {
        this.filters.afterDay = null;
        this.reload(true);
      }
    },

    resetTrigger() {
      //this.payload.length = 0
      this.payload = { days: [] };
    },

    scheduleViewType(valOld, valNew) {
      if (valOld != valNew) {
        this.changeHidden = true;
      } else {
        this.changeHidden = false;
      }
    },

    // "filters.areaFilter"() {
    //   this.recalculateElementSizeAndStyle();
    // },
  },

  mounted() {
    this.mounting = true;

    // Recalculate visible time range
    this.recalculateTimetable();

    let settings = this.$cookies.get(
      Cookies.LOCALS_SCHEDULE2_SETTINGS.replace("{eid}", this.me.id)
    );
    if (settings) {
      this.hourFrom = settings.hourFrom;
      this.hourTo = settings.hourTo;
      this.showAppointmentTypeLabelOnBlocks =
        settings.showAppointmentTypeLabelOnBlocks;
      this.fixedEnabled = settings.fixedEnabled;
      this.pixelsPerMinute = settings.pixelsPerMinute;
      this.showMyTasks = settings.showMyTasks;
      this.showReversedBlockColors = settings.showReversedBlockColors;
      this.show5minutes = settings.show5minutes;
    } else {
      // do nothing
    }

    this.mounting = false;

    if (this.predefinedDayMode && this.predefinedDay) {
      let payloadDays = { days: [] };
      payloadDays.days.push(this.predefinedDay);
      this.wrapDays(payloadDays);
    }
  },

  created() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("scrollend", this.handleScrollEnd);
  },

  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("scrollend", this.handleScrollEnd);

    while (this.days != null && this.days.length > 0) {
      this.days.pop();
    }
  },

  computed: {
    focusPatient() {
      if (this.focusReservation?.appointment?.patient) {
        return this.focusReservation.appointment.patient;
      }

      return this.patient;
    },

    subunits() {
      return this.$store.state.orgUnit.subunits ?? [];
    },

    schedulesStore() {
      return this.$store.state.registration.schedules;
    },
    schedule2editor() {
      return this.$store.state.employee.permits.schedule2editor;
    },
    autoNavigateTarget() {
      return this.$store.state.registration.autoNavigateTarget;
    },

    slotsSelectedLength() {
      return this.slotsSelected.length;
    },

    firstAvailableMode() {
      if (this.filters) {
        let modesFobidden = [1, 3, 5, 7, 9, 12];
        return modesFobidden.includes(this.filters.searchType);
      } else {
        return false;
      }
    },

    /**
     * Time mode indicator. If true, only appointments with time can be created.
     */
    timeOnlyMode() {
      if (this.timedOnly) {
        return true;
      } else {
        return false;
      }
    },

    predefinedDayMode() {
      if (this.predefinedDay) {
        return true;
      } else {
        return false;
      }
    },

    /**
     * NOT personal mode indicator.
     */
    notPersonal() {
      if (this.personal) {
        return false;
      } else {
        return true;
      }
    },

    /** Items for hours from dropdown */
    hoursFrom() {
      let hours = [];
      for (let index = 4; index < this.hourTo; index++) {
        hours.push({ value: index, text: `${index}:00` });
      }

      return hours;
    },

    /** Items for hours to dropdown */
    hoursTo() {
      let hours = [];
      for (let index = this.hourFrom + 1; index < 23; index++) {
        hours.push({ value: index, text: `${index}:00` });
      }

      return hours;
    },

    timeMarkerStyle() {
      return `top: calc(${this.timeMarkerPosition}px + 8.9rem);`;
    },
    oneclickModalTriggerActive() {
      return this.$store.state.schedule.openOneclickAddModal;
    },
    employees() {
      return this.$store.state.employee.all;
    },
    isNotOffice() {
      return (
        (this.filters && this.filters.office === null) ||
        this.filters.office == -1
      );
    },
    clinic() {
      return this.$store.state.clinic.activeClinic.clinicId;
    },
    configOffices() {
      return this.$store.state.config.offices;
    },
    allOffices() {
      return this.$store.state.offices.offices;
    },
    apps() {
      return this.$store.state.config.appointments;
    },
    perms() {
      return this.$store.state.employee.permits;
    },
    hiddenChevron() {
      if (
        this.changeHidden == false &&
        this.scheduleViewType != null &&
        this.scheduleViewType != undefined &&
        this.scheduleViewType == 1
      )
        return false;
      else return true;
    },

    /**
     * Konto pracownika aktualnie zalogowanego
     */
    me() {
      return this.$store.state.employee.me;
    },

    meParentFacilityObject() {
      if (this.facilities) {
        var found = this.facilities.find(
          (x) => x.internalId === this.me.parentFacility
        );
        return found;
      } else return null;
    },

    facilities() {
      return this.$store.state.clinic.clinics ?? [];
    },

    registrationIkz() {
      return this.$store.state.registration.ikz;
    },

    focusArea() {
      return this.$store.state.poz.focusArea;
    },
  },

  methods: {
    addSlot(minutes) {
      this.slotsLoading = true;
      let payload = JSON.parse(JSON.stringify(this.focusBlock));
      payload.h = minutes;
      payload.facility = null;

      this.$store
        .dispatch(Action.CGATE_ADD_SLOTS, payload)
        .then(() => {
          this.slotsLoading = false;
          this.hideModalsAndReload();
        })
        .catch((error) => {
          this.slotsLoading = false;
          this.apiProblem(error);
        });
    },

    switchSlotState(block) {
      if (block.error < 2) {
        this.$buefy.dialog.confirm({
          title: "Blokowanie slotu E-Rejestracji",
          message: "Czy na pewno zablokować ten slot?",
          confirmText: "Tak, zablokuj",
          cancelText: "Anuluj",
          type: "is-warning",
          hasIcon: true,
          scroll: "keep",
          onConfirm: () => {
            var payload = {
              id: block.name,
              state: 2,
            };

            this.$store
              .dispatch(Action.CGATE_MODIFY_SLOT, payload)
              .then(() => {
                this.hideModalsAndReload();
              })
              .catch((error) => {
                this.apiProblem(error);
              });
          },
        });
      } else if (block.error == 2) {
        this.$buefy.dialog.confirm({
          title: "Odblokowanie slotu E-Rejestracji",
          message: "Czy na pewno odblokować ten slot?",
          confirmText: "Tak, odblokuj",
          cancelText: "Anuluj",
          type: "is-info",
          hasIcon: true,
          scroll: "keep",
          onConfirm: () => {
            var payload = {
              id: block.name,
              state: 1,
            };

            this.$store
              .dispatch(Action.CGATE_MODIFY_SLOT, payload)
              .then(() => {
                this.hideModalsAndReload();
              })
              .catch((error) => {
                this.apiProblem(error);
              });
          },
        });
      }
    },

    showSlotAddModal(block) {
      this.focusBlock = block;
      this.slotAddModalVisible = true;
    },

    clearFirstDay() {
      this.firstSelected = null;
    },

    cancelAsSubadmin() {
      if (this.focusBlock && this.focusBlock.appointment) {
        this.$buefy.dialog.confirm({
          title: "Anulowanie wizyty w trybie Subadmina",
          message:
            "Uwaga, wizyta zostanie anulowana, jednak jeśli ma podpięte skierowanie, <b>nie zostanie ono wycofane!</b>.<br /><br />Czy na pewno odwołać tę wizytę?",
          confirmText: "Tak, jestem subadminem, więc mi wolno!",
          cancelText: "Anuluj",
          type: "is-danger",
          hasIcon: true,
          scroll: "keep",
          onConfirm: () => {
            let payloadCancel = {
              id: this.focusBlock.appointment.id,
              reason: -999,
            };

            this.$store
              .dispatch(Action.S2_CANCEL_APPOINTMENT, payloadCancel)
              .then(() => {
                this.hideModalsAndReload();
              })
              .catch((error) => {
                this.apiProblem(error);
                this.requestInProgress = false;
              });
          },
        });
      }
    },

    multiDelete() {
      if (this.areasSelected.length > 0) {
        var txt = `Czy na pewno usunąć wszystkie zaznaczone strefy? Tej akcji nie można cofnąć.<br />`;

        this.areasSelected.forEach((element) => {
          txt += `<br />${this.mDate(element.start)} od godziny <b>${this.mTime(
            element.start
          )}</b> do <b>${this.mTime(element.stop)}</b>`;
        });

        this.$buefy.dialog.confirm({
          message: txt,
          confirmText: "Tak, usuń",
          cancelText: "Anuluj",
          type: "is-danger",
          hasIcon: true,
          scroll: "keep",
          onConfirm: () => this.deleteSend(),
        });
      }
    },

    deleteSend() {
      this.loading = true;
      this.$store
        .dispatch(Action.S3_DELETE_WORKTIMES, this.areasSelected)
        .then(() => {
          this.loading = false;
          this.reload(true);
        })
        .catch((error) => {
          this.loading = false;
          this.apiProblem(error);
        });
    },

    switchAreasSelection(selectAll) {
      if (selectAll) {
        this.payload.days.forEach((element) => {
          let checkOfficeBlocked = false;
          if (element?.office?.isScheduleBlocked) {
            checkOfficeBlocked = true;
          }

          if (checkOfficeBlocked) {
            this.snackbar(
              `Gabinet: ${element?.office?.name} zablokowany dla rejestracji`
            );
          } else {
            element.blocks.forEach((block) => {
              if (block.worktimeArea) {
                if (this.areasSelected.includes(block.worktimeArea)) {
                  // already there
                } else {
                  this.areasSelected.push(block.worktimeArea);
                }
              }
            });
          }
        });
      } else {
        this.areasSelected = [];
      }
    },

    allDays(val) {
      if (val) {
        if (this.payload && this.payload.days) {
          this.selectedDays = this.payload.days
            .filter((x) => x.id.includes(this.editorCurrentOffice))
            .map((x) => x.id);
        }
      } else {
        while (this.selectedDays.length > 0) {
          this.selectedDays.pop();
        }
      }
    },

    isToday(focusBlock) {
      let now = this.mDate(moment());
      let item = this.mDate(focusBlock.start);

      return now == item;
    },

    findFacilityForDay(item) {
      if (item.facilityId) {
        item.facilityInfo = this.facilities.find(
          (x) => x.id == item.facilityId
        );
      }
    },

    proceedWithAreaMove() {
      if (this.focusArea && this.focusDay && this.focusSlot) {
        let payload = {
          area: this.focusArea.worktimeArea,
          target: moment(this.focusSlot.start).toDate(),
          includeAppointments: this.areaMoveWithApps,
          smsDelay: this.areaMoveDelaySms,
        };

        if (this.selectedWorktimeAreaApps) {
          payload.appointmentIds = this.selectedWorktimeAreaApps.map(
            (x) => x.appointment.id
          );
        }

        this.loading = true;
        this.$store
          .dispatch(Action.S3_MOVE_PRO, payload)
          .then(() => {
            this.$emit("area-moved");
            this.reload();
            this.areaMoveModal = false;
            this.$store.commit(Mutation.APPOINTMENT_WORKTIME_FOCUS, null);
          })
          .catch((error) => {
            this.loading = false;
            this.apiProblem(error);
            this.$emit("error");
          });
      }
    },

    moveWorktimeWithAppointments() {
      if (this.filters.multiOffice.length > 1) {
        this.$buefy.dialog.alert({
          message:
            "<b>Wybrano wiele gabinetów.</b> Przenoszenie stref dostępne jest tylko w obrębie jednego gabinetu.",
          type: "is-warning",
          scroll: "keep",
          hasIcon: true,
        });
      } else {
        this.selectedWorktimeAreaApps = [];

        if (this.focusBlock && this.focusBlock.worktimeArea) {
          this.areaDetailsModalVisible = false;

          this.$store.commit(
            Mutation.APPOINTMENT_WORKTIME_FOCUS,
            this.focusBlock
          );
          this.toast("Wybierz nowy termin na terminarzu");

          if (
            this.focusBlock &&
            this.focusBlock.worktimeArea &&
            this.focusDay &&
            this.focusDay.blocks
          ) {
            this.selectedWorktimeAreaApps = this.focusDay.blocks.filter(
              (x) =>
                x.appointment &&
                x.appointment.areaId == this.focusBlock.worktimeArea.id
            );
          }
        }
      }
    },

    clearEditorData() {
      this.slotsSelected.length = 0;
      this.recalculateSlotStyles();
    },

    editWorktimeArea(block, day) {
      let checkOfficeBlocked = false;
      if (day?.office?.isScheduleBlocked) {
        checkOfficeBlocked = true;
      }

      if (checkOfficeBlocked) {
        this.snackbar("Gabinet zablokowany dla rejestracji");
      } else {
        this.focusBlock = block;
        this.focusDay = day;
        this.worktimeEditTrigger++;
      }
    },

    onMouseDown(event, item, dayId, dayslots) {
      if (event.button == 0) {
        if (this.focusArea) {
          //movemovemove
          if (this.payload && this.payload.days) {
            let dayFound = this.payload.days.find((d) => d.id == dayId);

            if (dayFound) {
              this.focusDay = dayFound;
              this.focusSlot = item;
              this.areaMoveModal = true;
            }
          }
        } else {
          if (this.selectedDays.includes(dayId)) {
            // cool
          } else {
            this.selectedDays.push(dayId);
          }

          let currentSlections = [];

          if (this.firstSelected) {
            currentSlections = [...this.slotsSelected];

            // let currentSlections = [...this.slotsSelected]
            let existing = currentSlections.find((x) => x.id == item.id);

            if (existing) {
              // its already there
            } else {
              currentSlections.push(item);
            }

            let indexes = currentSlections.map((x) => x.index);
            let min = Math.min(...indexes);
            let max = item.index;

            if (min >= max) {
              max = Math.max(...indexes);
              min = item.index;
            }

            this.slotsSelected = dayslots.filter(
              (x) =>
                this.selectedDays.includes(x.did) &&
                x.index <= max &&
                x.index >= min
            );

            this.firstSelected = null;
            this.creatorTrigger++;
          } else {
            this.firstSelected = item;
            currentSlections.push(item);
            let indexes = currentSlections.map((x) => x.index);

            let min = Math.min(...indexes);
            let max = item.index;

            if (min >= max) {
              max = Math.max(...indexes);
              min = item.index;
            }

            this.slotsSelected = dayslots.filter(
              (x) =>
                this.selectedDays.includes(x.did) &&
                x.index <= max &&
                x.index >= min
            );
          }
        }
      }
    },

    onMouseLeave() {
      //this.tooltipVisible = false
    },

    onMouseEnter(e, item, dayslots) {
      if (this.firstSelected) {
        // this.tooltipX = e.clientX + 50
        // this.tooltipY = e.clientY - 10
        // this.tooltipB = this.mTime(item.end)
        // this.tooltipVisible = true
        let currentSlections = [...this.slotsSelected];
        let existing = currentSlections.find((x) => x.id == item.id);

        if (existing) {
          // its already there
        } else {
          currentSlections.push(item);
        }

        let indexes = currentSlections.map((x) => x.index);

        let min = Math.min(...indexes);
        let max = item.index;

        if (min >= max) {
          max = Math.max(...indexes);
          min = item.index;
        }

        this.slotsSelected = dayslots.filter(
          (x) =>
            this.selectedDays.includes(x.did) &&
            x.index <= max &&
            x.index >= min
        );
      } else {
        // this.tooltipA = this.mTime(item.start)
      }
    },

    generalMouseMove() {
      // this.tooltipX = e.clientX + 30
      // this.tooltipY = e.clientY
      // this.tooltipVisible = true
    },

    generalMouseLeave() {
      //this.tooltipVisible = false
    },

    onMouseUp() {},

    showSelectionRectangle(selection) {
      this.selectionStyle = `left: ${selection.left}px;top: ${
        selection.top + window.scrollY
      }px;width: ${selection.right - selection.left}px;height:${
        selection.bottom - selection.top
      }px;opacity: 0.5;`;
    },

    hideSelectionRectangle() {
      this.selectionStyle = "opacity: 0;";
    },

    emitTime(item) {
      this.$emit("time-selected", item);
    },

    addNewTask(day) {
      day.toString();
    },

    showReservationCompletionModal(item) {
      this.appointmentDetailsModalVisible = false;
      this.focusReservation = item;
      this.newAppointmentModalVisible = true;
    },

    /**
     * Displays modal with timeless appointment details. MC
     * @param app Schedule appointment model.
     */
    showTaskModal(day, block) {
      this.focusDay = day;
      this.focusBlock = block;
      this.taskDetailsModalVisible = true;
    },

    /**
     * Displays modal with timeless appointment details. MC
     * @param app Schedule appointment model.
     */
    showTimelessApp(app) {
      let findWorktime = null;
      let start = null;
      let end = null;

      if (this.focusDay && this.focusDay.worktimes) {
        findWorktime = this.focusDay.worktimes.find(
          (worktime) => worktime.id === app.areaId
        );
      }

      if (findWorktime) {
        start = findWorktime.start;
        end = findWorktime.end;
      } else {
        start = app.start;
        end = app.end;
      }

      this.focusBlock = {
        appointment: app,
        worktimeArea: findWorktime,
        start: start,
        end: end,
      };
      this.appointmentDetailsModalVisible = true;
    },

    /**
     * Displays modal with timeless appointment details. MC
     * @param app Schedule appointment model.
     */
    triggerMultiAreaChange(day) {
      this.focusDay = day;
      this.multiAreaChangeTrigger++;
    },

    /**
     * Shows modal with timeless appointments for specific day. MC
     * @param day Data for targeted day.
     */
    showTimeless(day) {
      this.focusDay = day;
      this.timelessModalVisible = true;
    },

    /**
     * Shows modal with tasks for specific day. MC
     * @param day Data for targeted day.
     */
    showTasksList(day) {
      this.focusDay = day;
      this.tasksModalVisible = true;
    },

    /**
     * Hides all shown modals and redownloads schedule. MC
     */
    hideModalsAndReload(item) {
      this.reload();
      this.slotAddModalVisible = false;
      this.newAppointmentModalVisible = false;
      this.appointmentDetailsModalVisible = false;
      this.endErAppModalVisible = false;
      this.$store.commit(Mutation.SCHEDULE_SET_ONECLICK_ADD_MODAL, false);
      this.$store.commit(Mutation.SCHEDULE_SET_CURRENT_BLOCK, null);

      if (item) {
        this.$store.commit(Mutation.REGISTRATION_ADD_SCHEDULE, item);
        this.$store.commit(Mutation.SCHEDULE_ADD_PATIENT_VISIT, item);
      }
    },

    hideModalsAndReloadMany(items) {
      this.reload();
      this.newAppointmentModalVisible = false;
      this.appointmentDetailsModalVisible = false;
      this.$store.commit(Mutation.SCHEDULE_SET_ONECLICK_ADD_MODAL, false);
      this.$store.commit(Mutation.SCHEDULE_SET_CURRENT_BLOCK, null);

      if (items) {
        items.forEach((item) => {
          this.$store.commit(Mutation.REGISTRATION_ADD_SCHEDULE, item);
          this.$store.commit(Mutation.SCHEDULE_ADD_PATIENT_VISIT, item);
        });
      }
    },

    hideModals() {
      this.newAppointmentModalVisible = false;
      this.appointmentDetailsModalVisible = false;
      this.$store.commit(Mutation.SCHEDULE_SET_ONECLICK_ADD_MODAL, false);
      this.$store.commit(Mutation.SCHEDULE_SET_CURRENT_BLOCK, null);
    },

    /**
     * Domain conflict check. MC
     */
    domainConflict() {
      if (this.patient) {
        return this.patient.domainId != this.me.domainId;
      } else return false;
    },

    /**
     * History date check. MC
     * @param date Date to check in string/date format.
     */
    isHistory(date) {
      let now = moment().startOf("day");
      return moment(date).isBefore(now);
    },

    /**
     * Shows modal for timeless appointment creator. MC
     * @param day Day data.
     * @param block Block to focus on.
     */
    addTimeless(day, block) {
      if (day?.office?.isScheduleBlocked) {
        this.snackbar("Gabinet zablokowany dla rejestracji");
        return;
      }

      if (this.editorSlotsMode) {
        this.snackbar("Akcja zablokowana w trybie edycji slotów");
        return;
      }

      this.areaDetailsModalVisible = false;
      this.timelessOnlyMode = true;

      this.showDetailsOrNewAppointmentModal(day, block, true);

      // this.focusDay = day
      // this.focusBlock = block
      // this.newAppointmentModalVisible = true
    },

    /**
     * Shows area details modal.
     */
    showAreaDetails() {
      this.contextMenuVisible = false;

      if (this.focusDay && this.focusBlock) {
        if (this.focusBlock.isWorktime && this.focusBlock.worktimeArea) {
          this.areaDetailsModalVisible = true;
        } else {
          this.showDetailsOrNewAppointmentModal(this.focusDay, this.focusBlock);
        }
      }
    },

    /**
     * Shows area details modal.
     */
    showAreaDetailsMockup(day, block) {
      this.contextMenuVisible = false;
      this.focusDay = day;
      this.focusBlock = block;

      if (this.focusDay && this.focusBlock) {
        if (this.focusBlock.isWorktime && this.focusBlock.worktimeArea) {
          this.areaDetailsModalVisible = true;
        } else {
          this.showDetailsOrNewAppointmentModal(this.focusDay, this.focusBlock);
        }
      }
    },

    /**
     * Shows area details modal.
     */
    showContextMenu(e, day, block) {
      if (!this.editorMode && !this.editorSlotsMode) {
        this.contextMenuVisible = false;
        this.focusDay = day;
        this.focusBlock = block;
        this.contextMenuVisible = true;
        this.contextMenuStyle = `left: ${e.clientX}px;top: ${e.clientY}px;`;
      }

      e.preventDefault();
    },

    /**
     * Shows modal for appointment details, or appointment creator. MC
     * @param day Day data.
     * @param block Block to focus on.
     */
    showDetailsOrNewAppointmentModal(day, block, onlyTimeless) {
      if (!this.editorMode && !this.editorSlotsMode) {
        this.$store.commit(Mutation.REGISTRATION_AUTO_NAVIGATE, null);
        this.areaDetailsModalVisible = false;
        this.timelessOnlyMode = onlyTimeless ? true : false;
        this.focusDay = day;
        this.focusBlock = block;

        // Check if block has appointment.
        if (this.focusBlock && this.focusBlock.appointment) {
          this.appointmentDetailsModalVisible = true;
        } else if (this.readonly) {
          // do nothing!
        }

        // Check for worktime block.
        else if (
          this.notPersonal &&
          this.focusBlock &&
          this.focusBlock.isWorktime &&
          this.focusBlock.worktimeArea
        ) {
          // Check for domain conflict.
          if (this.domainConflict()) {
            this.dangerToast(
              "Pacjent zarejestrowany jest w innej domenie. Umówienie wizyty nie jest możliwe."
            );
          }

          // Check for history days.
          else if (this.focusBlock.isHistory) {
            this.dangerToast(
              "Brak możliwości umawiania wizyt w strefach historycznych."
            );
          }

          // Check for 'onlyWorktimeSchedule' prop in office object for day
          // IMPORTANT: NO ALERT IN CASE OF TIMEONLYMODE - which is set on true only from TicketMaster / TicketMasterVer2 components (as of 06.12.2023)
          else if (
            this.focusDay.office &&
            this.focusDay.office.onlyWorktimeSchedule &&
            this.timeOnlyMode === false
          ) {
            this.$buefy.dialog.alert({
              message:
                "<b>Brak możliwości umawiania wizyt w gabinetach IPOM.</b> Skonsultuj się z koordynatorem w celu umówienia wizyty.",
              type: "is-danger",
              scroll: "keep",
              hasIcon: true,
            });
          } else if (this.focusDay?.office?.isScheduleBlocked) {
            this.$buefy.dialog.alert({
              message:
                "<b>Brak możliwości umawiania wizyt w gabinetach zablokowanych dla rejestracji.</b>",
              type: "is-danger",
              scroll: "keep",
              hasIcon: true,
            });
          }

          // Seems legit.
          else {
            // TODO background/timess visits.
            if (
              !this.isBackground ||
              (this.isBackground && this.timelessOnly)
            ) {
              if (this.patient || this.rescheduleMode) {
                if (
                  this.rescheduleMode &&
                  this.focusDay &&
                  this.focusDay.office &&
                  this.focusDay.office.onlyWorktimeSchedule
                ) {
                  this.$buefy.dialog.alert({
                    message:
                      "<b>Brak możliwości przekładania wizyt w gabinetach IPOM.</b> Skonsultuj się z koordynatorem w celu umówienia wizyty.",
                    type: "is-danger",
                    scroll: "keep",
                    hasIcon: true,
                  });
                }

                // Exceptions for service area.
                else if (this.focusBlock.worktimeArea.area == 98) {
                  this.dangerToast(
                    "Brak możliwości umówienia wizyty - wybrano strefę serwisową"
                  );
                }

                // Exception for vacation area.
                else if (this.focusBlock.worktimeArea.area == 34) {
                  this.dangerToast(
                    "Brak możliwości umówienia wizyty - wybrano strefę urlopową"
                  );
                }

                // Area is good - can create appointment.
                else {
                  this.newAppointmentModalVisible = true;
                }
              }

              // Its ok, vehicle area, call creator.
              else if (this.focusBlock.worktimeArea.vehicle > 0) {
                this.newAppointmentModalVisible = true;
              }

              // TODO No patient, ask for search.
              else {
                this.$store.commit(
                  Mutation.SCHEDULE_SET_CURRENT_BLOCK,
                  this.focusBlock
                );
                this.$emit("search-patient");
              }
            }
          }
        }
      } else if (this.editorSlotsMode && block.worktimeArea) {
        this.showSlotAddModal(block);
      } else if (block.worktimeArea) {
        var copies = this.areasSelected.filter(
          (x) =>
            x.domainId == block.worktimeArea.domainId &&
            x.id == block.worktimeArea.id
        );

        if (copies.length > 0) {
          copies.forEach((el) => {
            let ind = this.areasSelected.indexOf(el);

            if (ind > -1) {
              this.areasSelected.splice(ind, 1);
            }
          });
        } else {
          let checkOfficeBlocked = false;
          if (day?.office?.isScheduleBlocked) {
            checkOfficeBlocked = true;
          }

          if (checkOfficeBlocked) {
            this.snackbar("Gabinet zablokowany dla rejestracji");
          } else {
            this.areasSelected.push(block.worktimeArea);
          }
        }
      }
    },

    /**
     * Returns line marker top style for provided line value. MC
     * @param item Current line number.
     */
    getLineStyle(item) {
      var top = item * 5 * this.pixelsPerMinute + 2;

      if (item % 12 == 0) {
        return `top: ${top}px;border-bottom: 2px solid rgba(125, 125, 125, 0.35) !important;width:calc(${this.timelinesWidth}px + 1.75rem);`;
      } else if (item % 3 == 0) {
        return `top: ${top}px;border-bottom: 1px dashed rgba(125, 125, 125, 0.35) !important;width:calc(${this.timelinesWidth}px + 1.75rem);`;
      } else if (this.show5minutes) {
        return `top: ${top}px;border-bottom: 1px dotted rgba(125, 125, 125, 0.2) !important;width:calc(${this.timelinesWidth}px + 1.75rem);`;
      } else {
        return "display: none;";
      }
    },

    /**
     * Returns line marker top style for provided line value. MC
     * @param item Current line number.
     */
    getLineStyle2(item) {
      var top = item * 5 * this.pixelsPerMinute - 2;

      if (item % 12 == 0) {
        return `top: ${top}px;border-bottom: 2px solid rgba(125, 125, 125, 0.35) !important;width:${this.timelinesWidth}px;`;
      } else if (item % 3 == 0) {
        return `top: ${top}px;border-bottom: 1px dashed rgba(125, 125, 125, 0.35) !important;width:${this.timelinesWidth}px;`;
      } else if (this.show5minutes) {
        return `top: ${top}px;border-bottom: 1px dotted rgba(125, 125, 125, 0.2) !important;width:${this.timelinesWidth}px;`;
      } else {
        return "display: none;";
      }
    },

    /**
     * Block drag started event. MC
     */
    dragStarted() {},

    /**
     * Block dropped event. MC
     */
    dragDropped() {},

    /**
     * Block dragged over other block event. MC
     */
    onDragOver() {},

    isIncluded(area, slot) {
      let aStart = moment(area.start);
      let aEnd = moment(area.stop);
      let included = slot.isSameOrAfter(aStart) && slot.isBefore(aEnd);

      return included;
    },

    /**
     * Recalculates block sizes and colors. MC
     */
    recalculateElementSizeAndStyle(hardmode) {
      if (!this.mounting) {
        // Save settings
        let settings = {
          hourFrom: this.hourFrom,
          hourTo: this.hourTo,
          showAppointmentTypeLabelOnBlocks:
            this.showAppointmentTypeLabelOnBlocks,
          pixelsPerMinute: this.pixelsPerMinute,
          showMyTasks: this.showMyTasks,
          showReversedBlockColors: this.showReversedBlockColors,
          show5minutes: this.show5minutes,
          fixedEnabled: this.fixedEnabled,
        };

        let settingsJson = JSON.stringify(settings);
        this.$cookies.set(
          Cookies.LOCALS_SCHEDULE2_SETTINGS.replace("{eid}", this.me.id),
          settingsJson
        );
      }

      let totalWidthForLines = 0;

      // let momentFrom = moment().set({ "hour": this.hourFrom, "minute": 0 });
      // let momentTo = moment().set({ "hour": this.hourTo, "minute": 0 });

      // this.timelinesCount = momentTo.diff(momentFrom, 'hours') * 12

      if (this.payload && this.payload.days) {
        // Iterate over downloaded days data.
        this.payload.days.forEach((element) => {
          // Minimum block width to prevent empty columns.
          let maxwidth = 96 * this.pixelsPerMinute;
          var taskMaxWidth = 36 * this.pixelsPerMinute;

          // Offset size to move columns based on display hours.
          let offset = 0;

          // Calculate offset based of first block in day.
          if (
            element.blocks.length > 0 ||
            (element.erSlots && element.erSlots.length > 0)
          ) {
            let firstBlock = moment(element.date).set({ hour: 4, minute: 0 });
            let baseMoment = moment(element.date).set({
              hour: this.hourFrom,
              minute: 0,
            });

            offset =
              firstBlock.diff(baseMoment, "minutes") * this.pixelsPerMinute;
          }

          // Calculate styles for each block in current day.
          if (element.blocks) {
            element.blocks.forEach((b) => {
              b.offset = offset;
              b.isHistory = this.isHistory(b.start);

              if (this.showAppointmentTypeLabelOnBlocks && b.appointment) {
                b.typeName = this.getAppName(b.appointment);
              }

              // Block height.
              let h = b.h * this.pixelsPerMinute;

              // Block width
              let w = b.w * this.pixelsPerMinute;

              // Block left position.
              let l = b.x * this.pixelsPerMinute;

              // Block top position with offset.
              let top = b.y * this.pixelsPerMinute + offset;

              top = top - 1;

              // Style for empty/disabled blocks.
              if (b.isEmpty) {
                b.lightColor = "transparent";
                b.style = `
                position: absolute;
                left: ${l}px;
                top: ${top}px;
                width: ${w}px;
                cursor: help;
                height: ${h}px;
                min-width: ${w}px;
                min-height: ${h}px;`
                  .replaceAll("  ", "")
                  .replaceAll("\n", "");
              }

              // Style for worktime areas.
              else if (b.isWorktime) {
                let opc = 1;

                if (this.filters && this.filters.areaFilter >= 0) {
                  if (
                    b.worktimeArea &&
                    b.worktimeArea.area != this.filters.areaFilter
                  ) {
                    opc = 0.2;
                  }
                }

                b.style = `
                font-size: calc(0.28rem * ${this.pixelsPerMinute});
                line-height: calc(0.28rem * ${this.pixelsPerMinute});
                background: ${b.lightColor};
                color: ${b.textColor};
                opacity: ${opc};
                position: absolute;
                border-top: 1px solid ${b.textColor};
                cursor: ${this.personal ? "default" : "copy"};
                left: ${l}px;
                top: ${top}px;
                width: ${w}px;
                height: ${h}px;
                min-width: ${w}px;
                min-height: ${h}px;`
                  .replaceAll("  ", "")
                  .replaceAll("\n", "");

                // if (b.worktimeArea && b.worktimeArea.isCito) {
                //   b.style += "border-right: 2px solid red;"
                // }
              }

              // Style for appointments.
              else {
                // Max right point.
                let maxX = l + w;

                // Increase column width if block is wider.
                if (maxX > maxwidth) {
                  maxwidth = maxX;
                }

                if (b.appointment) {
                  if (
                    (b.appointment.state > 4 && b.appointment.state < 100) ||
                    b.isHistory
                  ) {
                    b.darkColor = b.darkColor + "77";
                    b.lightColor = b.lightColor + "77";
                    b.textColor = b.textColor + "77";
                  }
                }

                let opacity = "1";
                let background = this.showReversedBlockColors
                  ? b.darkColor
                  : b.lightColor;

                if (b.appointment.state == 100) {
                  background = `repeating-linear-gradient(-45deg, ${b.darkColor}, ${b.darkColor} 10px, ${b.lightColor} 10px, ${b.lightColor} 20px)`;
                }

                if (b.appointment) {
                  if (b.appointment.state < 100) {
                    if (b.appointment.state > 4) {
                      //opacity = '0.6'
                    }

                    if (b.appointment.state > 5) {
                      background = `repeating-linear-gradient(45deg, ${b.darkColor}, ${b.darkColor} 10px, ${b.lightColor} 10px, ${b.lightColor} 20px)`;
                    }
                  }

                  if (this.schedulesStore && this.schedulesStore.length > 0) {
                    let same = this.schedulesStore.find(
                      (x) => x.id === b.appointment.id
                    );

                    if (same) {
                      //background = `radial-gradient(${b.darkColor}, ${b.lightColor});`
                      b.isNew = true;
                    }
                  }
                }

                b.maxInsideHeightStyle = `height: ${h}px;max-height: ${h}px;`;

                b.style = `
                opacity: ${opacity};
                font-size: calc(0.28rem * ${this.pixelsPerMinute});
                line-height: calc(0.28rem * ${this.pixelsPerMinute});
                font-weight: bold;
                border-style: solid;
                border-color: ${
                  this.showReversedBlockColors ? b.lightColor : b.darkColor
                };
                border-width: 1px 1px 0 1px;
                border-radius: 0px;
                background: ${background};
                color: ${b.textColor};
                position: absolute;
                cursor: pointer;
                left: ${l}px;
                top: ${top}px;
                width: ${w}px;
                height: ${h}px;
                min-width: ${w}px;
                min-height: ${h}px;`
                  .replaceAll("  ", "")
                  .replaceAll("\n", "");
              }
            });
          }

          // Calculate styles for mockup/background blocks.
          if (element.mockupBlocks) {
            element.mockupBlocks.forEach((b) => {
              // Block height.
              let h = b.h * this.pixelsPerMinute;

              // Block width.
              let w = b.w * this.pixelsPerMinute;

              // Block left position.
              let l = b.x * this.pixelsPerMinute;

              // Block right position.
              let top = b.y * this.pixelsPerMinute + offset;

              top = top - 1;

              // Max right point.
              let maxX = l + w;

              // Increase column width if block is wider.
              if (maxX > maxwidth) {
                maxwidth = maxX;
              }

              b.isBackground = true;

              let wbg = b.lightColor;

              // Style for background empty block.
              if (b.isEmpty) {
                b.style = `
                position: absolute;
                left: ${l}px;
                top: ${top}px;
                width: ${w}px;
                height: ${h}px;
                min-width: ${w}px;
                overflow-x: visible;
                overflow-y: hidden;
                min-height: ${h}px;`
                  .replaceAll("  ", "")
                  .replaceAll("\n", "");
              }

              // Style for background worktime areas.
              else if (b.isWorktime) {
                if (b.worktimeArea) {
                  switch (b.worktimeArea.style) {
                    case 1:
                      wbg = `repeating-linear-gradient(45deg, ${b.darkColor}, ${b.darkColor} 10px, ${b.lightColor} 10px, ${b.lightColor} 20px)`;
                      break;
                    case 2:
                      wbg = b.darkColor;
                      break;
                  }
                }

                let opc = 1;

                if (this.filters && this.filters.areaFilter >= 0) {
                  if (
                    b.worktimeArea &&
                    b.worktimeArea.area != this.filters.areaFilter
                  ) {
                    opc = 0.2;
                  }
                }

                b.style = `
                font-size: calc(0.28rem * ${this.pixelsPerMinute});
                line-height: calc(0.28rem * ${this.pixelsPerMinute});
                background: ${wbg};
                opacity: ${opc};
                color: ${b.textColor};
                position: absolute;
                border-top: 1px solid ${b.textColor};
                left: ${l}px;
                top: ${top}px;
                width: ${w}px;
                height: ${h}px;  
                overflow-x: visible;
                overflow-y: hidden;
                min-width: ${w}px;
                min-height: ${h}px;`
                  .replaceAll("  ", "")
                  .replaceAll("\n", "");
              }
            });
          }

          // Calculate styles for mockup/background blocks.
          if (element.erSlots) {
            element.erSlots.forEach((b) => {
              // Block height.
              let h = b.h * this.pixelsPerMinute;

              // Block width.
              let w = b.w * this.pixelsPerMinute;

              // Block left position.
              let l = b.x * this.pixelsPerMinute;

              // Block right position.
              let top = b.y * this.pixelsPerMinute + offset;

              top = top - 1;

              // Max right point.
              let maxX = l + w;

              // Increase column width if block is wider.
              if (maxX > maxwidth) {
                maxwidth = maxX;
              }

              b.isBackground = true;

              let wbg = "transparent";
              let bbg = "transparent";

              b.textColor = "grey";

              if (b.error < 2) {
                bbg = b.textColor;
              } else if (this.editorSlotsMode) {
                bbg = b.textColor;
              }

              b.style = `
              font-size: calc(0.28rem * ${this.pixelsPerMinute});
              line-height: calc(0.28rem * ${this.pixelsPerMinute});
              background: ${wbg};
              color: ${b.textColor};
              position: absolute;
              border: 1px dashed ${bbg};
              pointer-events: ${this.editorSlotsMode ? "auto" : "none"};
              left: ${l}px;
              top: ${top}px;
              width: ${w}px;
              height: ${h}px;  
              overflow-x: visible;
              overflow-y: hidden;
              text-align: right;
              min-width: ${w}px;
              min-height: ${h}px;`
                .replaceAll("  ", "")
                .replaceAll("\n", "");
            });
          }

          // Calculate styles for each block in current day.
          if (element.tasks) {
            element.tasks.forEach((b) => {
              b.isHistory = this.isHistory(b.start);

              // Block height.
              let h = b.h * this.pixelsPerMinute;

              // Block width
              let w = b.w * this.pixelsPerMinute;

              // Block left position.
              let l = b.x * this.pixelsPerMinute;

              // Block top position with offset.
              let top = b.y * this.pixelsPerMinute + offset;

              top = top - 1;

              // Max right point.
              let maxX = l + w;

              // Increase column width if block is wider.
              if (maxX > taskMaxWidth) {
                taskMaxWidth = maxX;
              }

              if (b.task) {
                b.darkColor = "#003366";
                b.lightColor = "#3366cc";
                b.textColor = "#fff";
              }

              let background = this.showReversedBlockColors
                ? b.darkColor
                : b.lightColor;

              b.style = `
                font-size: calc(0.28rem * ${this.pixelsPerMinute});
                font-weight: bold;
                line-height: calc(0.28rem * ${this.pixelsPerMinute});
                border-style: solid;
                padding: 1px;
                border-color: ${
                  this.showReversedBlockColors ? b.lightColor : b.darkColor
                };
                border-width: 1px 1px 1px 1px;
                border-radius: 5px;
                background: ${background};
                color: ${b.textColor};
                position: absolute;
                cursor: pointer;
                left: ${l}px;
                top: ${top}px;
                width: ${w}px;
                height: ${h}px;
                min-width: ${w}px;
                min-height: ${h}px;`
                .replaceAll("  ", "")
                .replaceAll("\n", "");
            });
          }

          if (this.editorMode && element.slots && element.slots.length > 0) {
            if (this.slotsSelectedLength > 0 || hardmode) {
              // let indexes = this.slotsSelected.map(x => x.index)
              // var minIndex = Math.min(...indexes)
              // var maxIndex = Math.max(...indexes)

              element.slots.forEach((b) => {
                if (hardmode) {
                  // Block height.
                  b.dh = b.h * this.pixelsPerMinute;

                  // Block width
                  b.dw = b.w * this.pixelsPerMinute;

                  // Block left position.
                  b.dl = b.x * this.pixelsPerMinute;

                  // Block top position with offset.
                  b.dtop = b.y * this.pixelsPerMinute;
                }

                let backSlot = "transparent";

                if (this.selectedDays.includes(element.id)) {
                  let existing = this.slotsSelected.find(
                    (x) => x.index == b.index
                  );

                  if (existing) {
                    backSlot = "#26a69a";
                  }
                }

                b.style = `
                border: 0px;
                opacity: 0.5;
                position: absolute;
                box-shadow: inset 0px 0px 1px 0px #000 !important;
                left: ${b.dl}px;
                background: ${backSlot};
                top: ${b.dtop}px;
                width: ${b.dw}px;
                height: ${b.dh}px;
                min-width: ${b.dw}px;
                min-height: ${b.dh}px;`
                  .replaceAll("  ", "")
                  .replaceAll("\n", "");
              });
            }
          } else {
            // // EDITOR DATA
            // element.slots = []
            // let slotCurrent = moment(element.date).set({ "hour": this.hourFrom, "minute": 0 });
            // let slotStop = moment(element.date).set({ "hour": this.hourTo, "minute": 0 });
            // for (let i = 0; i < 216; i++) {
            //   if (slotCurrent.isBefore(slotStop)) {
            //     let slotEnd = moment(slotCurrent).add(5, 'm')
            //     // Add block
            //     let b = {
            //       id: `${index}_${i}_${element.date}`,
            //       did: element.id,
            //       cid: `${index}_${element.date}`,
            //       index: i,
            //       date: element.date,
            //       x: 0,
            //       y: 5 * i,
            //       w: 86,
            //       h: 5,
            //       start: slotCurrent.format('YYYY-MM-DDTHH:mm:ssZ'),
            //       end: slotEnd.format('YYYY-MM-DDTHH:mm:ssZ'),
            //     }
            //     let border = '0px'
            //     if (element.worktimes) {
            //       let occupierItems = element.worktimes.find(s => this.isIncluded(s, slotCurrent))
            //       if (occupierItems) {
            //         b.occupied = true
            //       }
            //     }
            //     slotCurrent = slotEnd
            //     // Block height.
            //     b.dh = b.h * this.pixelsPerMinute
            //     // Block width
            //     b.dw = b.w * this.pixelsPerMinute
            //     // Block left position.
            //     b.dl = b.x * this.pixelsPerMinute
            //     // Block top position with offset.
            //     b.dtop = b.y * this.pixelsPerMinute
            //     b.style = `
            //     border: 0px;
            //     cursor: pointer;
            //     position: absolute;
            //     border-left: ${border};
            //     left: ${b.dl}px;
            //     box-shadow: inset 0px 0px 1px 0px #000 !important;
            //     left: ${b.dl}px;
            //     top: ${b.dtop}px;
            //     width: ${b.dw}px;
            //     height: ${b.dh}px;
            //     min-width: ${b.dw}px;
            //     min-height: ${b.dh}px;`.replaceAll('  ', '').replaceAll('\n', '')
            //     element.slots.push(b)
            //     this.everyslot.push(b)
            //   }
            // }
          }

          if (element.timeless) {
            element.timeless.forEach((b) => {
              b.typeName = this.getAppName(b);
            });
          }

          var totalWidth = maxwidth + taskMaxWidth;

          if (this.showMyTasks) {
            element.boxStyle =
              `min-width: ${totalWidth}px;width: ${totalWidth}px;`
                .replaceAll("  ", "")
                .replaceAll("\n", "");
          } else {
            element.boxStyle = `min-width: ${maxwidth}px;width: ${maxwidth}px;`
              .replaceAll("  ", "")
              .replaceAll("\n", "");
          }

          // Calculate max day column height based on timeline markers displayed.
          let maxHeight = (this.markers.length + 1) * 15 * this.pixelsPerMinute;

          // Parent day schedule column style.
          element.scheduleColumnStyle = `
          position: relative;
          overflow: hidden;
          height: ${maxHeight}px;
          min-height: ${maxHeight}px;
          min-width: ${maxwidth}px;width: ${maxwidth}px;max-width: ${maxwidth}px;`
            .replaceAll("  ", "")
            .replaceAll("\n", "");
          // Parent day column style.

          // Parent day task column style.
          element.taskColumnStyle = `
          position: relative;
          height: ${maxHeight}px;
          min-height: ${maxHeight}px;
          min-width: 6rem;
          width: 6rem;
          min-width: ${taskMaxWidth}px;width: ${taskMaxWidth}px;`
            .replaceAll("  ", "")
            .replaceAll("\n", "");

          element.headerFixedStyle = `
          position:fixed;
          top:-5px;
          left:100px;
          z-index:10;
          min-width: ${maxwidth}px;width: ${maxwidth}px;max-width: ${maxwidth}px;`
            .replaceAll("  ", "")
            .replaceAll("\n", "");

          totalWidthForLines += maxwidth + 8;
        });

        this.timelinesWidth = totalWidthForLines;

        // Update time marker line.
        this.updateTimeMark();
      }
    },

    refillActiveSlots() {
      if (this.editorActive && this.payload && this.payload.days) {
        this.payload.days.forEach((element, index) => {
          // EDITOR DATA
          element.slots = [];

          if (this.selectedDays.includes(element.id)) {
            let slotCurrent = moment(element.date).set({
              hour: this.hourFrom,
              minute: 0,
            });
            let slotStop = moment(element.date).set({
              hour: this.hourTo,
              minute: 0,
            });

            for (let i = 0; i < 216; i++) {
              if (slotCurrent.isBefore(slotStop)) {
                let slotEnd = moment(slotCurrent).add(5, "m");

                // Add block
                let b = {
                  id: `${index}_${i}_${element.date}`,
                  did: element.id,
                  cid: `${index}_${element.date}`,
                  index: i,
                  date: element.date,
                  x: 0,
                  y: 5 * i,
                  w: 86,
                  h: 5,
                  start: slotCurrent.format("YYYY-MM-DDTHH:mm:ssZ"),
                  end: slotEnd.format("YYYY-MM-DDTHH:mm:ssZ"),
                };

                let border = "0px";

                if (element.worktimes) {
                  let occupierItems = element.worktimes.find((s) =>
                    this.isIncluded(s, slotCurrent)
                  );

                  if (occupierItems) {
                    b.occupied = true;
                  }
                }

                slotCurrent = slotEnd;

                // Block height.
                b.dh = b.h * this.pixelsPerMinute;

                // Block width
                b.dw = b.w * this.pixelsPerMinute;

                // Block left position.
                b.dl = b.x * this.pixelsPerMinute;

                // Block top position with offset.
                b.dtop = b.y * this.pixelsPerMinute;

                b.style = `
                border: 0px;
                cursor: pointer;
                position: absolute;
                border-left: ${border};
                left: ${b.dl}px;
                box-shadow: inset 0px 0px 1px 0px #000 !important;
                left: ${b.dl}px;
                top: ${b.dtop}px;
                width: ${b.dw}px;
                height: ${b.dh}px;
                min-width: ${b.dw}px;
                min-height: ${b.dh}px;`
                  .replaceAll("  ", "")
                  .replaceAll("\n", "");

                element.slots.push(b);
                this.everyslot.push(b);
              }
            }
          }
        });
      }
    },

    /**
     * Recalculates block sizes and colors. MC
     */
    recalculateSlotStyles(hardmode) {
      if (this.payload && this.payload.days) {
        // Iterate over downloaded days data.
        this.payload.days.forEach((element, index) => {
          if (
            this.editorMode &&
            this.selectedDays.includes(element.id) &&
            element.slots &&
            element.slots.length > 0
          ) {
            if (this.slotsSelectedLength > 0 || hardmode) {
              // let indexes = this.slotsSelected.map(x => x.index)
              // var minIndex = Math.min(...indexes)
              // var maxIndex = Math.max(...indexes)

              element.slots.forEach((b) => {
                if (hardmode) {
                  // Block height.
                  b.dh = b.h * this.pixelsPerMinute;

                  // Block width
                  b.dw = b.w * this.pixelsPerMinute;

                  // Block left position.
                  b.dl = b.x * this.pixelsPerMinute;

                  // Block top position with offset.
                  b.dtop = b.y * this.pixelsPerMinute;
                }

                let backSlot = "transparent";

                if (this.selectedDays.includes(element.id)) {
                  let existing = this.slotsSelected.find(
                    (x) => x.index == b.index
                  );

                  if (existing) {
                    backSlot = "red";
                  }
                }

                b.style = `
                  border: 0px;
                  opacity: 0.5;
                  box-shadow: inset 0px 0px 1px 0px #000!important;
                  cursor: pointer;
                  position: absolute;
                  left: ${b.dl}px;
                  background: ${backSlot};
                  top: ${b.dtop}px;
                  width: ${b.dw}px;
                  height: ${b.dh}px;
                  min-width: ${b.dw}px;
                  min-height: ${b.dh}px; `
                  .replaceAll("  ", "")
                  .replaceAll("\n", "");
              });
            }
          } else {
            // EDITOR DATA
            element.slots = [];

            let slotCurrent = moment(element.date).set({
              hour: this.hourFrom,
              minute: 0,
            });
            let slotStop = moment(element.date).set({
              hour: this.hourTo,
              minute: 0,
            });

            for (let i = 0; i < 216; i++) {
              if (slotCurrent.isBefore(slotStop)) {
                let slotEnd = moment(slotCurrent).add(5, "m");

                // Add block
                let b = {
                  id: `${index}_${i}_${element.date} `,
                  did: element.id,
                  cid: `${index}_${element.date} `,
                  index: i,
                  date: element.date,
                  x: 0,
                  y: 5 * i,
                  w: 86,
                  h: 5,
                  start: slotCurrent.format("YYYY-MM-DDTHH:mm:ssZ"),
                  end: slotEnd.format("YYYY-MM-DDTHH:mm:ssZ"),
                };

                slotCurrent = slotEnd;

                // Block height.
                b.dh = b.h * this.pixelsPerMinute;

                // Block width
                b.dw = b.w * this.pixelsPerMinute;

                // Block left position.
                b.dl = b.x * this.pixelsPerMinute;

                // Block top position with offset.
                b.dtop = b.y * this.pixelsPerMinute;

                b.style = `
                  border: 0px;
                  cursor: pointer;
                  position: absolute;
                  box-shadow: inset 0px 0px 1px 0px #000!important;
                  left: ${b.dl}px;
                  left: ${b.dl}px;
                  top: ${b.dtop}px;
                  width: ${b.dw}px;
                  height: ${b.dh}px;
                  min-width: ${b.dw}px;
                  min-height: ${b.dh}px; `
                  .replaceAll("  ", "")
                  .replaceAll("\n", "");

                element.slots.push(b);
                this.everyslot.push(b);
              }
            }
          }
        });

        // Update time marker line.
        this.updateTimeMark();
      }
    },

    /**
     * Reloads schedule data. MC
     * @param hard Hard reload indicator. With hard reload previous data is cleared before API call.
     */
    reload(hard) {
      this.areaDetailsModalVisible = false;
      this.tooltipA = null;
      this.tooltipB = null;
      this.tooltipVisible = false;

      if (this.filters) {
        this.areasSelected = [];
        this.loading = true;

        // Clear data if hard realod.
        if (hard) {
          this.clearDataHardReload();
          this.payload = { days: [] };
        }

        // Cancel reload if bad search type and no employee.
        if (this.filters.searchType === 0 && this.filters.employee === -1) {
          this.loading = false;
          this.$emit("error", "Brak zdefiniowanych filrtów wyszukiwania.");
        }

        // Proceed with reload.
        else {
          this.filters.includeTasksForWorker = this.showMyTasks
            ? this.me.id
            : -1;

          // API call.
          this.$store
            .dispatch(Action.S4_GET_SCHEDULE, this.filters)
            .then((response) => {
              this.wrapDays(response.data);
            })
            .catch((error) => {
              this.loading = false;
              this.apiProblem(error);
              this.$emit("error");
              this.payload = { days: [] };
            });
        }
      }
    },

    wrapDays(daysData) {
      // let patientIds = []
      // let allapps = []
      let days = daysData.days;
      this.everyslot.length = 0;

      if (days == null) {
        this.dangerSnackbar(
          "Wystąpił problem podczas pobierania terminarza. Tablica dni jest pusta."
        );
      } else if (days.length == 0) {
        this.snackbar(
          "Nie znaleziono stref w terminarzu dla wybranych kryteriów."
        );
      } else if (days) {
        // Wrap data for each day.
        days.forEach((element, index) => {
          let daypats = [];

          // Get office.
          if (element.subjectCode) {
            element.office = this.getOffice(element.subjectCode);
          }

          // Get office.
          if (element.office) {
            this.findFacilityForDay(element.office);
            element.id = `day_${index}_${element.office.id}`;
          } else {
            element.id = `day_${index}`;
          }

          let dateStr = element.date,
            date = moment(dateStr),
            time = moment();

          date.set({
            hour: time.get("hour"),
            minute: time.get("minute"),
            second: time.get("second"),
          });

          element.dateObject = date.toDate();

          // Convert date to moment.
          element.moment = moment(element.date);

          // Minutes unoccupied.
          let timeLeft = 0;

          // Minutes occupied.
          let timeTaken = 0;

          // Add apps and patient ids from timeless.
          // if (element.blocks) {
          //   element.timeless.forEach((b) => {
          //     allapps.push(b);
          //     if (!patientIds.includes(b.patientId)) {
          //       patientIds.push(b.patientId)
          //     }
          //   })
          // }

          if (element.blocks) {
            element.blocks.forEach((b, index) => {
              b.index = `blocks_${index}`;
              b.tooltip = "";

              if (b.name) {
                b.tooltip = `<p><b>${b.name}</b></p> `;
              }

              b.tooltip += `<p>${this.mTime(b.start)} `;

              // Add apps and patient ids from blocks.
              if (b.appointment) {
                // allapps.push(b.appointment);

                // if (!patientIds.includes(b.appointment.patientId)) {
                //   patientIds.push(b.appointment.patientId)
                // }

                if (!daypats.includes(b.appointment.patientId)) {
                  daypats.push(b.appointment.patientId);
                }

                if (b.appointment.payerType == 0) {
                  b.tooltip += ", Prywatna";
                } else if (b.appointment.payerType == 1) {
                  b.tooltip += ", NFZ";
                } else if (b.appointment.payerType == 2) {
                  b.tooltip += ", MZ";
                }
              }

              b.tooltip += `</p>`;

              // Setup facility data for block.
              b.facility = this.clinic;

              // Sum unoccupied time.
              if (b.isWorktime) {
                let diff = moment(b.end).diff(moment(b.start), "minutes");
                b.timeLeft = diff;
                timeLeft += diff;

                if (b.worktimeArea && b.worktimeArea.isCito) {
                  b.name += ` <b class="has-text-danger">CITO</b>`;
                }
              } else if (b.appointment) {
                if (b.appointment.startEwus) {
                  b.appointment.sEwus = JSON.parse(b.appointment.startEwus);
                }

                if (b.appointment.lastEwus) {
                  b.appointment.lEwus = JSON.parse(b.appointment.lastEwus);
                }

                // Sum occupied time.
                let diff = moment(b.end).diff(moment(b.start), "minutes");
                timeTaken += diff;
              }
            });
          }

          if (element.mockupBlocks) {
            element.mockupBlocks.forEach((b, index) => {
              b.index = `mockupBlocks_${index}`;
              b.tooltip = "";

              if (b.name) {
                b.tooltip = `<p><b>${b.name}</b></p>`;
              }

              b.tooltip += `<p>${this.mTime(b.start)} `;

              // Add apps and patient ids from blocks.
              if (b.appointment) {
                // allapps.push(b.appointment);

                // if (!patientIds.includes(b.appointment.patientId)) {
                //   patientIds.push(b.appointment.patientId)
                // }

                if (!daypats.includes(b.appointment.patientId)) {
                  daypats.push(b.appointment.patientId);
                }

                if (b.appointment.payerType == 0) {
                  b.tooltip += ", Prywatna";
                } else if (b.appointment.payerType == 1) {
                  b.tooltip += ", NFZ";
                } else if (b.appointment.payerType == 2) {
                  b.tooltip += ", MZ";
                }
              }

              b.tooltip += `</p > `;

              // Setup facility data for block.
              b.facility = this.clinic;
            });
          }

          // Wrap stats for day.
          element.stats = {
            patients: daypats.length,
            appointments: element.appointments.length,
            timeless: element.timeless.length,
            timeLeft: timeLeft,
            timeTaken: timeTaken,
          };
        });
      }

      this.loading = false;

      // Display wrapped data.
      this.payload = daysData;

      // Recalculate block styles.
      this.recalculateElementSizeAndStyle();
      this.redrawLines();
      this.refillActiveSlots();

      // Signal the world!
      this.$emit("response");

      // No idea what is this :)
      this.changeHidden = false;

      // Trigger headers key.
      this.fixedHeader++;
    },

    /**
     * Marks patient waiting.
     * @param value Patient waiting value.
     */
    sendToEregistration(again) {
      if (this.focusBlock && this.focusBlock.appointment) {
        var message = again
          ? "Czy na pewno zarejestrować tę wizytę ponownie w E-Rejestracji?"
          : "Czy na pewno zarejestrować tę wizytę w E-Rejestracji?";
        var type = again ? "is-info" : "is-warning";

        this.$buefy.dialog.confirm({
          message: message,
          cancelText: "Anuluj",
          type: type,
          scroll: "keep",
          hasIcon: true,
          confirmText: "Tak, zarejestruj",
          onConfirm: () => {
            this.slotsLoading = true;
            this.$store
              .dispatch(
                Action.CGATE_EREGISTER_APP,
                this.focusBlock.appointment.id
              )
              .then(() => {
                this.slotsLoading = false;
                this.hideModalsAndReload();
                // cool
              })
              .catch((error) => {
                this.slotsLoading = false;
                this.focusBlock.appointment.patientWaiting = false;
                this.apiProblem(error);
              });
          },
        });
      }
    },

    /**
     * Marks patient waiting.
     * @param value Patient waiting value.
     */
    markPatientWaiting(value) {
      if (this.focusBlock && this.focusBlock.appointment) {
        if (value) {
          this.$buefy.dialog.confirm({
            message: `Czy na pewno oznaczyć oczekującego pacjenta ? `,
            cancelText: "Anuluj",
            type: "is-info",
            scroll: "keep",
            hasIcon: true,
            confirmText: "Tak, pacjent czeka w poczekalni",
            onConfirm: () => {
              this.focusBlock.appointment.patientWaiting = true;
              this.$store
                .dispatch(
                  Action.S3_MARK_PATIENT_WAITING,
                  this.focusBlock.appointment
                )
                .then(() => {
                  // cool
                })
                .catch((error) => {
                  this.focusBlock.appointment.patientWaiting = false;
                  this.apiProblem(error);
                });
            },
          });
        } else {
          this.$buefy.dialog.confirm({
            message: `Czy na pewno anulować oznaczenie oczekującego pacjenta ? `,
            cancelText: "Anuluj",
            type: "is-danger",
            scroll: "keep",
            hasIcon: true,
            confirmText: "Tak, pacjenta nie ma w poczekalni",
            onConfirm: () => {
              this.focusBlock.appointment.patientWaiting = false;
              this.$store
                .dispatch(
                  Action.S3_MARK_PATIENT_WAITING,
                  this.focusBlock.appointment
                )
                .then(() => {
                  // cool
                })
                .catch((error) => {
                  this.focusBlock.appointment.patientWaiting = true;
                  this.apiProblem(error);
                });
            },
          });
        }
      }
    },

    /**
     * Marks patient waiting.
     * @param value Patient waiting value.
     */
    endErApp() {
      if (this.focusBlock && this.focusBlock.appointment && this.erEndReason) {
        this.$buefy.dialog.confirm({
          title: "E-Rejestracja",
          message: `Czy na pewno skreślić wizytę w E-Rejestracji?`,
          cancelText: "Wróć",
          type: "is-info",
          scroll: "keep",
          hasIcon: true,
          confirmText: "Tak, skreślam",
          onConfirm: () => {
            this.focusBlock.appointment.reason = this.erEndReason;
            this.$store
              .dispatch(Action.ER_END_APP_08, this.focusBlock.appointment)
              .then(() => {
                // cool
                this.erEndReason = null;
                this.hideModalsAndReload();
              })
              .catch((error) => {
                this.apiProblem(error);
              });
          },
        });
      }
    },

    /**
     * Recalculates timetable block height. MC
     */
    recalculateTimetableBlockStyle() {
      var height = this.getElementHeight(15);
      this.timetableBlockStyle = `min-height: ${height}px; width: 2.2rem;`;
    },

    /**
     * Calculates element height in pixels. MC
     * @param minutes Length of appointment in minutes.
     */
    getElementHeight(minutes) {
      return minutes * this.pixelsPerMinute;
    },

    /**
     * Recalculates timeline markers based on hourFrom and hourTo. MC
     */
    recalculateTimetable() {
      this.markers.length = 0;

      for (let index = this.hourFrom; index < this.hourTo; index++) {
        this.markers.push({
          value: index,
          text: `${index}:00`,
          class: "has-text-weight-bold",
        });
        this.markers.push({ value: index, text: `${index}:15`, class: "" });
        this.markers.push({ value: index, text: `${index}:30`, class: "" });
        this.markers.push({ value: index, text: `${index}:45`, class: "" });
      }

      let momentFrom = moment().set({ hour: this.hourFrom, minute: 0 });
      let momentTo = moment().set({ hour: this.hourTo, minute: 0 });

      this.redrawLines();

      this.timelinesCount = momentTo.diff(momentFrom, "hours") * 12;
    },

    redrawLines() {
      if (this.$refs.scheduleContainer) {
        let tm0 = this.$refs.scheduleContainer.clientWidth;

        if (this.tm0 > this.timelinesWidth) {
          this.timelinesWidth = tm0;
        }

        if (this.timelinesWidth < 1) {
          this.timelinesWidth = window.innerWidth * 0.78;
        }
      }
    },

    reschedule() {
      if (this.focusBlock && this.focusBlock.appointment) {
        this.$store.commit(Mutation.APPOINTMENT_SIMPLE_FOCUS, this.focusBlock);
        this.toast("Wybierz nowy termin na terminarzu");
      }
      this.contextMenuVisible = false;
    },

    /**
     * Translate day name. MC
     * @param item Day name in english.
     */
    translateDay(item) {
      switch (item) {
        case "Monday":
          return "Poniedziałek";
        case "Tuesday":
          return "Wtorek";
        case "Wednesday":
          return "Środa";
        case "Thursday":
          return "Czwartek";
        case "Friday":
          return "Piątek";
        case "Saturday":
          return "Sobota";
        case "Sunday":
          return "Niedziela";
        default:
          return item;
      }
    },

    /**
     * Gets day name from date.
     * @param date Date to get day name from.
     */
    getDayVerb(date) {
      let day = moment(date).format("dddd");
      return this.translateDay(day);
    },

    /**
     * Gets office object. MC
     * @param id Office ID.
     */
    getOffice(id) {
      return this.allOffices.find((c) => c.id == id);
    },

    /**
     * Gets employee object. MC
     * @param id Worker ID.
     */
    getWorker(id) {
      if (this.employees && id > 0) {
        return this.employees.find((e) => e.id === id);
      } else {
        // Return mockup object if not found.
        return {
          lastName: "",
          firstName: "Brak pracownika",
          id: -1,
          type: -1,
        };
      }
    },

    /**
     * Gets employee name only. MC
     * @param id Worker ID.
     */
    getWorkerNameOnly(id) {
      if (this.employees) {
        let emp = this.employees.find((e) => e.id === id);

        if (emp) {
          return emp.firstName + " " + emp.lastName;
        } else {
          return "Brak pracownika";
        }
      } else {
        // Return mockup object if not found.
        return "Brak informacji";
      }
    },

    scrollRight() {
      let val = this.$refs.scroller.scrollLeft + 200;
      this.$refs.scroller.scroll({
        left: val,
        behavior: "smooth",
      });
    },

    scrollLeft() {
      let val = this.$refs.scroller.scrollLeft - 200;
      this.$refs.scroller.scroll({
        left: val,
        behavior: "smooth",
      });
    },

    scrollRightZero() {
      let val = 99999;
      this.$refs.scroller.scroll({
        left: val,
        behavior: "smooth",
      });
    },

    scrollLeftZero() {
      let val = 0;
      this.$refs.scroller.scroll({
        left: val,
        behavior: "smooth",
      });
    },

    changeDayRange(amount) {
      if (this.filters) {
        if (
          this.firstAvailableMode &&
          this.payload &&
          this.payload.days &&
          this.payload.days.length > 0
        ) {
          this.filters.afterDay = moment(this.payload.days[0].date)
            .add(amount - 1, "days")
            .add(4, "hours")
            .format("YYYY-MM-DDTHH:mm:ss");
          this.reload(true);
        } else {
          this.filters.afterDay = null;
          this.$emit("scroll-days", amount);
        }
      }
    },

    changeAppTypeVisibility(val) {
      this.$store.commit(Mutation.LOCALS_SCHEDULE_APP_TYPE_VISIBIILITY, val);
      this.$cookies.set(
        Cookies.LOCALS_SCHEDULE_APP_TYPE_VISIBILITY.replace(
          "{eid}",
          this.me.id
        ),
        val
      );
    },

    handleScroll() {
      this.scrolling = true;
      this.fixedVisible = false;
    },

    handleScrollEnd() {
      this.scrollTop = window.scrollY;
      this.scrolling = false;
      this.fixedVisible = this.fixedEnabled && window.scrollY > 150;
    },

    handleXScroll(ev) {
      this.fixedLeft = ev.target.scrollLeft;
    },

    searchPatientEvent(payload) {
      this.$emit("search-patient", payload);
    },

    getWorkerName(item, fullInfo) {
      let officeInfo = "Brak gabinetu";
      let officeCleanInfo = "Brak gabinetu";
      let employName = "Brak pracownika";
      let employCleanName = "Brak pracownika";

      if (item.subjectCode) {
        let office = this.getOffice(item.subjectCode);

        if (office) {
          officeInfo = /*(office.shortName ? `< b class="has-text-secondary" > ${ office.shortName }</b > <br />` : '') +*/ ` ${office.name} `;
          officeCleanInfo =
            (office.shortName ? `${office.shortName}, ` : "") +
            `${office.name} `;
        } else {
          officeInfo = `Nie znaleziono gabinetu(${office.id})`;
        }
      }

      if (item.subjectId > 0) {
        let emp = this.employees.find((e) => e.id === item.subjectId);

        if (emp) {
          employName = `< b class="has-text-secondary" > ${emp.lastName} ${emp.firstName}</b > `;
          employCleanName = `${emp.lastName} ${emp.firstName} `;
        } else {
          employName = `Nie znaleziono pracownika(${emp.id})`;
        }
      }

      if (fullInfo) {
        return `${officeCleanInfo}, ${employCleanName} `;
      } else {
        if (item.subjectCode) {
          return officeInfo;
        } else {
          return employName;
        }
      }
    },

    emitCreate(payload) {
      this.$emit("created", payload);
      this.reload();
    },

    emitRemove(payload) {
      this.$emit("remove", payload);
      this.reload();
    },

    emitUpdate(payload) {
      this.$emit("update", payload);
      this.reload();
    },

    getHeaderColors(item) {
      let day = moment(item.date).format("dddd");

      let color = "white";

      switch (day) {
        case "Monday":
          color = "red";
          break;
        case "Tuesday":
          color = "blue";
          break;
        case "Wednesday":
          color = "pink";
          break;
        case "Thursday":
          color = "brown";
          break;
        case "Friday":
          color = "green";
          break;
        case "Saturday":
          color = "violet";
          break;
        case "Sunday":
          color = "teal";
          break;
      }
      color = "white";
      return `background-color: ${color};`;
    },

    getFixedHeaderColors(item, refId) {
      if (this.fixedVisible) {
        let target = this.$refs[refId];
        if (target && target.length > 0) {
          return `background-color: white; width:${
            target[0].clientWidth
          }px; left:${target[0].getBoundingClientRect().x}px; `;
        }
      }
    },

    updateTimeMark() {
      let baseMoment = moment().set({ hour: this.hourFrom, minute: 0 });
      this.timeMarkerPosition =
        moment().diff(baseMoment, "minutes") * this.pixelsPerMinute;
    },

    momentDate(item) {
      return moment(item);
    },

    wrapPatients(items, appointments) {
      this.$store
        .dispatch(Action.PATIENT_GET_MANY, items)
        .then((resp) => {
          if (resp && resp.data) {
            appointments.forEach((app) => {
              app.patient = resp.data.find((p) => p.id == app.patientId);
            });
          }
        })
        .catch((error) => {
          this.apiProblem(error);
        });
    },

    printPatients(item) {
      let appointments = [];
      let ticketsToGet = [];
      let tickets = [];

      let index = 1;
      let office = this.getOffice(item.subjectCode);

      this.printDate = item.date;

      item.blocks.forEach((app) => {
        if (app.appointment) {
          if (
            !ticketsToGet.includes(app.appointment.ticket) &&
            app.appointment.ticket
          )
            ticketsToGet.push(app.appointment.ticket);

          let object = {
            index: index,
            employee: app.appointment.employee,
            isTimeless: false,
            start: app.appointment.start,
            stop: app.appointment.stop,
            office: office ? office.name : "Brak",
            isNfz: app.appointment.payerType == 1 ? true : false,
            description: app.appointment.description,
            patient: app.appointment.patient,
            patientId: app.appointment.patientId,
            appointmentType: this.mGetAppType(app.appointment.type),
            appointmentSubtype: this.mGetAppSubtype(
              app.appointment.type,
              app.appointment.subtype
            ),
            ticket: null,
            ticketId: app.appointment.ticket,
          };

          appointments.push(object);
          index++;
        }
      });

      item.timeless.forEach((app) => {
        if (!ticketsToGet.includes(app.ticket) && app.ticket) {
          ticketsToGet.push(app.ticket);
        }

        let object = {
          index: index,
          employee: app.employee,
          isTimeless: true,
          start: app.start,
          stop: app.stop,
          office: office ? office.name : "Brak",
          isNfz: app.payerType == 1 ? true : false,
          description: app.description,
          patient: app.patient,
          patientId: app.patientId,
          appointmentType: this.mGetAppType(app.type),
          appointmentSubtype: this.mGetAppSubtype(app.type, app.subtype),
          ticket: null,
          ticketId: app.ticket,
        };

        appointments.push(object);
        index++;
      });

      let uniqueEmployee = appointments
        .map((item) => item.employee)
        .filter((value, index, self) => self.indexOf(value) === index);

      this.loading = true;
      this.$store
        .dispatch(Action.STATS_GET_TICKETS_MULTIPLE, ticketsToGet)
        .then((respTickets) => {
          this.loading = false;
          tickets = respTickets;

          appointments.forEach((app) => {
            app.ticket = tickets.find((tick) => tick.id == app.ticketId);
          });

          let printApps = [];

          uniqueEmployee.forEach((employee) => {
            let worker = this.getWorker(employee);

            if (worker == null || worker.id < 1) {
              worker.firstName = "Brak pracownika";
              worker.lastName = "";
            }

            let object = {
              employee: `${worker.firstName} ${worker.lastName} `,
              date: item.date,
              appointments: appointments.filter(
                (app) => app.employee == employee
              ),
            };

            printApps.push(object);
          });

          this.printAppointments = printApps;

          this.$nextTick(() => {
            this.printSummary();
            this.addIkzPrintSummary();
          });
        })
        .catch((error) => {
          this.apiProblem(error);
          this.loading = false;
        });
    },

    printSummary() {
      // const options = {
      //   styles: [
      //     "http://localhost:8080/buefy_for_print_only.css",
      //     "http://t1.digitmed.pl/buefy_for_print_only.css",
      //     "http://localhost:8080/margins_paddings_for_print_only.css",
      //     "http://t1.digitmed.pl/margins_paddings_for_print_only.css",
      //   ],
      // };

      //this.$htmlToPaper("appointmentDayPrint", options);

      this.mPrintBackend("appointmentDayPrint");
    },

    /**
     * Generates PDF from invoice.
     */
    printElementBackend() {
      //var title = `<p style="font-family:Arial;padding:0;font-size:0.7rem;margin: 0;">FAKTURA nr ${this.item.invoiceNr} za miesiąc ${this.getMonthName(this.item.month)} ${this.item.year} z dnia ${this.mDate(this.item.documentDate)} - ciąg dalszy</p>`
      //this.requestInProgress = true
      let rf = "appointmentDayPrint";

      let test = {
        workerId: this.me.id,
        htmlContent: this.$refs[rf].innerHTML,
        landscape: false,
        margin: 1,
        //optionalHeaderFromSecondPage: title
      };

      this.$store
        .dispatch(Action.GOLD_TOOLS_PDF_FROM_HTML, test)
        .then((response) => {
          //this.requestInProgress = false
          var blob = new Blob([response], { type: "application/pdf" });
          let url = URL.createObjectURL(blob);
          window.open(url, "_blank", "location=yes,scrollbars=yes");
          URL.revokeObjectURL(url);
        })
        .catch((error) => {
          //this.requestInProgress = false
          this.apiProblem(error);
        });
    },

    getHour(date) {
      return moment(date).format("HH:mm");
    },

    getAppName(app) {
      let id = app.type;
      let sid = app.subtype;
      let result = "";

      let category = this.apps.find((c) => c.id == id);
      if (category) {
        result = category.name;

        if (sid > 0 && category.subcategories) {
          let sub = category.subcategories.find((c) => c.id == sid);

          if (sub) {
            result += `, ${sub.name}`;
          }
        }

        return result;
      } else return "Brak informacji";
    },

    addIkzPrintSummary() {
      let facilityId = this.meParentFacilityObject
        ? this.meParentFacilityObject.id
        : null;
      let selected = this.registrationIkz.find((x) => x.internalId == 605);

      if (selected) {
        var payload = {
          ikzId: selected.id,
          employeeId: this.me.id,
          patientId: 0,
          group: selected.group,
          type: selected.type,
          source: selected.source,
          points: selected.points,
          ikzInternalId: selected.internalId,
          name: selected.name,
          entry: 3, // 3 - ENTRY - AUTOMATYCZNE
          amount: 1,
          facility: facilityId,
        };

        this.$store
          .dispatch(Action.FINANCE_USER_IKZ_ADD, payload)
          .then(() => {
            //this.successSnackbar("Dodano IKZ");
          })
          .catch((error) => {
            this.apiProblem(error);
          });
      }
    },

    addIkzSearchSchedule() {
      let facilityId = this.meParentFacilityObject
        ? this.meParentFacilityObject.id
        : null;
      let selected = this.registrationIkz.find((x) => x.internalId == 629);

      if (selected) {
        var payload = {
          ikzId: selected.id,
          employeeId: this.me.id,
          patientId: 0,
          group: selected.group,
          type: selected.type,
          source: selected.source,
          points: selected.points,
          ikzInternalId: selected.internalId,
          name: selected.name,
          entry: 3, // 3 - ENTRY - AUTOMATYCZNE
          amount: 1,
          facility: facilityId,
        };

        this.$store
          .dispatch(Action.FINANCE_USER_IKZ_ADD, payload)
          .then(() => {
            //this.successSnackbar("Dodano IKZ");
          })
          .catch((error) => {
            this.apiProblem(error);
          });
      }
    },

    getPatientBirthdate(patient) {
      if (patient.pesel != "" && patient.pesel != null) {
        return this.getBirthDateFromPesel(patient.pesel);
      } else {
        return this.mDate(patient.dateOfBirth);
      }
    },

    getBirthDateFromPesel(pesel) {
      let d = null;
      if (pesel) {
        if (pesel != "") {
          let arr = [];
          for (let i = 0; i < 6; i++) {
            arr[i] = pesel.charAt(i);
          }
          if (arr[2] < 2) {
            d =
              "19" +
              arr[0] +
              arr[1] +
              "-" +
              arr[2] +
              arr[3] +
              "-" +
              arr[4] +
              arr[5];
          } else {
            d =
              "20" +
              arr[0] +
              arr[1] +
              "-" +
              (arr[2] - 2) +
              arr[3] +
              "-" +
              arr[4] +
              arr[5];
          }
        }
      }
      return d;
    },

    clearDataHardReload() {
      if (this.payload.days && this.payload.days.length > 0) {
        this.payload.days.forEach((day) => {
          if (day.blocks && day.blocks.length > 0) {
            day.blocks.forEach((block) => {
              if (block.appointment) {
                block.appointment.patient = null;
                block.appointment = null;
              }

              if (block.worktimeArea) {
                block.worktimeArea = null;
              }
            });
            day.blocks.length = 0;
          }

          if (day.worktimes && day.worktimes.length > 0) {
            day.worktimes.length = 0;
          }

          if (day.appointments && day.appointments.length > 0) {
            day.appointments.length = 0;
          }

          if (day.timeless && day.timeless.length > 0) {
            day.timeless.length = 0;
          }

          if (day.mockupBlocks && day.mockupBlocks.length > 0) {
            day.mockupBlocks.length = 0;
          }

          if (day.office) {
            if (day.office.facilityInfo) {
              day.office.facilityInfo = null;
            }
            day.office = null;
          }
        });

        this.payload.days.length = 0;
      }
    },

    emitShowPatientData(patient) {
      this.$emit("show-patient-data", patient);
    },

    isOfficeDayDisabled(day) {
      if (day?.office?.isScheduleBlocked) {
        return true;
      }

      if (
        this.editorCurrentOffice &&
        !day.id.includes(this.editorCurrentOffice)
      ) {
        return true;
      }

      return false;
    },

    //close er
    async closeErAppointment() {
      try {
        if (!this.focusBlock || !this.focusBlock.appointment) {
          this.dangerSnackbar(
            "Wystąpił problem z zamknięciem wizyty w E-rejestracji"
          );
          return;
        }

        let subunit = null;

        //old but we need office settings
        // if (this.focusBlock.appointment.subunitId) {
        //   subunit = this.subunits.find(
        //     (subunit) => subunit.id == this.focusBlock.appointment.subunitId
        //   );
        // } else if (this.focusBlock.appointment.officeId) {
        //   let office = this.getOffice(this.focusBlock.appointment.officeId);
        //   if (office) {
        //     subunit = this.subunits.find(
        //       (subunit) => subunit.id == office.subUnitId
        //     );
        //   }
        // }

        if (this.focusBlock.appointment.officeId) {
          let office = this.getOffice(this.focusBlock.appointment.officeId);
          if (office) {
            if (office.isReportZmDifferently) {
              if (office.reportZmAs != null) {
                subunit = this.subunits.find(
                  (subunit) => subunit.id == office.reportZmAs
                );
              }
            } else {
              subunit = this.subunits.find(
                (subunit) => subunit.id == office.subUnitId
              );
            }
          }
        }

        if (!subunit) {
          this.dangerSnackbar(
            "Wystąpił problem z zamknięciem wizyty w E-rejestracji (subjednostka)"
          );
          return;
        }

        let zmRequest = this.mCreateErClosDataObject(
          this.focusBlock.appointment,
          subunit
        );
        zmRequest.workerGuid = this.me.id.toString();
        zmRequest.p1ERejestracjaStatusChange = this.mNow();
        let payload = JSON.parse(JSON.stringify(zmRequest));

        await this.sendErejRequest(payload);
        this.successSnackbar("Zamknięciem wizyty zgłoszone do E-rejestracji");
      } catch (error) {
        this.dangerSnackbar(
          "Wystąpił problem z zamknięciem wizyty w E-rejestracji"
        );
      }
    },

    sendErejRequest(payload) {
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch(Action.MISC_P1EREJESTRACJA_CLOSE_VISIT, payload)
          .then((response) => {
            // this.status.status = 1;
            // this.status.date = new Date();
            resolve(response);
          })
          .catch((error) => {
            this.apiProblem(error);
            reject(error);
          });
      });
    },
  },
};
</script>

<style scoped lang="scss">
.hoverable-slot:hover {
  background: silver !important;
  cursor: pointer;
}

.fixed-header {
  position: absolute;
  left: auto;
  top: 0;
  z-index: 7;
  padding-left: 0.15rem;
  padding-top: 0.25rem;
}

.slot-box:hover {
  background: red !important;
  opacity: 0.6;
}

.slot-box p {
  visibility: hidden;
  font-size: 0.8rem;
  margin-top: -0.25rem;
  text-align: center;
  font-weight: bold;
  color: white;
}
.slot-box:hover p {
  visibility: visible;
}

.selection-rect {
  position: absolute;
  background: yellow;
  pointer-events: none;
  opacity: 0;
}

.ind-nodekl {
  background: rgb(255, 213, 0);
  border: 0px solid transparent;
}

.ind-otherdekl {
  background: mediumpurple;
  border: 0px solid transparent;
}

.ind-reservation {
  background: mediumpurple;
  border: 0px solid transparent;
}
.ind-present {
  background: #b1ff7b;
  border: 0px solid transparent;
}
.ind-alert {
  background: maroon;
  border: 0px solid transparent;
}
.ind-tele {
  background: #17a589;
  border: 0px solid transparent;
}
.ind-home {
  background: #884ea0;
  border: 0px solid transparent;
}
.ind-urgent {
  background: red;
  border: 0px solid transparent;
}
.ind-reschedule {
  background: greenyellow;
  border: 0px solid transparent;
}
.ind-ongoing {
  background: DarkTurquoise;
  border: 0px solid transparent;
}

.schedule2-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 100%;
}

.schedule2-container {
  display: flex;
  align-items: stretch;
  flex-direction: row;
  width: 100%;
}

.schedule2-day-box {
  overflow-y: hidden;
  background: #f7f7f7;
  // background: repeating-linear-gradient(
  //   -45deg,
  //   #ededed,
  //   #ededed 10px,
  //   #f7f7f7 10px,
  //   #f7f7f7 20px
  // );
}

.timeline-container {
  font-size: 0.75rem;
  text-align: right;
  max-width: 2.2rem;
}

.timeline-container-item {
  font-size: 0.7rem;
  padding-right: 2px;
  text-align: right;
}

.header-height {
  height: 4.5rem;
  line-height: 0.9rem;
}

.header-height-time {
  height: 4.4rem;
}

.time-marker {
  position: absolute;
  left: 0;
  width: 100%;
  border: 2px dotted pink;
  height: 1px;
  pointer-events: none;
}

.marker {
  border-top: 1px solid #eee;
  height: 60px;
}

.small-pad {
  padding-left: 1px;
  padding-right: 1px;
}

.day-header {
  height: 4rem;
  max-height: 4rem;
  padding-left: 0.1rem;
  padding-right: 0.1rem;
}

.header-fixed {
  position: fixed;
  top: 0;
  z-index: 1;
}

.timeline {
  position: absolute;
  width: 100%;
  border-bottom: 1px solid rgba(125, 125, 125, 0.1);
  z-index: 6;
  pointer-events: none;
  height: 2px;
}

.hoverable-block:hover {
  -webkit-box-shadow: inset 0px 0px 2px 0px #000 !important;
  -moz-box-shadow: inset 0px 0px 2px 0px #000 !important;
  box-shadow: inset 0px 0px 2px 0px #000 !important;
}

.rightrotate {
  writing-mode: tb-rl;
  text-orientation: sideways-right !important;
  position: absolute;
  right: 0rem;
  top: 1.5rem;
  bottom: 0;
  overflow: hidden;
  z-index: 10;
  font-size: 0.8rem;
  padding: 0 0.36rem;
  text-align: left !important;
  cursor: help;
}

.rightrotate:hover {
  opacity: 0.8;
}

.context-menu {
  position: fixed;
  z-index: 13;
  width: 12rem;
}
.context-menu ul li {
  display: block;
  padding: 0.2rem 0.65rem;
  cursor: pointer;
  font-size: 0.85rem;
}
.context-menu ul li:hover {
  background: whitesmoke;
}

.empty-schedule-box {
  position: fixed;
  top: 40%;
  left: calc(50% - 8rem);
  width: 16rem;
  text-align: center;
}

.loading-schedule-box {
  width: 24rem;
  z-index: 12;
  position: fixed;
  bottom: 8rem;
  left: calc(50% - 12rem);
  border: 3px solid #26a69a !important;
}

.no-office-schedule-box {
  width: 18rem;
  z-index: 12;
  position: fixed;
  bottom: 1rem;
  left: 2.2rem;
  border: 3px solid #3db8ff !important;
  opacity: 0.9;
}

.handpointer {
  transform: scale(1);
  animation: pulse 2s infinite;
}

.currentlyAdded {
  transform: scale(1);
  animation: pulseNoScale 1s infinite;
}

.erErrorAnimation {
  transform: scale(1);
  animation: pulseNoScaleInward 0.75s infinite;
}

.editor-background {
  background: repeating-linear-gradient(
    45deg,
    #fdefe1,
    #fdefe1 10px,
    #fff 10px,
    #fff 20px
  );
}

.editor-background-slots {
  background: repeating-linear-gradient(
    45deg,
    #fae1fd,
    #fae1fd 10px,
    #fff 10px,
    #fff 20px
  );
}

.table-clear {
  table td,
  table th {
    border: none !important;
    border-width: 0 !important;
    padding: 0 !important;
    vertical-align: top !important;
  }

  .table.is-bordered td {
    border: none !important;
    border-width: 0 !important;
  }
}

@keyframes pulseNoScale {
  0% {
    transform: scale(1);
    box-shadow: inset 0 0 0 0 rgba(255, 255, 255, 0.95);
  }

  70% {
    transform: scale(0.97);
    box-shadow: inset 0 0 0 8px rgba(255, 255, 255, 0);
  }

  100% {
    transform: scale(1);
    box-shadow: inset 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

@keyframes pulseNoScaleInward {
  0% {
    opacity: 1;
  }

  70% {
    opacity: 0.3;
  }

  100% {
    opacity: 1;
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
</style>
